<template>
    <section class="clients">
        <b-container fluid='lg'>
            <b-row>
                <b-col cols='12' md='12'>
                    <h2 class="clients__title">
                        {{ title[$props.lang] }}
                        <div class="line"></div>
                    </h2>
                    <h3 class="clients__subtitle">
                        {{ subtitle[$props.lang] }}
                    </h3>
                    <p class="clients__description">
                        {{ description[$props.lang] }}
                    </p>
                </b-col>
            </b-row>
            <b-row>
              <b-col md="3" v-for="client in clients" :key="client.id" >
                <b-card
                  :img-alt="client.name"
                  img-top
                  tag="client"
                  style="max-width: 28rem;height:20rem;border:none;border-radius:1rem;"
                  class="mb-2"
                >
                  <b-card-text >
                    <div class="text-center" >
                      <b-img style="margin: auto;width:60%" target="_blank" :src="client.logo" fluid></b-img>
                    </div>
                    <div class="clients__inner_description pt-2">
                    {{client.description[$props.lang]}}<br>
                    <a :href="client.url" class="btn btn-link font-weight-bold" style="font-size:1em;">{{ clink[$props.lang] }}</a>
                    </div>
                  </b-card-text>
                </b-card>
              </b-col>
            </b-row>
            <div class="text-center pt-4">
              <ButtonComponent :text="glink[$props.lang]" variant="secondary" href="/company_clients"/>
              <div class="small font-weight-lighter mt-3" style="font-size:1.3em;">{{ note[$props.lang] }}</div>
            </div>
        </b-container>
    </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import ButtonComponent from '@/../../systemico.ui/components/buttons/ButtonComponent.vue';
export default {
  name: 'clients',
  components: {
    buttonPrimry,
    ButtonComponent
  },
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      title: { es: 'Clientes con reusltados éxitosos', en:'Successful Clients' },
      subtitle: {es: '¿Por qué ser nuestro cliente?', en: 'Why become our customer?'},
      note: {
        es: 'Aprende de otros clientes y sus casos de éxito.',
        en: 'Learn about other clients and their successful cases.'
      },
      description: {
        es: 'Nuestra experiencia es la clave para modernizar modelos de negocio o crear nuevas oportunidades en innovación. Queremos trabajar con tigo y conocer de tus proyectos. Tenemos un espacio reservado para ti.',
        en: 'Our experience is a key to improve your business and create new opportunities. We have a spot reserved for you.'
      },
      clink: { es:'Visitar Cliente >', en: 'Visit Client' },
      glink: { es:'Saber más >', en: 'Read more' },
      clients: [
        {
          id: 1, name: 'Cotrasur', logo: 'https://cdn.systemico.net/systemico/web/clients/client_1.png',
          description: {
            es: 'Empresa de Transporte de carga masiva en el ámbito Nacional e Internacional.',
            en: 'Cargo shipment company at the national and international level.'
          },
          url: 'https://cotrasur.com.co/'
        },
        {
          id: 2, name: 'Camacol', logo: 'https://cdn.systemico.net/systemico/web/clients/client_2.png',
          description: {
            es: 'Gremio que representa los intereses de la cadena de valor de la construcción, incidiendo eficazmente en las decisiones que conciernen al desarrollo y crecimiento del sector.',
            en: 'A union that represents the interests of the construction value chain, successfully influencing decisions that concern the development and growth of the sector.'
          },
          url: 'https://camacol.co/'
        },
        { id: 3, name: 'Fundación de la Mujer', logo: 'https://cdn.systemico.net/systemico/web/clients/client_3.png',
          description: {
            es: 'Organización comprometida con impulsar el liderazgo de las mujeres empresarias de la microempresa de bajo ingresos, facilitando el acceso a productos y servicios microfinancieros.',
            en: 'Organization committed to promoting the leadership of low-income women entrepreneurs in microbusinesses, facilitating access to microfinance products and services.'
          },
          url: 'https://portales.fundaciondelamujer.com/'
        },
        { id: 4, name: 'Cajasan', logo: 'https://cdn.systemico.net/systemico/web/clients/client_4.png',
          description: {
            es: 'Primera caja de compensación familiar en Santander (Colombia); una entidad sin ánimo de lucro que ofrece servicios y beneficios a empleadores y trabajadores, enfocados en satisfacer las necesidades de sus familias.',
            en: 'First family compensation fund in Santander (Colombia); a non-profit entity that offers services and benefits to employers and workers, focused on satisfying the needs of their families.'
          },
          url: 'https://cajasan.com' }
      ],
      configButton: { text: 'Crear cuenta gratis', class: 'button--primary' },

      configButton2: { text: 'Ingresar', class: 'button--secundary' }
    }
  },
  methods: {
    sinContenido: function () {
      this.$router.push({
        name: 'enConstruccion'
      })
    },
    ingresar: function () {
      window.location.href = '/login/'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../systemico.ui/basic.scss";
@import "../../assets/styles/landing/clients.scss";

</style>
