<template>
  <section class="banner_tip" :style="'background:'+$props.background+';color:'+$props.textColor">
    <b-img :src="$props.icon" fluid alt=""></b-img>
    <h2 v-if="$props.title !== undefined" class="title" v-html="$props.title"></h2>
    <h3 class="message" v-html="$props.message"></h3>
    <h5 class="author" v-html="$props.author"></h5>
  </section>
</template>

<script>
export default {
  name: 'BannerTipComponent',
  props: {
    title: String,
    message: String,
    icon: String,
    author: String,
    background: String,
    textColor: String
  }
}
</script>
<style lang="scss" scoped>
  @import "../../variables.scss";
  .banner_tip{
    padding:2rem;
    text-align: center;
    border-bottom: 2px solid #f0f0f0;
    a{
      color: #fefefe;
    }
    .title{
      font-size: 2.5rem;
      line-height: 2.7rem;
      text-align: center;
      color:#0b1c98;
      font-weight:bolder;
      margin-bottom: 1rem;
      padding-left: 10%;
      padding-right: 10%;
    }
    .author{
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    .message{
      font-size: 1.8rem;
      padding-left: 20%;
      padding-right: 20%;
    }
  }
  @media (max-width: 768px) {
    .banner_tip {
      padding: 1rem;
      .title{
        font-size: 2rem;
        line-height: 2.2rem;
        padding-left: 5%;
        padding-right: 5%;
      }
      .message{
        font-size: 1.5rem;
        padding-left: 10%;
        padding-right: 10%;
      }
    }
  }
</style>