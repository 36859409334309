<template>
  <section class="navbarSection">
    <b-container fluid=lg>
      <b-row>
        <b-col>
          <nav class="navbarSection__nav">
            <!-- <button class="navbarSection__button">Nosotros</button>
            <button class="navbarSection__button">Políticas de la empresa</button>
            <button class="navbarSection__button">Metodología de trabajo</button>
            <button class="navbarSection__button">Aliados</button> -->
            <button class="navbarSection__button" v-on:click="change('terms')">Términos y condiciones</button>
            <button class="navbarSection__button" v-on:click="change('politics')">Políticas de privacidad</button>
            <button class="navbarSection__button" v-on:click="change('advice')">Aviso de privacidad</button>
          </nav>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>

export default {
  name: 'navbarSection',
  props: {
  },
  components: {
  },
  data: function () {
    return {
    }
  },
  methods: {
    change: function (section) {
      this.$router.push({
        // name: 'Products'
        name: section
      })
    }
  }
}
</script>

<style lang="scss">
</style>
