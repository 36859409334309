<template>
  <div class="home pt-5">
    <HeaderSystemicoComponent :menuActive="true" lang="es" country="colombia" class="header_nav__active p-0" />
    <BannerMultiProductComponent :items="banner_items" country="colombia" lang="es" />
    <products lang="es" currency="cop" country="colombia" alone="products"/>
    <clients lang="es"/>
    <testimonials lang="es"/>
    <contact lang="es"/>
    <footerC lang="es"/>
    <modalTranslate @changeLanguage="changeLanguage"/>
    <modalPopup />
  </div>
</template>

<script>
import products from '@/components/landing/products.vue'
import BannerMultiProductComponent from '@/../../systemico.ui/components/banners/BannerMultiProductComponent.vue'
import clients from '@/components/landing/clients.vue'
import testimonials from '@/components/landing/testimonials.vue'
import contact from '@/components/landing/contact.vue'
import footerC from '@/../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import modalTranslate from '@/components/modals/modalTranslate.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import HeaderSystemicoComponent from '../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue'

export default {
  name: 'ProductsView',
  data: function () {
    return {
      headerFixed: false,
      banner_items: [
        {
          id: 1,
          title: 'Soluciones para optimizar tu empresa.',
          subtitle: 'Software para simplificar y acelerar procesos.',
          image: 'https://cdn.systemico.net/systemico/web/headers/header_15.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Agendar Demostraci&oacute;n', variant: 'secondary', link: '/products', type: 'meeting' }
          ],
          show: true
        }
      ],
      benefits: [
        { id: 1, title: 'Soporte Permanente', description: 'Equipo experto que garantiza el cumplimiento de las metas del servicio.', icon: 'https://cdn.systemico.net/systemico/icons/icon--soporte.svg' },
        { id: 2, title: 'Experiencia', description: '17 años en el mercado con errores y aprendizajes que fortalecieron nuestro enfoque, resiliencia y visión.', icon: 'https://cdn.systemico.net/systemico/icons/icon-code.svg' },
        { id: 3, title: 'Empatía', description: 'Orgullosamente Colombianos que vive y enfrentan los retos de nuestro entorno y economía.', icon: 'https://cdn.systemico.net/systemico/icons/icon--cool.svg' },
        { id: 4, title: 'Confianza', description: 'Clientes y aliados que garantizan nuestra experiencia y motivación.', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' }
      ]
    }
  },
  components: {
    HeaderSystemicoComponent,
    BannerMultiProductComponent,
    products,
    clients,
    testimonials,
    contact,
    footerC,
    modalTranslate,
    modalPopup
  },
  beforeMount () {
    /* if(this.$route.params.lang){
      if(this.$route.params.lang=='es' || this.$route.params.lang=='en'){
        localStorage.setItem("userLanguage", this.$route.params.lang);
        this.changeLanguage(this.$route.params.lang)
      }else{
        localStorage.setItem("userLanguage", 'en');
        this.changeLanguage('en')
      }
    }else{
      localStorage.setItem("userLanguage", 'en');
      this.changeLanguage('en')
    } */
  },
  methods: {
    headerReact: function () {
      /** const header = document.getElementsByClassName('headerUi')
      if (event.target.scrollTop > 0 && this.headerFixed === false) {
        header[0].classList.remove('headerUi--show')
        header[0].classList.add('headerUi--active')
        this.headerFixed = true
      } else if (this.headerFixed === true && event.target.scrollTop === 0) {
        header[0].classList.remove('headerUi--active')
        header[0].classList.add('headerUi--show')
        this.headerFixed = false
      }**/

      // TODO: let stateLocalStorage = localStorage.getItem("modalWePower")

      // console.log(event.target.scrollTop)

      // if (event.target.scrollTop > 2620 && window.screen.width > 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }

      // if (event.target.scrollTop > 5200 && window.screen.width < 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }
    }
  }
}
</script>
<style>
@import "../../../systemico.ui/basic.scss";
</style>
