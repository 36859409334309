<template>
  <section class="contact" id="contact">
    <b-container fluid="xl">
      <b-row>
        <b-col cols="12" md="6">
          <h2 class="contact__title">
            {{ title[$props.lang] }}
            <div class="line"></div>
          </h2>
          <h3 class="contact__subtitle">
            {{ subtitle[$props.lang] }}
          </h3>
          <!--<p class="contact__description">
            {{ description }}
          </p>-->
          <!--<template v-for="(item, index) in buttons">
            <buttonPrimary
              :configButton="item"
              :key="index"
              @funcionBtn="funcioRedirect(item.link)"
            />
          </template>-->
          <contactForm :lang="$props.lang"/>
          <!--<p class="contact__subtitle">También puedes contactarnos en:</p>
          <div class="d-flex">
            <div class="contact__whatsapp">
              <div class="icon">
                <img :src="$cdn + 'icons/icon-whatsapp.svg'" />
              </div>
              <a href="https://wa.me/13149883020?text=Hola, quisiera saber más!" target="_blank" rel="noopener noreferrer">
                <buttonPrimary class="m-0" :configButton="whatsapp" />
              </a>
            </div>
            <div class="contact__email ml-4">
              <div class="icon">
                <img :src="$cdn + 'icons/icon-email.svg'" />
              </div>
              <a href="mailto:hello@systemico.net">
                <buttonPrimary class="m-0" :configButton="email" />
              </a>
            </div>-->
          </div>
        </b-col>
        <b-col cols="12" md="6">
          <div class="contact__image">
            <img :src="$cdn + 'images/image-map--xl.png'" alt="image section" />
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import buttonPrimary from "@/components/basic/buttonBasic.vue";
import contactForm from "@/../../systemico.ui/components/formContact.vue"
export default {
  name: "contact",
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  components: {
    buttonPrimary,
    contactForm
  },

  data: function () {
    return {
      title: {es: "Contacta con nosotros", en: "Contact us"},
      subtitle: {es: "Dinos todo lo que necesitas.", en: "How can we help you?"},
      description:
        {
          es: "Estamos para ayudarte y cumplir con tu necesidad en el menor tiempo posible.",
          en: "We are here to help you and fulfill your needs in the shortest time possible."
        },
      buttons: [
        {
          text: {
            es: "Cotizar",
            en:"Quotes"
          },
          class: "button--secundary",
          link: "/support/quote" },
        {
          text: {
            es: "Contáctanos",
            en:"Contact Us"
          },
          class: "button--secundary",
          link: "/support/registro",
        },
        {
          text: {
            es: "Soporte",
            en: "Support"
          },
          class: "button--secundary",
          link: "/support" },
      ],

      whatsapp: {
        text: "+1 (314) 988-3020",
        class: "button--whatsapp",
        link: "",
      },

      email: {
        text: "hello@systemico.net",
        class: "button--black",
        link: "",
      }
    };
  },
  methods: {
    funcioRedirect: function (link) {
      window.location = link;
    },
  },
};
</script>
