<template>
  <main class="section">
    <headerC :menuActive="true" class="headerUi--active p-0"/>
    <!-- seccion 1 -->
    <section class="requirements requirements--blue mt-5 pt-5">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <titleSection :content="content" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md=""></b-col>
          <b-col cols="12" md="6">
            <div
              class="
                requirements__title requirements__title--white
                align-items-end
              "
            >
              <img :src="$cdn + 'icons/icon-code.svg'" alt="icon card" />
              <h2>Desarrollo de Software</h2>
            </div>
            <p class="requirements__subtitle">
              Análisis detallado de necesidades y soluciones
            </p>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="requirements__description">
              Los aspectos técnicos son altamente relevantes y elegir una
              tecnología con el mayor tiempo de viabilidad es clave para
              sostener una plataforma tecnológica sostenible desde todo punto de
              vista.
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- index -->
    <div class="content__index">
      <a href="#Description">Descripción</a>
      <a href="#Price">Precio</a>
      <a href="#Characteristic">Características</a>
      <a href="#Technologies">Tecnologías</a>
    </div>
    <!-- seccion 2 -->
    <div class="requirements pb-0 requirements--grey" id="Description">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Tecnologías apropiadas</h3>
              <p>
                Se han elegido tecnologías populares que cuentan con un respaldo
                robusto para disminuir los riesgos asociados a la obsolescencia,
                para esto el uso de tecnología, frameworks y librerías se
                determina según popularidad que garantiza permanencia y su
                compatibilidad con el proyecto a desarrollar.
              </p>
              <a href="#Technologies" target="_blank" rel="noopener noreferrer"
                >Ver tecnologías ></a
              >
            </div>
            <div class="requirements__card">
              <h3>Resultados permanentes</h3>
              <p>
                Systemico trabaja en un modelo iterativo con resultados
                recurrentes que pueden ser validados por usuarios finales o
                líderes.
              </p>
              <a
                href="/enConstruccion"
                target="_blank"
                rel="noopener noreferrer"
                >Ver modelo iterativo ></a
              >
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Tipos de Desarrollos</h3>
              <ul>
                <li>Plataformas Web. (WAPPS)</li>
                <li>Aplicaciones Móviles. (APPS)</li>
                <li>Aplicaciones de Escritorio. (DAPPS)</li>
                <li>Aplicaciones CLI (Command Line interface)</li>
                <li>API. (REST y SOAP)</li>
                <li>Automatizaciones (BOTS)</li>
                <li>Aplicaciones de control de plataformas. (CONSOLE)</li>
                <li>Frameworks</li>
                <li>Librerías</li>
                <li>Analítica de Datos</li>
              </ul>
            </div>
            <div class="requirements__slogan">
              <p>
                Nuestros Servicios son soluciones innovadoras para potenciar tu
                negocio.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Proyectos Open Source</h3>
              <p>
                Systemico utiliza GitHub para compartir proyectos que son
                utilizados de manera libre por gran cantidad de desarrolladores
                que aportan o recomiendan mejores acciones.
              </p>
              <a
                href="https://github.com/systemico"
                target="_blank"
                rel="noopener noreferrer"
                >Visitar GitHub Systemico ></a
              >
            </div>
            <div class="requirements__card">
              <h3>Comunidad Open Source</h3>
              <p>
                Participación activa en comunidades para compartir librerías,
                comandos, frameworks que faciliten la implementación rápida de
                soluciones en etapas de desarrollo o soporte agregándole valor a
                cada proyecto y disminuyendo los riesgos de obsolescencia de las
                dependencias.
              </p>
              <a
                href="https://jumichica.com"
                target="_blank"
                rel="noopener noreferrer"
                >Visitar jumichica.com ></a
              >
            </div>
            <div class="requirements__card">
              <h3>Ajustado al presupuesto</h3>
              <p>
                Desear es fácil, lograr es complicado para eso se determinan
                límites claros que faciliten el balance entre el riesgo y el
                cumplimiento de metas.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <!-- seccion 3 -->
    <section class="content content--gray" id="Price">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <b-table-simple class="content__simple__table" responsive>
              <b-thead>
                <b-tr>
                  <b-th>Plan</b-th>
                  <b-th class="text-center">Hora / Mes</b-th>
                  <b-th class="text-center">Valor Mes</b-th>
                  <b-th class="text-center">Valor Hora Adicional</b-th>
                  <b-th class="text-center">Tiempo promedio PMV</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr>
                  <b-td>
                    <div>
                      <h3>Plan Rookie</h3>
                      <p>
                        Recomendado para nuevos emprendedores que desean
                        desarrollar un PMV (<i>Producto Mínimo Viable</i>) para validación de mercado.
                      </p>
                    </div>
                  </b-td>
                  <b-td>
                    <p class="text-center">40</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">2000 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">50 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">5 meses</p>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <div>
                      <h3>Plan Bootstrap</h3>
                    </div>
                  </b-td>
                  <b-td>
                    <p class="text-center">80</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">3750 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">47 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">4 meses</p>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <div>
                      <h3>Plan Impulse Small Business</h3>
                    </div>
                  </b-td>
                  <b-td>
                    <p class="text-center">160</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">7000 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">43 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">3 meses</p>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-td>
                    <div>
                      <h3>Plan Launch Startup</h3>
                      <p>
                        Recomendado para startups que desean un producto para
                        escalarse de manera rápida en el mercado y que ven en su
                        producto una oportunidad única si lo liberan de manera
                        rápida a sus clientes.
                      </p>
                    </div>
                  </b-td>
                  <b-td>
                    <p class="text-center">320</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">13000 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">40 USD</p>
                  </b-td>
                  <b-td>
                    <p class="text-center">2 meses</p>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>

            <buttonPrimry
              class="mx-auto margin-top-5 margin-bottom-5"
              :configButton="configButton2"
              @funcionBtn="funcioRedirect('/login')"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <div class="content__card content__card--white">
              <p>
                Si consideras que tu proyecto requiere apoyo inicial para detallar tus necesidades y establecer requerimientos, visita nuestra sección de:
              </p>
              <a href="/service_requirements" rel="noopener noreferrer"
                >Análisis de Requerimientos ></a
              >
            </div>
          </b-col>
          <b-col cols="12" md="6">
            <div class="content__card content__card--white">
              <p>
                Una vez terminado el proceso de desarrollo del PMV se debe realizar la maduración del producto con sus usuarios y asegurar su funcionamiento, para eso lo invitamos a consultar:
              </p>
              <a href="/service_support" rel="noopener noreferrer"
                >Soporte por demanda ></a
              >
            </div>
          </b-col>
        </b-row>
      </b-container>

    </section>
    <!-- seccion 4 -->
    <section class="support" id="Characteristic">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12">
            <div class="support__title w-100 justify-content-center">
              <h2>Incrementa el potencial de tu empresa con tecnología</h2>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-1.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Optimiza procesos de negocio</h3>
              <p>
                Utiliza desarrollos a la medida para apoyar los diferentes
                procesos de tu empresa, reduciendo carga operativa.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-2.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Aumento de productividad</h3>
              <p>
                Aumenta la productividad hasta en un 50%, dejando de realizar
                procesos repetitivos.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-3.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Innovación tecnológica</h3>
              <p>
                Llega a nuevos mercados transformando en beneficios la
                implementación de desarrollos innovadores acorde a tus
                necesidades.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-4.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Ahorra tiempo y dinero</h3>
              <p>
                Implementa desarrollos tecnológicos, disminuyendo hasta un 40%
                los gastos de tu empresa.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-5.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Solucionar cuellos de botella</h3>
              <p>
                Automatizamos las tareas recurrentes aumentando la productividad
                de tu empresa.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="support__card mt-5">
              <div class="icon">
                <img :src="$cdn + 'icons/icon__line-2.svg'" alt="icon card" />
              </div>
              <h3 class="text-center">Vanguardia tecnológica</h3>
              <p>
                Mejoramos procesos, implementando nuevas herramientas dentro de
                la compañía.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- seccion 5 -->
    <section class="content p-0">
      <div class="content__slogan">
        <b-container>
          <b-row>
            <b-col>
              <p class="m-0">
                Systemico cuenta con un equipo que evalúa, analiza y cuida cada
                detalle del diseño, desarrollo, prueba y publicación de los
                productos, ideas y proyectos de sus clientes.
              </p>
            </b-col>
          </b-row>
        </b-container>
      </div>
    </section>

    <!-- seccion 6 -->
    <technologies id="Technologies" />
    <!-- seccion 7 -->
    <section class="requirements requirements--blue">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md=""></b-col>
          <b-col cols="12" md="10" lg="8">
            <div class="requirements__develop">
              <h2>Desarrollamos el crecimiento de tu empresa!</h2>
              <p>
                Systemico es un aliado estratégico enfocado en hacer las cosas
                simples y efectivas, que a cada paso se agregue valor y que la
                inversión se ejecute lo más eficientemente posible.
              </p>
              <div class="opcions">
                <template v-for="(item, index) in buttons">
                  <buttonPrimry
                    :configButton="item"
                    :key="index"
                    @funcionBtn="funcioRedirect(item.link)"
                  />
                </template>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>
      </b-container>
    </section>
    <footerC />
  </main>
</template>

<script>
import headerC from '../../../../../../systemico.ui/components/header.vue'
import titleSection from "@/components/ui/titleSection";
import buttonPrimry from "@/components/basic/buttonBasic.vue";
import footerC from '../../../../../../systemico.ui/components/footer.vue'

import technologies from "@/components/landing/technologies.vue";

export default {
  name: "developing",
  components: {
    headerC,
    titleSection,
    buttonPrimry,
    footerC,
    technologies,
  },
  metaInfo: {
    title: 'Systemico: Servicio profesional de Desarrollo de Software.'
  },
  data: function () {
    return {
      content: {
        title: "Servicios",
        subtitle: "/ Desarrollo de Software",
      },
      buttons: [
        {
          text: "Cotizar proyecto",
          class: "button--secundary",
          link: "/support",
        },
        {
          text: "Solicitar servicio",
          class: "button--secundary",
          link: "/support/registro",
        },
        { text: "Contáctanos", class: "button--secundary", link: "/support" },
      ],
      configButton2: {
        text: "Adquirir plan",
        class: "button--secundary",
      },
    };
  },
  methods: {
    funcioRedirect: function (link) {
      window.location = link;
    },
  },
};
</script>
