<template>
  <section class="nav-landing" >
    <b-nav pills align="center" class="p-2">
      <b-nav-item href="#products">Productos y Servicios</b-nav-item>
      <b-nav-item href="#rocket">Experiencia</b-nav-item>
      <b-nav-item href="#methodology">Metodología</b-nav-item>
    </b-nav>
  </section>
</template>
<script>
export default {
  name: "navLanding"
}
</script>
<style lang="scss" scoped>
  .nav-landing{
    font-family: "Quicksand", sans-serif;
    font-size: 2em;
    border-top:2px solid #fefefe;
    background: linear-gradient(112.1deg, rgb(32, 38, 57) 11.4%, rgb(63, 76, 119) 70.2%);
  }
  .nav-link{
    color:#fefefe;
  }
  .nav-link:hover{
    color:#dddddd;
    text-decoration: underline;
  }
</style>
