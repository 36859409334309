<template>
  <section class="banner pt-5">
    <div class="banner__section">
      <b-row>
        <b-col md="7">
          <h1 class="title">
            Software sencillo que optimiza, automatiza e impulsa.
          </h1>
          <h2 class="subtitle">
              Software de pqrs, automatización WhatsApp, cobranza, cotizaciones, contratos,
              mensajes de texto, gestión de proveedores, administración de flota y mucho más.
          </h2>
          <div class="button_bar">
            <buttonPrimry :configButton="configButton8" @funcionBtn="funcioRedirect('/tryit')" />
          </div>
        </b-col>
        <b-col class="col-md-5 text-left">
          <img src="@/assets/images/banner_image_1.svg" height="100%" />
        </b-col>
      </b-row>
    </div>
    <navLanding />

    <div class="row banner__items p-3">
          <div class="col-md-4 col-sm-12 banner__item">
            <h3>
              <img height="25em"
                   :src="$cdn + '' + selected_context.main_icon"
                   alt="icon card"
              />
              {{ selected_context.main_product }}
            </h3>
            <p v-html="selected_context.main_description"></p>
            <div class="options">
              <a
                class="text-decoration-none"
                :href=selected_context.main_link
                rel="noopener noreferrer"
              >
                <buttonPrimry :configButton="configButton5" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 banner__item">
            <h3>
              <img height="25em"
                   :src="$cdn + 'icons/products/icon-wabot.svg'"
                   alt="icon card"
              />
              Plataforma Chat Bot.
            </h3>
            <p>
              <strong>Automatiza y genera campañas por Whatsapp, Email, SMS y Llamadas</strong> de manera sencilla e integrada con tus plataformas actuales.
            </p>
            <div class="options">
              <a
                class="text-decoration-none"
                href="/products/wabot/"
                rel="noopener noreferrer"
              >
                <buttonPrimry :configButton="configButton3" />
              </a>
            </div>
          </div>
          <div class="col-md-4 col-sm-12 banner__item">
            <h3>
              <img height="25em"
                   :src="$cdn + 'icons/products/icon-suppmail.svg'"
                   alt="icon card"
              />
              Software de PQRS.
            </h3>
            <p>
              Atención efectiva de clientes, no importa el estilo de trabajo (Remoto, Hibrido ó Presencial). Tendrás <b>todo centralizado, ordenado y con alertas para asegurar el cumplimiento</b>.
            </p>
            <div class="options">
                <a
                  class="text-decoration-none"
                  href="/products/suppmail/"
                  rel="noopener noreferrer"
                >
                <buttonPrimry  :configButton="configButton3" />
              </a>
            </div>
          </div>
      </div>

  </section>
</template>

<script>
import buttonPrimry from "@/components/basic/buttonBasic.vue";
import navLanding from "@/components/landing/navLanding.vue";

export default {
  name: "banner",

  components: {
    buttonPrimry,
    navLanding
  },

  data: function () {
    return {
      context: {
        "1": {
          main_product: 'Desarrollo de Microservicios',
          main_description: 'Plataformas de OpenData e integración para simplificar la integración de procesos de negocio, con tecnologías, actores e interesados.',
          main_icon: 'icons/icon-code-white.svg',
          main_link: '/service_developing'
        },
        "2": {
          main_product: 'Solución para Gestión de Flota.',
          main_description: 'Software para la gestión de negocio en empresa de transporte (<i>Centros de enseñanza, Transportadoras de Personas, Transporte especial y mucho más</i>).',
          main_icon: 'icons/products/icon-transpur.svg',
          main_link: '/products/transpur/'
        },
        "3": {
          main_product: 'Solución para Gestión de Flota.',
          main_description: 'Software para la gestión de negocio en empresa de transporte (<i>Centros de enseñanza, Transportadoras de Personas, Transporte especial y mucho más</i>).',
          main_icon: 'icons/products/icon-transpur.svg',
          main_link: '/products/transpur/'
        }
      },
      selected_context: {},
      title: "",
      textTitle: "Desarrollamos",
      counter: 0,
      configButton: { text: "Conoce nuestras soluciones", class: "button--primary" },
      configButton2: { text: "Saber más", class: "button--secundary" },
      configButton3: { text: "Saber más", class: "button--primary" },
      configButton4: { text: "Cotizar", class: "button--primary" },
      configButton5: { text: "Saber más", class: "button--primary" },
      configButton6: { text: "Más productos", class: "button--primary" },
      configButton7: { text: "Solicitar Soporte", class: "button--primary" },
      configButton8: { text: "Probar", class: "button--primary", link: '/tryit' },
      items: [
        { number: "245", text: "Proyectos realizados" },
        { number: "14", text: "Años de experiencia" },
        { number: "+500k", text: "Horas de programación" },
        { number: "+4", text: "Bots atendiendo" },
      ],

      translate: 0,
    };
  },

  mounted: function () {
    // ejecutar la funcion typing en el ciclo de mounted
    this.typing()

    // seleccionar la imagen de fondo
    //this.selectBackground();
    this.context_detect()
  },

  methods: {
    context_detect: function () {
      var context_cookie = this.$cookies.get('context') // GET THE CONTEXT COOKIE
      if (context_cookie == undefined){
        context_cookie = 1
      }
      this.selected_context = this.context[context_cookie]
    },
    typing: function () {
      // console.log(this.counter, this.title, this.textTitle)
      if (this.counter < this.textTitle.length) {
        this.title += this.textTitle.charAt(this.counter);
        this.counter++;
        setTimeout(() => this.typing(), 200);
      } else {
        var line = document.getElementById("line");
        line.classList.remove("line--active");
      }
    },

    traduccion: function () {
      let language = document.documentElement.lang;

      if (language == "es") {
        this.translate = 1;
      } else if (language == "en") {
        this.translate = 2;
      }
    },

    selectBackground: function () {
      let banner = document.getElementById("colbanner");//document.getElementsByClassName("banner");
      let products = document.getElementsByClassName("products");

      let number = Math.floor(Math.random() * (2 + 1 - 0) + 0);

      if (number > 0) {
        //banner[0].classList.add("banner--background-" + number);
        //banner.classList.add("banner--background-" + number);
        //products[0].classList.add("products--background-" + number);
      }
    },
    funcioRedirect: function (link) {
      window.location = link
    }
  },
};
</script>
<style>
  .title{
    font-family: "Quicksand", sans-serif;
    font-size: 6.5rem;
    font-weight: bolder;
    color:#fefefe;
  }
  .subtitle{
    font-family: "Quicksand", sans-serif;
    font-size: 2.3rem;
    font-weight: lighter;
    color:#f8f8f8;
  }
  .button_bar{
    margin-bottom:2em;
  }
</style>
