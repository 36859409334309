<template>
  <div class="landing"  @scroll="headerReact()" >
    <headerC class="headerUi--active" :menuActive="true" ref="header" @typing="typing" :show-lang="true"/>
    <!--<banner ref="banner"/>-->
    <div class="mt-5  pt-5">
      <products/>
    </div>
    <testimonials/>
    <!--<contact/>-->
    <footerC/>

    <modalTranslate @changeLanguage="changeLanguage"/>
    <modalPopup />
    <!--<social />-->
  </div>
</template>

<script>
import headerC from '@/../../systemico.ui/components/header.vue'
import banner from '@/components/landing/banner.vue'
import products from '@/components/landing/products.vue'
import software from '@/components/landing/software.vue'
import services from '@/components/landing/services.vue'
import methodology from '@/components/landing/methodology.vue'
import rocket from '@/components/landing/rocket.vue'
import team from '@/components/landing/team.vue'
// import technologies from '@/components/landing/technologies.vue'
import ceo from '@/components/landing/ceo.vue'
import clients from '@/components/landing/clients.vue'
import news from '@/components/landing/news.vue'
import testimonials from '@/components/landing/testimonials.vue'
import allies from '@/components/landing/allies.vue'
import contact from '@/components/landing/contact.vue'
import pradek from '@/components/landing/pradek.vue'
import footerC from '@/../../systemico.ui/components/footer.vue'

import modalTranslate from '@/components/modals/modalTranslate.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import social from '../../../../systemico.ui/components/social.vue'

export default {
  name: 'landing',
  data: function () {
    return {
      headerFixed: false
    }
  },
  components: {
    headerC,
    banner,
    products,
    software,
    services,
    methodology,
    rocket,
    team,
    // technologies,
    ceo,
    clients,
    news,
    testimonials,
    allies,
    contact,
    pradek,
    footerC,
    modalTranslate,
    modalPopup,
    social
  },
  beforeMount() {
    /*if(this.$route.params.lang){
      if(this.$route.params.lang=='es' || this.$route.params.lang=='en'){
        localStorage.setItem("userLanguage", this.$route.params.lang);
        this.changeLanguage(this.$route.params.lang)
      }else{
        localStorage.setItem("userLanguage", 'en');
        this.changeLanguage('en')
      }
    }else{
      localStorage.setItem("userLanguage", 'en');
      this.changeLanguage('en')
    }*/
  },
  methods: {
    headerReact: function () {
      /**const header = document.getElementsByClassName('headerUi')
      if (event.target.scrollTop > 0 && this.headerFixed === false) {
        header[0].classList.remove('headerUi--show')
        header[0].classList.add('headerUi--active')
        this.headerFixed = true
      } else if (this.headerFixed === true && event.target.scrollTop === 0) {
        header[0].classList.remove('headerUi--active')
        header[0].classList.add('headerUi--show')
        this.headerFixed = false
      }**/

      // TODO: let stateLocalStorage = localStorage.getItem("modalWePower")

      // console.log(event.target.scrollTop)

      // if (event.target.scrollTop > 2620 && window.screen.width > 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }

      // if (event.target.scrollTop > 5200 && window.screen.width < 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }
    },

    typing: function () {
      this.$refs.banner.traduccion()
    },

    changeLanguage: function (idioma) {
      this.$refs.header.changeLanguage(idioma)
    },
  }
}
</script>
