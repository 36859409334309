<template>
  <section :class="'p-0 m-0 header_nav container-fluid variant-'+$props.variant" >
    <b-container class="p-0 m-0" fluid>
      <b-row class="p-0 m-0">
        <b-col cols="12" sm="12" md="2" class="p-0 m-0">
          <div class="d-flex align-items-center">
            <!-- PRODUCT -->
            <a v-if="isProduct" href="/">
              <img
                class="iso__logo"
                :src="$cdn + 'iso_orange.svg'"
                height="50px"
                alt="Systemico - Home"
                href="/"
              />
            </a>
            <img v-if="isProduct"
              class="iso__logo"
              :src="$props.logoProduct"
              height="30px"
              alt="Product Logo"
              style="margin-left:0.8rem;"
              href="/"
            />
            <!-- NOT PRODUCT -->
            <img v-if="!isProduct"
              class="header_nav__logo"
              :src="$cdn + 'logos/logo-systemico--white-2.svg'"
              alt="logo systemico"
              @click="info_usuario != null ? redirect('/dashboard') : redirect('/')"/>
            <img v-if="!isProduct"
              class="header_nav__icon"
              :src="$cdn + 'logos/logo-systemico--white-2.svg'"
              alt="logo systemico"
              @click="info_usuario != null ? redirect('/dashboard') : redirect('/')"
            />
            <button class="header_nav__btn" @click="showMenu()">
              <b-icon icon="list"></b-icon>
            </button>
          </div>
        </b-col>
        <b-col cols="12" lg="10">
          <nav class="header_nav__nav">
            <template v-for="(item, index) in items">
              <a
                class="item"
                :key="index"
                :href="item.link"
                rel="noopener noreferrer"
                @mouseover="showSubMenu(item)"
                @mouseout="closeSubMenu()"
                v-if="menuActive && item.lang !== undefined && item.lang.includes($props.lang)">
                {{item.name[$props.lang]}}
              </a>
            </template>

            <div class="translate" v-if="menuActive && $props.showLang && $props.showLang==true">
              <button class="btn-translate" v-on:click="showTranslate()">
                <b-icon icon="globe"></b-icon>
                <span v-show="language == 'EN'">en</span>
                <span v-show="language == 'ES'">es</span>
              </button>

              <div
                class="options-translate"
                v-show="stateTranslate"
                @mouseleave="closeTranslate()"
              >
                <a
                    href=""
                    rel="noopener noreferrer"
                >English</a
                >
                <a
                    href="es"
                    rel="noopener noreferrer"
                >Español</a
                >
              </div>
            </div>
            <template v-if="info_usuario == null">
              <FlagButton v-if="$props.country !== undefined" :country="$props.country" :lang="$props.lang" />
              <ButtonMeetingComponent v-if="$props.isScheduled" :text="config1.text[[$props.lang]]" variant="secondary" />
              <ButtonComponent class="font-weight-bold mr-1" v-if="$props.isSignup" :text="config_signup.text[[$props.lang]]" :variant="$props.signupVariant" :href="$props.signupURL" icon="lightning-charge-fill" />
              <ButtonComponent :text="config_login.text[[$props.lang]]" :variant="$props.loginVariant" :href="$props.loginURL" icon="lock-fill" />
              <!--<buttonAccess :config="config2" @clicked="$bvModal.show('modal-access-options')" />-->
            </template>
            <template v-else>
              <!-- <buttonCustom :config="config3" @clicked="cerrarSesion" /> -->
              <div class="bell" @mouseleave="closeNotifications()">
                <button class="bell__icon" v-on:click="showNotifications()">
                  <b-icon icon="bell"></b-icon>
                  <span v-if="notifications.length">{{ notifications.length ? notifications.length : 0 }}</span>
                </button>
                <div class="bell__notifications" v-show="stateNotifications">
                  <p
                    v-for="(notification, index) in notifications"
                    :key="index"
                    v-on:click="changeStateNotificarion(notification.id)"
                  >
                    <strong>{{ notification.titulo }}</strong> <br />
                    {{ notification.contenido }}
                  </p>
                </div>
              </div>
              <div class="user">
                <div class="user__description">
                  <p>{{ info_usuario.usuario_nombre }}</p>
                  <span>{{ info_usuario.usuario_correo }}</span>
                </div>
                <div class="user__options">
                  <img
                    :src="info_usuario.usuario_foto"
                    onerror="this.src='https://cdn.systemico.net/systemico/icons/icon-person.svg'"
                    alt="Imagen de perfil"
                    @click="showProfile()"
                  />
                  <ul @mouseleave="hideProfile()">
                    <li>
                      <a href="/">Website</a>
                    </li>

                    <li>
                      <a href="/dashboard">Dashboard</a>
                    </li>
                    <li>
                      <a href="javascript:void(0)" @click="cerrarSesion"
                        >Salir</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </template>
          </nav>
        </b-col>
      </b-row>
    </b-container>

    <nav
      class="header_nav__menu"
      @mouseover="showSubMenu2()"
      @mouseleave="closeSubMenu()"
    >
      <button class="btn-close" @click="closeSubMenu">
        <b-icon icon="x-circle"></b-icon>
      </button>

      <template v-for="(item, index) in items">

        <h2 v-show="menu_active == item.name" :key="index + '-title'">
          {{ item.name[$props.lang] }}
        </h2>

        <b-container
          v-show="menu_active == item.name"
          :key="index + '-container'"
          fluid
        >
          <b-row>
            <b-col cols="12" md="12">
              <!-- CONTENT MENU TITLE -->
              <div v-for="tmenu in tmenus" :key="tmenu.id" class="menu_box_title" :id="'header_box_menu_'+index" v-if="(index===(tmenu.id-1))">
                <h3>
                  {{ tmenu.title[$props.lang]}}
                </h3>
                <p>
                  {{ tmenu.description[$props.lang] }}
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <template v-for="(subitem, subindex) in item.itemsMenu">
              <b-col cols="12" md="6" :key="subindex + '-container'">
                <a :href="subitem.link">
                  <img
                    v-if="subitem.icons !== ''"
                    :src="$cdn + 'icons/' + subitem.icons"
                    alt="icon card"
                    height="20rem"
                  />
                  <div>
                    <h3 v-if="subitem.title[$props.lang] !== undefined" :id="subitem.id + 'title'">
                      {{ subitem.title[$props.lang] }}
                      <small>{{subitem.subtitle[$props.lang]}}</small>
                    </h3>
                    <h3 v-else :id="subitem.id + 'title'">
                      {{ subitem.title[$props.lang] }}
                      <small>{{subitem.subtitle[$props.lang]}}</small>
                    </h3>

                    <p :id="subitem.id + 'description'" style="color:#777777;">
                      {{ subitem.description[$props.lang] }}
                    </p>
                  </div>
                </a>
              </b-col>
            </template>
          </b-row>
        </b-container>
      </template>
    </nav>
    <b-modal class="modal-access" id="modal-access-options" hide-footer>
      <template #modal-title>
        Using <code>$bvModal</code> Methods
      </template>
      <div class="d-block text-center">
        <h3>Hello From This Modal!</h3>
      </div>
    </b-modal>
  </section>
</template>

<script>
import '../../assets/styles/main.scss'
import store from '../../store/index.js'
import FlagButton from "../buttons/FlagButton.vue";
import ButtonMeetingComponent from "../buttons/ButtonMeetingComponent.vue";
import ButtonComponent from "../buttons/ButtonComponent.vue";

export default {
  name: 'HeaderSystemicoComponent',
  components: {
    ButtonMeetingComponent,
    ButtonComponent,
    FlagButton
  },
  props: {
    menuActive: Boolean,
    showLang: Boolean,
    country: String,
    lang: { default: 'EN', type: String },
    variant: String,
    loginURL: {
      type: String,
      default: '/login'
    },
    loginVariant: {
      type: String,
      default: 'secondary'
    },
    isLogin: {
      type: Boolean,
      default: true
    },
    signupURL: {
      type: String,
      default: '/login'
    },
    signupVariant: {
      type: String,
      default: 'warning'
    },
    isSignup: {
      type: Boolean,
      default: false
    },
    isScheduled: {
      type: Boolean,
      default: false
    },
    isProduct:{
      type: Boolean,
      default: false
    },
    logoProduct: {
      type: String,
      default: 'https://cdn.systemico.net/systemico/logo.png'
    }
  },
  data: function () {
    return {
      background: '#FF4500',
      tmenus: [
        {
          id: 1,
          title: {
            ES: "Soluciones alineadas a tu industria.",
            EN: "Solutions aligned to your industry."
          },
          description: {
            ES: "Software, expertos y metodologías alineadas a tu sector empresarial.",
            EN: "Software, experts and methodologies for your industry."
          }
        },
        {
          id: 2,
          title: {
            ES:"Software en la nube para tu empresa.",
            EN: "Software for small business."
          },
          description: {
            ES: "Soluciones que simplifican tu vida, ahorrando costos, simplificando procesos y acelerando tu gestión.",
            EN: "Software, experts and methodologies for your industry."
          }
        },
        {
          id: 3,
          title: {
            ES: "Software a la medida de tus necesidades y presupuesto.",
            EN:"Custom development for you and your budget."},
          description: {
            ES:"Desarrollo, pruebas y puesta en marcha de plataformas web, web services y dashboards de negocios.",
            EN:"Web platforms, microservices, integrations and more."
          }
        },
        {
          id: 4,
          title: {
            ES: "Interactua de manera sencilla y directa.",
            EN:"Interact in a simple and direct way."},
          description: {
            ES:"Escríbenos por Whatsapp, SMS, agenda una reunión o accede a nuestra web.",
            EN:"Contact us using facebook messenger, sms or email."}
        },
        {
          id: 5,
          title: {
            ES: "Aprende más de nuestro modelo y experiencia.",
            EN: "Learn more about our business model and experience."
          },
          description: {
            ES:"Verifica lo que necesites para tener claridad de quienes somos y como trabajamos.",
            EN:"Verify our journey, goals and values."
          }
        }
      ],
      items: [
        {
          name: { ES: 'Industrias', EN: 'Industries' },
          link: '/#idustries',
          lang: [],
          itemsMenu:
            [
              {
                id: '12',
                icons: 'icon-transport.svg',
                title: { ES: 'Lógistica y Transporte', EN: 'Transportation & Logistics' },
                subtitle: {ES: '', EN:''},
                description: { ES: 'Digitaliza, automatiza y moderniza tu negocio de transporte.', EN: 'Digital and automate transportation core processes.'},
                link: '/products/transpur',
                lang: ['ES']
              }
            ]
        },
        {
          name: { ES: 'Productos', EN: 'Products' },
          link: '/products',
          lang: ['ES', 'EN'],
          itemsMenu: [
            {
              id: '1',
              icons: 'products/icon-wabot.svg',
              title: { ES: 'Wabot', EN: 'Wabot'},
              subtitle: { ES: 'Marketing y chat bot.', EN: 'Marketing and chat bot.' },
              description: { ES: 'Marketing por Whatsapp, Email, SMS y llamadas de manera sencilla y automatiza tu atención de canales.', EN: 'Whatsapp, email, sms and call marketing with channel automation.'},
              link: '/products/wabot',
              lang: ['ES', 'EN']
            },
            {
              id: '2',
              icons: 'products/icon-suppmail.svg',
              title: { ES: 'Suppmail', EN: 'Suppmail' },
              subtitle: { ES: 'Helpdesk y ServiceDesk.', EN: 'Helpdesk and ServiceDesk.'},
              description: { ES: 'Simplifica la gestión de peticiones, recomendaciones, solicitudes, quejas y reclamos.', EN:'Simple, agile and cool solution to organize and solve your clients requests.'},
              link: '/products/suppmail'
            },
            {
              id: '3',
              icons: 'products/icon-grumpyturtle.svg',
              title: { ES: 'Grumpyturtle', EN: 'Grumpyturtle'},
              subtitle: {ES: 'Gestión de proyectos.', EN: 'Project management.' },
              description: {ES: 'Requerimientos, fases, equipo de trabajo y cronogramas en el mismo lugar.', EN:'Requirements, phases, team and schedule in one place.'},
              link: '/products/grumpyturtle'
            },
            {
              id: '4',
              icons: 'products/icon-focusmeet.svg',
              title: { ES: 'Focusmeet', EN: 'Focusmeet' },
              subtitle: {ES: 'Smart meetings.', EN: 'Smart meetings.'},
              description: {ES: 'Reuniones bien planificadas, con compromisos y resultados concretos.', EN:'Meetings with aids to get results. '},
              link: '/products/focusmeet'
            },
            {
            id: "5",
            icons: "products/icon-proposals.svg",
            title: { ES: "Proposals", EN: "Proposals" },
            subtitle: {ES: "Cotizaciones inteligentes.", EN: "Smart Quotations."},
            description: {
              ES: "Acelera tu proceso comercial optimizando la generación de cotizaciones con modelos estructurados para tus servicios, planes y más.",
              EN: "Impulse your commercial proces with simple, fast and clear quotations.",
            },
            link: "/products/proposals",
            },
            /** {
              id: "05",
              icons: "products/icon-chatter.svg",
              title: "Chatter",
              subtitle: "Chat de atención a clientes y equipo.",
              description: "Atención multicanal por chat para desplegar un contact center digital.",
              link: "/products/chatter",
            },
            {
              id: "06",
              icons: "products/icon-pugobox.svg",
              title: "PugoBox",
              subtitle: "Almacenamiento de archivos.",
              description: "Gestión documental y firma electrónica.",
              link: "/products/pugobox",
            },
            {
              id: "07",
              icons: "products/icon-penguin.svg",
              title: "PenguinStrategy",
              subtitle: "",
              description: "Impulso comercial con automatización e integración de canales.",
              link: "/products/penguinstrategy",
            },
            {
              id: "08",
              icons: "products/icon-collectio-n.png",
              title: "Collectio",
              subtitle: "",
              description: "Cobranza multicanal automatizada para asegurar la recuperación de cartera.",
              link: "/products/collectio",
            },**/
            {
              id: "09",
              icons: "products/icon-ducksite.svg",
              title: { ES: "Ducksites", EN: "Ducksites" },
              subtitle: {ES: "Tu sitio web en minutos", EN: "Your website in minutes."},
              description: {
                ES: "Tu sitio web sin complicaciones, con notificaciones automáticas, artículos, chat y mucho más..",
                EN: "Your website without complications, with automatic notifications, articles, chat and more."
              },
              link: "/products/ducksites",
            },
            {
              id: '10',
              icons: 'products/icon-transpur.svg',
              title: { ES: 'Transpur', EN: 'Transpur' },
              subtitle: {ES: 'Gestión de Flota.', EN: 'Fleet management.'},
              description: {ES: 'Gestión de documentos, alertas de vencimientos, miembros, rutas y mucho más.', EN: 'Routes, documents, due date alerts, members and more.'},
              link: '/products/transpur'
            },
            {
              id: '11',
              icons: 'products/icon-provedur.svg',
              title: { ES: 'Provedur', EN: 'Provedur' },
              subtitle: { ES: 'Gestión de proveedores.', EN: 'Contractors management.'},
              description: {ES: 'Actualización de documentos, ofertas, cotizaciones, notificaciones y mucho más.', EN: 'Contractor Profile, opportunities, quotations, notifications and more.' },
              link: '/products/provedur'
            },
            {
              id: '12',
              icons: 'products/icon-gogoals.svg',
              title: {ES: 'Gogoals', EN: 'Gogoals'},
              subtitle: { ES: 'Email marketing para crecer.', EN: 'Email marketing to growth.'},
              description: {
                ES: 'Transforma tu negocio con email marketing automatizado para mostrar tus productos y servicios.',
                EN: 'Transform your business with automate email marketing and show your products and services.'
              },
              link: '/products/gogoals'
            }
          ]
        },
        {
          name: { ES: 'Desarrollo', EN: 'Development' },
          link: '/development',
          lang: ["EN"],
          itemsMenu: [
            {
              id: '12',
              icons: 'icon-software.svg',
              title: { ES: 'Custom Development', EN: 'Custom Development' },
              subtitle: {
                ES: 'Soluciones a la medida de tus necesidades.',
                EN: 'Custom solutions for your business.'
              },
              description: {
                ES: 'Desarrollo de software genial para tue startup o empresa.',
                EN: 'Cool software development for your startup and company.'
              },
              link: '/development'
            },
            {
              id: '13',
              icons: 'icon-developed.svg',
              title: { ES: 'API & Microservicios', EN: 'API & Microservices' },
              subtitle: {
                ES: 'REST, SOAP y HOOKS.',
                EN: 'REST, SOAP and HOOKS.'
              },
              description: {
                ES: 'Desarrollo de software para creat API REST, SOAP y WEBHOOKS con Python.',
                EN: 'Software development to create API REST, SOAP y WEBHOOKS with Python.'
              },
              link: '/development/api'
            },
            {
              id: '14',
              icons: 'icon-requirements.svg',
              title: { ES: 'Bots e Inteligencia Artificial', EN: 'Bots & AI'},
              subtitle: {
                ES: 'Bot para automatizar tu negocio.',
                EN: 'Automate your business with bots.'
              },
              description: {
                ES: 'Desarrollo de bots para simplificar y acelerar las operaciones de tu negocio.',
                EN: 'Bots development to simplify and accelerate your business operations.'
              },
              link: '/development/bots'
            },
            {
              id: '15',
              icons: 'icon-design.svg',
              title: { ES: 'Dashboards de Negocio.', EN: 'Business Dashboards' },
              subtitle: { ES: 'Datos, informes y análisis.', EN: 'Data and analytics.' },
              description: {
                ES: 'Implementación de dashboards con información clave de negocios.',
                EN: 'Dashboards development to show key indications and stats.'
              },
              link: '/development/dashboard'
            },
            {
              id: '15',
              icons: 'icon-requirements.svg',
              title: { ES: 'Servicios Cron.', EN: 'Cron Services.' },
              subtitle: { ES: 'Datos, informes y análisis.', EN: 'Data and analytics.' },
              description: {
                ES: 'Implementación de dashboards con información clave de negocios.',
                EN: 'Dashboards development to show key indications and stats.'
              },
              link: '/development/cron'
            },
            /**{
              id: '15',
              icons: 'icon-requirements.svg',
              title: { es: 'Análisis de Requerimientos', en: 'Requirements Analyst' },
              subtitle: { es: 'Consultor experto en requerimientos.', en:'Expert business analyst consultant.' },
              description: {es: 'Análisis detallado de necesidades y soluciones', en: 'Detailed requirements definition and limits.'},
              link: '/service_requirements'
            },
            {
              id: '14',
              icons: 'icon-design.svg',
              title: {es: 'Diseño de Software', en: 'Software Design'},
              subtitle: { es: 'UI y UX .', en:'UI and UX.' },
              description: {
                  es: 'Diseño, prototipos, perfiles de usuario, pruebas de usuario y más.',
                  en: 'Design, prototype, user profiles, user testing and more.'
              },
              link: '/service_design'
            },
            {
              id: '15',
              icons: 'icon-code.svg',
              title: 'Desarrollo de Software',
              subtitle: { es: '', en:'' },
              description: 'Metodologías ágiles, estándares e integración',
              link: '/service_developing'
            },
            {
              id: '16',
              icons: 'icon-software.svg',
              title: 'Pruebas de Software',
              subtitle: { es: '', en:'' },
              description: 'Validaciones y cumplimiento funcional del software',
              link: '/service_testing'
            },**/
            {
              id: '17',
              icons: 'icon--soporte.svg',
              title: {ES: 'Soporte por Demanda', EN:'Support on demand' },
              subtitle: { ES: 'Soporte Experto.', EN: 'Expert Support.' },
              description: {
                ES: 'Soporte TI a tu medida por horas, con expertos disponibles para tí.',
                EN: 'Technnical support for your projects and goals.'
              },
              link: '/service_support'
            }
          ]
        },
        {
          name: { ES: 'Contacto', EN: 'Contact' },
          // link: 'https://systemico.net/support/registro',
          link: '#contact',
          lang: ["ES", "EN"],
          itemsMenu: [
            // { icons: '', title: 'Soporte y Soluciones', description: 'Solicitudes y atención inmediata', link: 'https://systemico.net/support' },
            {
              id: '26',
              icons: 'icon-whatsapp.svg',
              title: { ES: 'WhatsApp Empresarial', EN: 'Business WhatsApp' },
              subtitle: { ES: 'Siempre disponible.', EN: 'Always available.' },
              description: {
                ES: 'Interactua por nuestro canal de Whatsapp y registra tus soportes, solicita cotizaciones y más.',
                EN: 'Interact using your WhatsApp account to register a support, request a quote and more.'
              },
              link: 'https://wa.me/13149883020?text=Hola, quisiera saber más!',
              target: '_blank'
            },
            {
              id: '27',
              icons: 'icon-messenger-blue.svg',
              title: { ES: 'Facebook Messenger', EN: 'Facebook Messenger' },
              subtitle: { ES: 'Siempre disponible.', EN: 'Always available.' },
              description: {
                ES: 'Interactua por Facebook Messenger y registra tus soportes, solicita cotizaciones y más.',
                EN: 'Interact using your Facebook messenger account to register a support, request a quote and more.'
              },
              link: 'https://m.me/systemico',
              target: '_blank'
            },
            {
              id: '28',
              icons: 'icon--soporte.svg',
              title: { ES: 'Solicitar Soporte', EN: 'Request Support' },
              subtitle: { ES: 'Envia tu solicitud.', EN: 'Send your request.' },
              description: {
                ES: 'Remite tu solicitud y recibe ayuda de nuestro panel de expertos.',
                EN: 'Obtain a form to register your request, we are ready to help you.'
              },
              link: '/support'
            },
            /**{
              id: '29',
              icons: 'icon--cotizacion.svg',
              title: 'Solicitud Directa',
              subtitle: { es: '', en:'' },
              description: '¿No eres cliente?, Registra una solicitud directa.',
              link: 'https://suppmail.systemico.net/customer/systemicosoftware.com'
            **/
          ]
        },
        {
          name: { ES: 'Más', EN: 'More' },
          link: '/#software',
          lang: [],
          itemsMenu: [
            {
              id: '17',
              icons: 'icon-systemico.svg',
              title: { ES: 'Sobre Nosotros', EN: 'About us'},
              subtitle: { ES: '', EN:'' },
              description: 'Somos aliados estratégicos en tecnología',
              link: '/company_about'
            },
            {
              id: '18',
              icons: '',
              title: { ES: 'Metodología', EN: 'Methodology' },
              subtitle: { ES: '', EN:'' },
              description: 'Consultoría, desarrollo y resultados efectivos',
              link: '/company_methodology'
            },
            {
              id: '19',
              icons: '',
              title: 'Nuestra Historia',
              subtitle: { ES: 'Simpre mejorando.', EN:'Permanent improve.' },
              description: 'Cambiar el mundo positivamente',
              link: '/company_history'
            },
            {
              id: '20',
              icons: '',
              title: { ES: 'Aliados', EN: 'Partners'},
              subtitle: { ES: 'Sinergías que generan mejores servicios.', EN:'' },
              description: 'Socios para mejorar la eficiencia tecnológica',
              link: '/company_clients'
            },
            {
              id: '21',
              icons: '',
              title: 'Inversores',
              subtitle: { ES: '', EN:'' },
              description: 'Systemico y la perspectiva de crecimiento global',
              link: '/company_investors'
            },
            {
              id: '22',
              icons: '',
              title: 'Conoce nuestro Equipo',
              subtitle: { ES: '', EN:'' },
              description: 'Juntos lo hacemos posible',
              link: '/company_team'
            },
            {
              id: '23',
              icons: '',
              title: 'Trabaja con nosotros',
              subtitle: { ES: '', EN:'' },
              description: 'Únete a nuestro equipo de trabajo',
              link: '/company_work'
            },
            {
              id: '24',
              icons: '',
              title: 'Casos de Estudio',
              subtitle: { ES: '', EN:'' },
              description:
                  'Ve los casos de éxito y el resultado con datos reales.',
              link: 'https://systemico.net/support'
            },
            {
              id: '25',
              icons: '',
              title: '¿Por qué ser nuestro cliente?',
              subtitle: { ES: '', EN:'' },
              description: 'Te ayudamos a impulsar tu negocio con tecnología',
              link: 'https://systemico.net/support'
            }]
        }
      ],

      config1: { text: { ES: 'Agendar', EN: 'Schedule'}, class: 'primary' },
      /**config2: { text: { ES: 'Mi Cuenta', EN: 'Administrar'}, class: 'secundary' },**/
      config_login: { text: { ES: 'Ingresar', EN: 'Sign In'}, class: 'dark' },
      config_signup: { text:  { ES: 'Crear Cuenta', EN: 'Sign Up'}, class: 'dark' },
      config3: { text: 'Salir', class: 'primary' },
      menu_title: '',
      menu_list: [],
      menu_active: '',
      language: '',
      info_usuario: null,

      stateTranslate: false,

      stateNotifications: false,

      notifications: {}
    }
  },
  beforeMount() {
    // LANG TO UPPPER
    this.lang = this.lang.toUpperCase()
    this.country = this.country.toUpperCase()
  },
  mounted () {
    if (this.variant === undefined) {
      this.variant = 'blue'
    }
    const language = localStorage.getItem('userLanguage')
    if (language === null && navigator.language.substring(0, 2) !== 'es' && navigator.language.substring(0, 2) !== 'en') {
      this.changeLanguage('EN')
      localStorage.setItem('requestLanguage', 'true')
    } else if (language === null && (navigator.language.substring(0, 2) === 'es' || navigator.language.substring(0, 2) === 'en')) {
      localStorage.setItem('userLanguage', navigator.language.substring(0, 2))
      this.language = navigator.language.substring(0, 2).toUpperCase()
    } else {
      this.language = localStorage.getItem('userLanguage').toUpperCase()
    }
    const userInfo = localStorage.getItem('userInfo').toUpperCase()
    // GET THE USER INFO
    if (userInfo != null) {
      this.info_usuario = JSON.parse(localStorage.getItem('userInfo'))
      this.info_usuario.foto = this.info_usuario.foto.replace(/&amp;/g, '&')
      this.loadNotifications()
    }
  },

  methods: {
    loadNotifications: async function () {
      await store
        .dispatch('fetchGetNotification', {})
        .then((response) => {
          if (response.CODE != 1 && response.CODE != 6) {
            this.$store.commit('setNotification', {
              state: true,
              title: 'Error',
              text: response.DESCRIPTION
            })
          } else {
            this.notifications = response.DATA
          }
        })
    },

    changeStateNotificarion: async function (id) {
      const parametros = {
        notificacion_id: id
      }
      await store.dispatch('changeStatetNotification', parametros).then(response => {
        console.log(response)
        this.loadNotifications()
        this.closeNotifications()
      })
    },

    showMenu: function () {
      const header = document.getElementsByClassName('header_nav')
      header[0].classList.toggle('header_nav__show')
      // this.menuActive = !this.menuActive;
    },

    showSubMenu: function (item) {
      const menu = document.getElementsByClassName('header_nav__menu')
      if (menu[0].classList == 'header_nav__menu') {
        menu[0].classList.toggle('header_nav__menu__show')
        this.menu_title = item.name
        this.menu_list = item.itemsMenu
        this.menu_active = item.name
      } else if (
        menu[0].classList == 'header_nav__menu header_nav__menu__show' &&
        this.menu_active != item.name
      ) {
        this.menu_title = item.name
        this.menu_list = item.itemsMenu
        this.menu_active = item.name
      } else if (
        menu[0].classList == 'header_nav__menu header_nav__menu__show' &&
        this.menu_active == item.name
      ) {
        this.closeSubMenu()
      }
    },

    showSubMenu2: function () {
      const menu = document.getElementsByClassName('header_nav__menu')
      menu[0].classList.add('header_nav__menu__show')
    },

    closeSubMenu: function () {
      const menu = document.getElementsByClassName('header_nav__menu')
      menu[0].classList.remove('header_nav__menu__show')
    },

    sinContenido: function () {
      const pathname = location.pathname

      if (pathname === '/enConstruccion') {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        this.$router.push({
          name: 'enConstruccion'
        })
      }
    },

    redirect: function (link, target = '') {
      if (target == '') {
        window.location = link
      } else {
        window.open(link, '_blank')
      }
    },
    agendar: function () {
      Calendly.initPopupWidget({ url: 'https://calendly.com/systemico/30min' }); return false
    },
    showTranslate: function () {
      this.stateTranslate = true
    },

    closeTranslate: function () {
      this.stateTranslate = false
    },

    changeLanguage: function (idioma) {
      localStorage.setItem('userLanguage', idioma)

      this.language = idioma

      //Transifex.live.translateTo(idioma, true)

      this.closeTranslate()
    },

    showNotifications: function () {
      this.stateNotifications = !this.stateNotifications
    },

    closeNotifications: function () {
      this.stateNotifications = false
    },

    cerrarSesion: function () {
      localStorage.removeItem('userInfo')
      localStorage.removeItem('hischat')
      // localStorage.removeItem("updateLanguage");
      window.location.href = '/api/glogin/logout'
    },

    showProfile: function () {
      const content = document.getElementsByClassName('user')
      content[0].classList.add('user__show')
    },

    hideProfile: function () {
      const content = document.getElementsByClassName('user')
      content[0].classList.remove('user__show')
    },

    openRecommendations: function () {
      const element = document.getElementById('recommendationsCustom')
      element.classList.add('d-block')
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../basic.scss";
.variant-blue{
  background:#171e44;
}
.variant-orange{
  background:#FF7518;
}
.header_nav {
  position: fixed;
  top: 0;
  left: 0;
  padding:0;
  z-index: 1000;
  transition: all 0.3s;
  color:#fefefe;
  &__active {
    position: fixed;
    top: 0;
    .header_nav__logo {
      display: none;
      height: 38px;
    }
    .header_nav__icon {
      display: block;
      height: 38px;
    }
    .header_nav__btn {
      border-color: #ffffff;
      background: transparent;
      border-radius: 5px;
      border: 2px solid #fefefe;
      padding: 0.3rem;
      width: 2.5rem;
      margin-left:38%;
      svg {
        color: #ff8028;
      }
    }
    .header_nav__nav {
      a {
        color: #ffffff;

        &:hover {
          color: #ff8028;
        }
      }
    }

    .translate {
      .btn-translate {
        svg {
          color: #ffffff;
        }
      }
    }

    .user {
      padding: 0;
      border-radius: 0;
      background: transparent;
      font-size:2rem;
      // &__description {
      //   p {
      //     color: #ffffff;
      //   }

      //   span {
      //     color: #ffffff;
      //   }
      // }
    }

    .bell {
      &__icon {
        svg {
          color: #ffffff;
        }
      }
    }
  }

  &__show {
    position: relative;
    top: 0rem;
    background-color: #171e44;

    .header_nav__logo {
      display: none;
    }

    .header_nav__icon {
      display: block;
    }

    .header_nav__btn {
      background: transparent;
      border-radius: 5px;
      border: 2px solid #fefefe;
      padding: 0.3rem;
      width: 2.5rem;
      svg {
        color: #ff8028;
      }
    }

    .header_nav__nav {
      height: auto;
      overflow: inherit;

      a {
        color: #ffffff;
      }
    }

    .translate {
      .btn-translate {
        svg {
          color: #ffffff;
        }
      }
    }

    .bell {
      &__icon {
        svg {
          color: #ffffff;
        }
      }
    }
  }

  &__logo {
    width: auto;
    margin:0;
    display: block;
    cursor: pointer;
  }

  &__icon {
    margin-top: 15px;
    margin-bottom: 15px;
    display: none;
    cursor: pointer;
  }

  /**&__btn {
    width: 3.5rem;
    min-width: 3.5rem;
    height: 3.5rem;
    margin-left: auto;
    border: 0.2rem solid #171e44;
    border-radius: 0.5rem;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 2.5rem;
      color: #171e44;
    }
  }**/

  &__nav {
    height: 0;
    overflow: hidden;

    .item {
      margin: 0 0.5rem 0.5rem 0;
      font-size: 1rem;
      font-weight: bold;
      color: #171e44;
      line-height: 1rem;
      text-align: center;
      display: block;

      &:hover {
        color: #ff8028;
        text-decoration: none;
      }
    }

    /**& > button {
      margin: 2rem auto;
    }**/
  }

  &__menu {
    height: 0;
    background-color: #f8fafb;
    border-right: 2rem solid #ff8028;
    overflow: hidden;
    position: relative;
    &__show {
      width: 100vw;
      height: 100vh;
      padding: 1rem 1rem;
      position: fixed;
      top: 0;
      left: 0;
      overflow: auto;
    }
    .btn-close {
      width: 2rem;
      min-width: 2rem;
      height: 2rem;
      padding: 0;
      position: relative;
      border:none;

      &:hover {
        svg {
          color: #ff8028;
        }
      }

      svg {
        font-size: 2rem;
        color: #171e44;
      }
    }

    a {
      width: 100%;
      padding: 0.5rem;
      border: 2px solid transparent;
      background: transparent;
      display: flex;

      &:hover {
        text-decoration:none;
        border: 2px dashed #aaaaaa;
        border-radius: 0.3rem;
        padding: 0.5rem;
        background: #fefefe;
        h3 {
          /**color: #006df0;**/
        }
      }

      img {
        border: 2px solid #bbbbbb;
        border-radius: 0.2rem;
        background:#fefefe;
        width: 2rem;
        height: 2rem;
        padding: 0.2rem;
        max-width: 3rem;
        margin-right: 1rem;
      }
    }

    h2 {
      margin: 0 0 1rem 0;
      font-family: "Quicksand", sans-serif;
      font-size: 1.5rem;
      font-weight: bold;
      color: #ff8028;
      line-height: 1.5rem;
      text-align: center;
    }

    h3 {
      /**margin: 0 0 1rem 0;**/
      font-family: "Quicksand", sans-serif;
      font-size: 1rem;
      font-weight: bold;
      color: #171e44;
      line-height: 1rem;
      text-align: center;
    }

    p {
      margin: 0;
      font-family: "Quicksand", sans-serif;
      font-size: 0.8rem;
      font-weight: normal;
      color: #171e44;
      line-height: 0.8rem;
      text-align: center;
    }
  }
  .menu_box_title{
    border-bottom:3px solid #aaaaaa;
    margin-bottom:1em;
    padding-bottom:1em;
    h3{
      font-size:1.5em;
      line-height:1.5rem;
      color:#fd7e14;
    }
    p{
      font-size:1.5em;
      line-height:1.5rem;
    }
  }
  .translate {
    margin: 2rem auto;
    position: relative;

    &.show {
      .btn-translate {
        svg {
          color: #ff8028;
        }
      }
    }
    &.show {
      .options-translate {
        height: auto;
      }
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .btn-translate {
    width: 3rem;
    min-width: 3rem;
    height: 3rem;
    margin: 0 auto;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      font-size: 2rem;
      color: #171e44;
      pointer-events: none;
    }

    span {
      padding: 0 0.4rem;
      border-radius: 3rem;
      background-color: #171e44;
      font-family: "Mulish", sans-serif;
      font-size: 1rem;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 1.5rem;
      left: 1.5rem;
    }

    &:hover,
    &.show {
      svg {
        color: #ff8028;
      }
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: 992px) {
      margin: 0;
    }
  }

  .options-translate {
    a {
      margin: 2rem 0;
      font-size: 1.3rem;
      font-weight: bold;
      color: #ffffff;
      line-height: 1.3rem;
      text-align: center;
      display: block;
      text-transform: uppercase;
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: 992px) {
      min-width: 10rem;
      border-radius: 0.5rem;
      background-color: #ffffff;
      box-shadow: 0px 3px 6px #4d576f29;
      position: absolute;
      top: 3rem;
      right: 0;

      a {
        margin: 0;
        padding: 1rem;
        font-family: "Mulish", sans-serif;
        font-size: 1.4rem;
        font-weight: lighter;
        color: #171e44 !important;
        line-height: 1.5rem;
        text-align: left;
        display: block;
        text-transform: none;

        &:hover {
          text-decoration: none;
          background-color: #e9ecf4;
        }
      }
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    &__active {
      padding: 0;
    }

    &__show {
      .header_nav__nav {
        height: 100%;
      }
    }

    &__btn {
      display: none;
    }

    &__nav {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      overflow: inherit;

      & > button {
        margin: 0 1rem;
      }

      & > :last-child {
        margin-right: 0;
      }

      .item {
        height: 100%;
        margin: 0 2rem;
        display: flex;
        align-items: center;
      }
    }

    &__menu {
      &__show {
        width: 100%;
        height: auto;
        padding: 2rem 0;
        border-bottom: 0.2rem solid #ff8028;
        position: absolute;
        top: 100%;
      }

      .btn-close {
        position: absolute;
        border:none;
        right: 1vw;
        z-index: 10;
        background:none;
      }

      h2 {
        display: none;
      }

      h3,
      p {
        text-align: left;
      }
    }
  }
}

.modal-access{
  font-size:2em;
  font-family:$font-quicksand;
}

.header_nav .user {
  margin-left: 1rem;
  padding: 1rem;
  border-radius: 0 1rem;
  background-color: #171e44;
  display: flex;
  align-items: center;
  font-size:1rem;

  &__description {
    padding: 0 0.5rem;

    p {
      margin: 0;
      font-size: 0.9rem;
      font-weight: 500;
      // color: #171e44;
      color: #ffffff;
      text-align: right;
      display: block;
    }

    span {
      margin: 0;
      font-size: 0.8rem;
      font-weight: normal;
      color: #ffffff;
      text-align: right;
      display: block;
    }
  }

  &__options {
    position: relative;

    & > img {
      width: 2.6rem;
      min-width: 2.6rem;
      height: 2.6rem;
      border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
    }

    ul {
      width: 10rem;
      padding: 0;
      margin-top: 1rem;
      border: 0.15rem solid #d1d5e9;
      border-radius: 0.5rem;
      box-shadow: 0px 3px 6px #0000001a;
      background-color: #ffffff;
      overflow: hidden;
      position: absolute;
      right: 0;
      display: none;

      li {
        padding: 0;
        margin: 0 0 0.1rem;
        list-style: none;
        a {
          margin: 0;
          padding: 0.5rem 1.5rem;
          font-size: 1.4rem;
          font-weight: normal;
          color: #4b4747 !important;
          cursor: pointer;
          display: block;

          &:hover {
            text-decoration: none;
            color: #4b4747;
          }
        }
      }
    }
  }

  &__show {
    .user__options ul {
      display: block;
    }
  }
}

.bell {
  margin: 0 auto;
  position: relative;
  z-index: 10;

  &__icon {
    width: 2rem;
    min-width: 2rem;
    height: 2rem;
    margin: 0 auto;
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      font-size: 1.5rem;
      color: #171e44;
      pointer-events: none;
    }

    span {
      padding: 0 0.4rem;
      border-radius: 3rem;
      background-color: #171e44;
      font-family: "Mulish", sans-serif;
      font-size: 1rem;
      font-weight: bold;
      color: #ffffff;
      text-align: center;
      text-transform: uppercase;
      position: absolute;
      top: 2rem;
      left: 1.5rem;
    }
  }

  &__notifications {
    // min-width: 25rem;
    max-height: 28.6rem;
    border: 0.1rem solid #d1d5e9;
    border-radius: 0.5rem;
    background-color: #ffffff;
    position: absolute;
    top: 4rem;
    right: 0;
    overflow: auto;
    box-shadow: 0rem 0.3rem 0.6rem #00000029;

    p {
      width: 30rem;
      margin: 0;
      padding: 1rem 2rem;
      font-family: "Mulish", sans-serif;
      font-size: 1.2rem;
      font-weight: normal;
      color: #171e44;
      text-align: left;
      overflow-wrap: break-word;
      // display: -webkit-box;
      // -webkit-box-orient: vertical;
      // -webkit-line-clamp: 2;
      // overflow: hidden;

      &:hover {
        background-color: #f5f9ff;
      }
    }
  }

  // responsive para dispositivos de tablets en adelante
  @media (min-width: 992px) {
    margin: 0 0.5rem;

    &__icon {
      margin: 0;
    }

    & ~ .user {
      margin-left: 1rem;
    }
  }
}

.contenedor-recomendaciones {
  button {
    width: auto;
    height: auto;
    padding: 0.2rem 1rem;
    border: 0;
    border-radius: 0.5rem 0.5rem 0 0;
    background: #171e44;
    display: flex;
    align-items: center;
    justify-content: center;
    transform-origin: 0 0;
    transform: rotate(270deg);
    position: fixed;
    top: 50vh;
    right: -13.9rem;

    p {
      padding: 0;
      margin: 0 0 0 0.5rem;
      font-family: "Mulish", sans-serif;
      font-size: 1.4rem;
      color: #ffffff;
      font-weight: 600;
    }

    img {
      width: 2rem;
      height: auto;
    }
  }
}


</style>
