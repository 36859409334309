<template>
  <ButtonComponent :variant="$props.variant" :text="$props.text" icon="calendar-check" @click="createMeeting()" style="display: inline;"></ButtonComponent>
</template>
<script>
import ButtonComponent from './ButtonComponent.vue';
export default {
  name: 'ButtonMeetingComponent',
  components:{
    ButtonComponent
  },
  props: {
    text: String,
    variant: String
  },
  methods: {
    createMeeting: function () {
      Calendly.initPopupWidget({ url: 'https://calendly.com/systemico/30min' }); return false
    }
  }
}
</script>
<style lang="scss" scoped>

</style>
