<template>
  <section class="tags_component">
    <h2 v-html="title"></h2>
    <b-container fluid v-if="$props.tags.length > 0" class="text-center">
      <b-badge v-for="tag in $props.tags"
                 :key="tag.id"
                 variant="light"
                 class="mr-2 mt-3"
      >
        {{tag.name}}
      </b-badge>
    </b-container>
  </section>
</template>
<script>
export default {
  name: 'TagsComponent',
  props: {
    tags: Array,
    title: String,
    background: {
      default: '#fefefe',
      type: String
    },
    titleColor:{
      default: '#222222',
      type: String
    },
    subtitleColor:{
      default: '#222222',
      type: String
    },
    contentColor: {
      default: '#222222',
      type: String
    },
    imgStyle: {
      default: 'border-radius:20px;overflow:hidden;border:5px solid #fefefe;',
      type: String
    }
  }
}
</script>

<style scoped lang="scss">
  .tags_component{
    font-size:1.5rem;
    padding: 2rem;
    background: v-bind(background);
    h2{
      font-size:3.5rem;
      text-align: center;
      color: v-bind(titleColor);
    }
    .badge{
      font-size:1.5rem
    }
  }
  @media (max-width: 768px) {
    .tags_component{
      padding: 1rem;
      h2{
        font-size:2rem;
      }
      .badge{
        font-size:1.1rem
      }
    }

  }
</style>