<template>
  <button :class="'button--' + config.class" @click="$emit('clicked')">
      {{config.text}}
      <div class="point"></div>
  </button>
</template>

<script>

export default {
  name: 'buttonCustom',

  props: {
    config: Object,
    icon: String
  }
}
</script>
