<template>
  <section class="contenedor-modal" v-if="state">
    <div class="back" v-on:click="cerrarModal()"></div>
    <div class="modal-custom">
      <header>
        <h2>Welcome | Bienvenido</h2>
      </header>
      <main>
        <b-container fluid>
          <b-row>
            <b-col>
              <div class="contenedor-parrafo-modal">
                <p class="p-0">
                  <b-icon icon="globe"></b-icon>
                  Choose a language
                </p>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </main>
      <div class="contenedor-opciones">
        <buttonPrimry :configButton='configButton' @funcionBtn="changeLanguage('en')"/>
        <buttonPrimry :configButton='configButton2' @funcionBtn="changeLanguage('es')"/>
      </div>
    </div>
  </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'

export default {
  name: 'modalTranslate',
  components: {
    buttonPrimry
  },

  data: function () {
    return {
      configButton: { text: 'English', class: 'button--secundary' },
      configButton2: { text: 'Español', class: 'button--secundary' }
    }
  },

  mounted () {
    const requestLanguage = localStorage.getItem('requestLanguage')
    //this.$store.commit('setModalTranslate', { state: false })
    // IF IS DIFFERENT OF SPANISH OR ENGLISH AND DIDN'T SELECT A LANGUAGE
    if (requestLanguage === 'true' && (navigator.language.substring(0, 2) !== 'es' && navigator.language.substring(0, 2) !== 'en')) {
      this.$store.commit('setModalTranslate', { state: true })
      localStorage.setItem('requestLanguage', 'false')
    }
    else if (requestLanguage === 'true' && (navigator.language.substring(0, 2) === 'es' || navigator.language.substring(0, 2) === 'en')) {
      this.changeLanguage(navigator.language.substring(0, 2))
    }
    else {
      this.language = localStorage.getItem('userLanguage')
      this.changeLanguage(this.language)
      this.$store.commit('setModalTranslate', { state: false })
    }
  },

  computed: {
    state () {
      const data = this.$store.getters.getModalTranslate
      return data.state
    }
  },

  methods: {
    cerrarModal: function () {
      this.$store.commit('setModalTranslate', { state: false })
    },

    changeLanguage: function (idioma) {
      localStorage.setItem('userLanguage', idioma)
      Transifex.live.translateTo(idioma, true)
      this.cerrarModal()
      this.$emit('changeLanguage', idioma)
    }
  }
}
</script>
