<template>
    <section class="allies">
        <b-container fluid='xl'>
            <b-row>
                <b-col cols='12'>
                    <h2 class="allies__title">
                      {{ title[$props.lang] }}
                      <div class="line"></div>
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <template v-for='(item, index) in links_1'>
                    <b-col cols='12' md='6' lg='4' xl='2' :key='index'>
                        <div class="allies__item">
                            {{ item.text }}
                        </div>
                    </b-col>
                </template>
            </b-row>
            <b-row>
                <b-col cols='12' xl=''></b-col>
                <template v-for='(item, index) in links_2'>
                    <b-col cols='12' md='6' lg='4' xl='2' :key='index'>
                        <div class="allies__item">
                            {{ item.text }}
                        </div>
                    </b-col>
                </template>
                <b-col cols='12' xl=''></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'allies',
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      title: {es: 'Aliados e Integraciones', en: 'Partners and Integrations'},

      links_1: [
        { text: 'AWS' },
        { text: 'GITHUB' },
        { text: 'GOOGLE CLOUD' },
        { text: 'BITBUCKET' },
        { text: 'CLUSTER TI' },
        { text: 'WYDEA LAB' }
      ],

      links_2: [
        { text: 'FEDESOFT' },
        { text: 'PRADEK' },
        { text: 'MINCIENCIAS' },
        { text: 'CREAME' }
      ]
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../../systemico.ui/basic.scss";
</style>
