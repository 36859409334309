<template>
  <b-nav-item-dropdown :title="$props.country" class="flag-button" style="list-style-type: none;" right no-caret no-flip>
    <!-- Using 'button-content' slot -->
    <template #button-content>
      <em>
        <b-avatar  class="icon-flag" :src="countries[$props.country].icon" rounded="circle"></b-avatar>
      </em>
    </template>
    <b-dropdown-item v-if="country.code != $props.country" v-for="country in countries" :key="country.id" class="item-flag"  :href="country.url">
      <b-avatar class="icon-flag" :src="country.icon" rounded="circle"></b-avatar> {{country.name[$props.lang]}}
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>
<script>
export default {
  name: 'FlagButton',
  props: {
    country: String,
    lang: { default: 'EN', type: String }
  },
  beforeMount(){
    this.lang = this.lang.toUpperCase()
    this.country = this.country.toUpperCase()
  },
  data: function () {
    return {
      countries: {
        COLOMBIA: { id: 1, code: 'COLOMBIA', name: { ES: 'Colombia', EN: 'Colombia'}, icon: 'https://cdn.systemico.net/systemico/flags/flag-colombia.svg', url:'/es-co', show: true },
        USA: { id: 2, code: 'USA', name: { ES: 'Estados Unidos', EN: 'United States of America'}, icon: 'https://cdn.systemico.net/systemico/flags/flag-usa.svg', url:'/en-us', show: true },
        CANADA: { id: 3, code: 'CANADA', name: { ES: 'Canadá', EN: 'Canada'}, icon: 'https://cdn.systemico.net/systemico/flags/flag-canada.svg', url:'/en-ca', show:true }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
  .flag-button {
    .icon-flag{
      height: 1.5rem;
      width: 1.5rem;
    }
    .item-flag{
      font-size: 1rem;
      font-family:Quicksand, sans-serif;
      border-bottom: 1px dotted #aaaaaa;
    }
    .item-flag a{
      font-size: 0.5rem;
    }
  }
</style>