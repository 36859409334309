<template>
    <section class="pradek">
        <div class="pradek__wallpaper">
          <img :src="$cdn + 'images/image-wallpaper.svg'" alt="image wallpaper">
        </div>
        <b-container fluid='xl'>
            <b-row>
                <b-col cols='12' lg='4'>
                  <img class="pradek__logo" :src="$cdn + 'logos/logo-pradek.svg'" alt="logo pradek">
                </b-col>
                <b-col cols='12' lg='4'>
                    <h2 class="pradek__title">
                        {{ title }}
                    </h2>
                    <p class="pradek__description">
                        {{ description }}
                    </p>
                    <buttonPrimry class="mx-auto" :configButton='configButton' @funcionBtn="funcioRedirect('https://pradek.co/')"/>
                </b-col>
                <b-col cols='12' lg='4'>
                  <div class="pradek__figure">
                    <img :src="$cdn + 'images/image-arrow.png'" alt="image woman">
                    <img :src="$cdn + 'images/image-woman.png'" alt="image woman">
                  </div>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'

export default {
  name: 'pradek',

  components: {
    buttonPrimry
  },

  data: function () {
    return {
      title: 'Conoce nuestro aliado.',

      description: 'Incrementa el potencial de tu empresa con productos especializados en la nube.',

      configButton: { text: 'Explorar', class: 'button--primary' }
    }
  },
  methods: {
    funcioRedirect: function (link) {
      window.location=link
    }
  }
}
</script>
