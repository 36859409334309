<template>
    <section class="rocket" id="rocket">
        <b-container fluid='xl'>
            <b-row>
                <b-col>
                    <img class="rocket__icon" :src="$cdn + 'icons/icon-rocket--white.svg'" alt="icon rocket">
                    <p class="rocket__description">
                        {{ description[$props.lang] }}
                    </p>
                </b-col>
            </b-row>
            <b-row class="rocket__counters">
                <template v-for="(item, index) in items">
                    <b-col cols='12' md='6' lg='3' :key="index">
                        <div class="rocket__counter">
                            <span>
                                {{ item.number }}
                            </span>
                            <h3>
                                {{ item.text[$props.lang] }}
                            </h3>
                        </div>
                    </b-col>
                </template>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'rocket',
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      description: {
        es: '¡Deja todo en nuestras manos mientras sigues enfocándote en tu negocio!',
        en: 'Share your dreams with our team to start to work and grow with your business, sales and clients!'
      },

      items: [
        { number: '+200', text: {es: 'Proyectos éxitosos', en: 'Successful projects'} },
        { number: '+15', text: {es: 'Años de experiencia', en:'Years of experience'} },
        { number: '+10M', text: {es: 'Horas de programación', en: 'Coding hours' } },
        { number: '+12', text: {es: 'Bots atendiendo', en:'Running bots' } }
      ],
    }
  }
}
</script>
