<template>
  <section class="plans container-fluid" id="plans" :style="'background:'+$props.background">
    <h2 class="display-3 text-center pb-4">{{$props.title}}</h2>
    <b-row class="justify-content-md-center p-2">
      <b-col  v-for="plan in $props.plans" :key="plan.id"  >
        <b-card bg-variant="light"
                text-variant="dark"
                :title="plan.title"
                class="text-center mb-2 pb-3"
                tag="article"
        >
          <b-card-text v-html="plan.description"></b-card-text>
          <b-list-group class="mb-5">
            <b-list-group-item v-for="feature in plan.features" :key="feature.id" class="bg-transparent" v-html="feature.value"></b-list-group-item>
          </b-list-group>
          <div class="pricing">
            <span class="pricing_value">{{plan.pricing}}</span>
            <span class="pricing_currency">{{plan.currency}}</span> /
            <span class="pricing_frecuency">{{plan.frecuency}}</span>
          </div>
          <ButtonComponent class="mr-2 mb-2" :text="plan.labelButton" variant="secondary" :href="plan.link"></ButtonComponent>
          <label class="label small mt-5">{{plan.noteButton}}</label>
        </b-card>
      </b-col>
    </b-row>
  </section>
</template>
<script>
import ButtonComponent from '../buttons/ButtonComponent.vue'
export default {
  name: 'PlansComponent',
  components: { ButtonComponent },
  props: {
    plans: Array,
    background: String,
    title: String
  }
}
</script>
<style lang="scss" scoped>
@import "../../../systemico.ui/basic.scss";
  .plans{
    background:#fefefe;
    font-size:1.5rem;
    padding:5rem;
    h2{
      font-size:2.5rem;
    }
    .card{
      border-radius:0.8rem;
      border:2px solid $color-blue;
      .card-text{
        font-size:1rem;
      }
      .card-body{
        font-size:1rem;
      }
      .list-group-item{
        padding:0.5rem;
      }
      h4{
        font-size:1.5rem;
      }
      .pricing{
        padding-bottom: 1rem;
      }
      .pricing_value{
        font-size:2rem;
        color:$color-celeste;
        font-weight:bold;
      }
      .pricing_frecuency{
        font-sie: 0.8rem;
      }
      .pricing_currency{
        border-radius:0.3rem;
        background-color: $color-orange;
        color:#fefefe;
        padding:0.2rem;
        font-size:0.6rem;
      }
    }
  }
</style>
