<template>
  <section class="popup" v-if="state">
    <div class="content">
      <div>
        <button class="btn-close"  v-on:click="cerrarModal()">
          <b-icon icon="x"></b-icon>
        </button>
        <h2 v-html="title" style="font-weight: bold;"></h2>
        <p><iframe width="560" height="315" :src="$props.urlvideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe></p>
        <div class="options">
          <buttonMain class="mr-md-4"  @funcionBtn="funcioRedirect(url_target)"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import buttonMain from "../ui/buttonMain";
import axios from "axios";

export default {
  name: "modalPopup",
  components: {
    buttonMain
  },
  props:{
    title: String,
    urlvideo: String,
    urlboton: String
  },

  data: function () {
    return {
      url_target: '/service_developing#Price'
    };
  },

  mounted() {
    if(this.$props.urlvideo==''){
      this.$props.urlvideo = 'https://www.youtube.com/embed/Kfx813mKW4U?si=lQdq1iS3whc5EN_r'
    }
    var currentUrl = document.URL
    var urlParts   = currentUrl.split('#')
    var anchor = (urlParts.length > 1) ? urlParts[1] : null
    console.log("Anchor "+anchor)
    if(anchor!=null && anchor=='video'){
      this.showPopup()
    }
  },

  computed: {
    state(){
      let data = this.$store.getters.getPopup;
      return data.state;
    }
  },

  methods: {
    cerrarModal: function () {
      localStorage.setItem('cerradoPopupVideo', true)
      this.$store.commit("setPopup", { state: false });
    },
    cargarmsg: function () {

    },

    showPopup: function () {
      this.$store.commit("setPopup", { state: true });
    },

    funcioRedirect: function () {
      window.location=this.$props.urlboton
    },
    //comentario final
  }
};
</script>
