<template>
    <section class="software" id="software">
        <b-container fluid='xl'>
            <b-row>
                <b-col cols="12" md="4" class="p-0">
                    <section class="software__section">
                        <div>
                            <h2 class="software__title">
                                {{ title[$props.lang] }}
                                <div class="line"></div>
                            </h2>
                            <p class="software__description">
                                {{ description[$props.lang] }}
                            </p>
                        </div>
                    </section>
                </b-col>
                <b-col cols="12" md="4" class="p-0">
                    <section class="software__section software__section--orange">
                        <div>
                            <q class="software__quote">
                                <i>{{ quote[$props.lang] }}</i>
                            </q>
                        </div>
                    </section>
                </b-col>
                <b-col cols="12" md="4" class="p-0">
                    <section class="software__section">
                        <div>
                            <template v-for="(item, index) in items">
                                <span class="software__item" :key="index">
                                    {{ item[$props.lang] }}
                                </span>
                            </template>
                        </div>
                    </section>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'banner',
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      title: {es: 'Pymes', en: 'Small Business'},
      description: {
        es: 'Apoyamos la transformación de pymes de diferentes sectores para mejorar sus operaciones.',
        en: 'We provide digital and smart transformation for small business in different sectors and industries to improve processes and profit. '
      },
      quote: {
        es: 'Participamos activamente, escuchamos, aprendemos, y proponemos acciones que impacten positivamente a nuestros clientes.',
        en: 'Actively Listening, learning and implementing to generate actions to improve business model to the next level.  '
      },

      items: [
        { es: 'Software a medida', en: 'Custom development' },
        { es: 'Desarrollo de módulos', en: 'Module development' },
        { es: 'Metodologías ágiles', en:'Agile methodologies' },
        { es: 'Integraciones', en: 'Integrations' },
        { es: 'Calidad', en: 'Quality' },
        { es: 'Innovación', en: 'Innovation' },
        { es: 'Consultoría', en: 'Consulting' },
        { es: 'Experiencia', en: 'Know-how' },
        { es: 'Equipo experto', en: 'Exper team' },
        { es: 'Tecnología', en: 'Technologies' },
        { es: 'Estándares', en: 'Standards' },
        { es: 'Pasión', en: 'Passion' },
        { es: 'UI/UX', en: 'UI/UX' }
      ]
    }
  }
}
</script>
