<template>
  <section class="article">
    <b-container fluid="lg">
      <b-row>
        <b-col cols="12" md="8">
          <div class="article__main">
            <div class="article__title">
              <div class="image">
                <img class="image-default" :src="$cdn + 'icons/icon-image-default.svg'" alt="icon">
              </div>
              <h2>La importancia de tener un Tooling Team para los proyectos propios de la compañía.</h2>
            </div>
            <div class="article__content">
              <div class="author">Autor: Edwin Ariza</div>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</p>
            </div>
          </div>
        </b-col>
        <b-col cols="12" md="4" class="p-0">
          <div class="article__more">
            <h3>MÁS ARTÍCULOS</h3>
            <div class="article__card">
                <div class="img">
                  <img class="icon-default" :src="$cdn + 'icons/icon-person-dafault.svg'" alt="icon">
                </div>
                <div class="content">
                  <h3>Programa: ELEVATE / ELEVAR Accelerator. Llevando a Systemico al siguiente nivel.</h3>
                  <a href="javascript:void(0);" v-on:click="funcioRedirect('/article')" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>
              <div class="article__card">
                <div class="img">
                  <img class="icon-default" :src="$cdn + 'icons/icon-person-dafault.svg'" alt="icon">
                </div>
                <div class="content">
                  <h3>La importancia de tener un Tooling Team para los proyectos propios de la compañía.</h3>
                  <a href="javascript:void(0);" v-on:click="funcioRedirect('/article')" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>
              <div class="article__card">
                <div class="img">
                  <img class="icon-default" :src="$cdn + 'icons/icon-person-dafault.svg'" alt="icon">
                </div>
                <div class="content">
                  <h3>La importancia de tener un Tooling Team para los proyectos propios de la compañía.</h3>
                  <a href="javascript:void(0);" v-on:click="funcioRedirect('/article')" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>
              <div class="article__card">
                <div class="img">
                  <img class="icon-default" :src="$cdn + 'icons/icon-person-dafault.svg'" alt="icon">
                </div>
                <div class="content">
                  <h3>La importancia de tener un Tooling Team para los proyectos propios de la compañía.</h3>
                  <a href="javascript:void(0);" v-on:click="funcioRedirect('/article')" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>
              <div class="article__card">
                <div class="img">
                  <img class="icon-default" :src="$cdn + 'icons/icon-person-dafault.svg'" alt="icon">
                </div>
                <div class="content">
                  <h3>La importancia de tener un Tooling Team para los proyectos propios de la compañía.</h3>
                  <a href="javascript:void(0);" v-on:click="funcioRedirect('/article')" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>
              <div class="article__card">
                <div class="img">
                  <img class="icon-default" :src="$cdn + 'icons/icon-person-dafault.svg'" alt="icon">
                </div>
                <div class="content">
                  <h3>La importancia de tener un Tooling Team para los proyectos propios de la compañía.</h3>
                  <a href="javascript:void(0);" v-on:click="funcioRedirect('/article')" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "article",
};
</script>