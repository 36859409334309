<template>
    <section class="testimonials">
        <b-container fluid='xl'>
            <b-row>
                <b-col>
                    <h2 class="testimonials__title">
                        {{ title[$props.lang] }}
                        <div class="line"></div>
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols='12' md=''></b-col>
                <template v-for='(item, index) in items'>
                    <b-col  cols='12' md='3' :key="index">
                        <div class="testimonials__card">
                            <img class="testimonials__icon" :src="$cdn + 'icons/'+ item.icon" alt="icon card">
                            <p class="testimonials__description">
                                <i v-html="item.description[$props.lang]"></i>
                            </p>
                            <h3 class="testimonials__user">
                                {{ item.user }}
                            </h3>
                        </div>
                    </b-col>
                </template>
                <b-col cols='12' md=''></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'testimonials',
  props: {
    lang: String
  },
  data: function () {
    return {
      title: { es: 'Testimonios', en: 'Testimonials'},
      items: [
        {
          icon: 'icon-men--white.svg',
          description: {
            en: 'Systemico delivers high-quality code. They always use a good selection of tools and create products with great UX.',
            es: 'Systemico entrega un c&oacute;digo de alta calidad. Siempre utilizan diferentes herramientas que les permite crear soluciones con una experiencia de usuario genial.'
          },
          user: 'Juan Barajas  - CDA ITG'
        },
        {
          icon: 'icon-woman--white.svg',
          description: {
            es: 'Son innovadores, r&aacute;pidos al dar soporte y proactivos. Siempre nos estamos aprendiendo cosas nuevas con ellos.',
            en: 'They are innovative, supportive, and proactive. We always learn new things with them.'
          },
          user: 'Rebeca Mejía - Cajasan'
        }
      ]
    }
  }
}
</script>
