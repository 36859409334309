<template>
    <section class="methodology" id="methodology">
        <b-container fluid='xl'>
            <b-row>
                <b-col>
                    <h2 class="methodology__title">
                        {{ title[$props.lang] }}
                        <div class="line"></div>
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <template v-for='(item, index) in items'>
                    <b-col cols="12" :key='index'>
                        <div class="methodology__card">
                            <header class="methodology__card__header">
                                <h3>
                                    {{ item.title[$props.lang] }}
                                    <img :src="$cdn + 'icons/' + item.icon" alt="icon card">
                                </h3>
                            </header>
                            <div class="methodology__card__line">
                                <img :src="$cdn + 'icons/icon-check.svg'" alt="icon card">
                                <div class="line" v-if="item.line"></div>
                            </div>
                            <main class="methodology__card__content">
                                <p>
                                    {{ item.description[$props.lang] }}
                                    <strong v-if="item.strong !== undefined">{{ item.strong[$props.lang] }}</strong>
                                </p>
                                <a v-if="item.strong !== undefined" :href="item.url">
                                    {{ item.link[$props.lang] }}
                                </a>
                            </main>
                        </div>
                    </b-col>
                </template>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'methodology',
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      title: {es: 'Metodología de Desarrollo', en: 'Development Methodology'},

      items: [
        {
          title: {es: 'Maduración de idea', en: 'Idea Maturation'}, icon: 'icon-idea.svg', line: true,
          description: {
            es: 'Escuchamos tus necesidades, investigamos, analizamos y planificamos tu proyecto para desarrollar un software efectivo e innovador que impulse tu negocio. Esto lo hacemos posible a través de nuestro aliado',
            en: 'We listen to your needs, research, analyze and plan your project to develop effective and innovative software that boosts your business. We make this possible through our ally WydeaLab.'
          },
          strong: 'WydeaLab.', link: {es: 'Iniciar con WydeaLab >',en:'Start with WydeaLab'}, url: 'https://wydealab.com/'
        },
        {
          title: {es: 'Diseño', en: 'Design'}, icon: 'icon-design.svg', line: true,
          description: {
            es: 'Nuestra experiencia en desarrollo nos permite diseñar ideas innovadoras y efectivas, con procesos confiables y ágiles, guiándonos siempre por los más altos estándares internacionales.',
            en: 'Our development experience allows us to design innovative and effective ideas, with reliable and agile processes, always guiding us by the highest international standards.'
          }
        },
        {
          title: {es: 'Implementación', en: 'Implementation'}, icon: 'icon-developed.svg', line: true,
          description: {
            es: 'Utilizamos metodologías que nos permiten alinear desarrollos de software con los objetivos de tu empresa generando productos de innovación.',
            en: 'We use methodologies that allow us to align software developments with your company\'s objectives, generating innovative products.'
          }
        },
        {
          title: {es: 'Pruebas', en:'Testing'}, icon: 'icon-tests.svg', line: true,
          description: {
            es: 'Nuestros desarrollos pasan por un riguroso proceso de pruebas, ofreciendo software de calidad que cumpla con los estándares internacionales de seguridad.',
            en: 'Our developments go through a rigorous testing process, offering quality software that meets international security standards.'
          }
        },
        { title: {es: 'Soporte', en: 'Support'}, icon: 'icon-support.svg', line: true,
          description: {
            es: 'Estaremos a tu lado siempre, incluso después de impulsar tu negocio ofreciendo ayuda, soporte técnico y actualizaciones que aseguren la continuidad de tu negocio.',
            en: 'We will always be by your side, even after boosting your business, offering help, technical support and updates that ensure the continuity of your business.'
          }
        },
        {
          title: {es: 'Infraestructura', en:'Architecture'}, icon: 'icon-infrastructure.svg', line: false,
          description: {
            es: 'Asegura tu solución en la nube, con servidores, balanceadores de carga y todo lo que requieras para que tu aplicación funcione de la mejor manera.',
            en: 'Secure your solution in the cloud, with servers, load balancers and everything you need to make your application work at its best.'
          }
        }
      ]
    }
  },
  methods: {
    funcioRedirect: function (link) {
      window.location=link
    }
  }
}
</script>
