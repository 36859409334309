<template>
  <section id="benefits" class="benefits border-top pt-2 container-fluid" :style="'background:'+$props.background+';color:'+$props.textColor">
    <b-container class="content">
      <h3 class="title" v-html="$props.title"></h3>
      <b-row>
        <b-col md="3" sm="12" v-for="item in $props.items" :key="item.id" class="p-1">
          <b-card
            style="max-width: 30rem;border-radius: 1.0em;"
            class="m-2 border-0 bg-transparent"
          >
            <b-card-text >
              <div class="text-left">
                <b-img :src="item.icon" height="35rem" alt="" class="mb-3" ></b-img>
              </div>
              <div class="title-item" v-html="item.title"></div>
              <div class="subtitle-item" v-html="item.description"></div>
            </b-card-text>
        </b-card>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: 'BannerBenefitsComponent',
  props: {
    title: String,
    items: Array,
    background: String,
    textColor: String,
    titleColor: {
      type: String,
      default: '#0b1c98'
    },
    titleItemColor:{
      type: String,
      default: '#009dff'
    }
  }
}
</script>
<style lang="scss" scoped>
  .benefits{
    height:auto;
    min-height: auto;
    border-bottom: 2px solid #f0f0f0;
    .content{
      max-width:1280px;
      padding: 1rem;
      .title{
        font-size: 2.5rem;
        height: 2.7rem;
        text-align: center;
        font-weight:bolder;
        margin-bottom: 1rem;
        color:v-bind(titleColor)
      }
      .title-item{
        font-size: 1.4rem;
        line-height: 1.8rem;
        text-align: left;
        color:v-bind(titleItemColor);
        font-weight:bolder;
      }
      .subtitle-item{
        font-size: 1.3rem;
        text-align: left;
        color:#777777;
        font-weight: 400;

      }
    }
  }
  @media (max-width: 768px) {
    .benefits {
      .content{
        padding: 1rem;
        .title{
          font-size: 2rem;
          line-height: 2.2rem;
        }
        .title-item{
          font-size: 1.2rem;
          line-height: 1.6rem;
        }
        .subtitle-item{
          font-size: 1.1rem;
        }
      }
    }
  }
</style>
