<template>
    <main class="section">
    	<headerC :menuActive="true" class="headerUi--active p-0"/>
        <!-- seccion 1 -->
		<section class="support support--blue mt-5 pt-5">
			<b-container fluid=lg>
				<b-row>
					<b-col>
						<titleSection :content='content'/>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='5'>
						<div class="support__title support__title--white">
							<img :src="$cdn + 'icons/icon-person--systemico.svg'" alt="icon card">
							<h2>Soporte por Demanda</h2>
						</div>
						<p class="support__subtitle">Planes integrales para tu empresa con personal experto</p>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>

				<b-row>
					<b-col>
						<div class="support__description">
							Simplifica y asegura el soporte a tus productos y servicios de tecnología.
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- index -->
		<div class="content__index">
			<a href="#Description">Descripción</a>
			<a href="#Prices">Precios</a>
			<a href="#Characteristic">Características</a>
			<a href="#Methodology">Metodología</a>
			<a href="#Service">Nivel de Servicio</a>
		</div>
        <!-- seccion 2 -->
		<section class="support support--gray" id="Description">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
						<div class="support__title w-100 justify-content-center">
							<h2>Planes de Servicio para Continuidad de Negocios</h2>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md=''>
						<p class="support__subtitle support__subtitle--black mb-5">Nuestros Planes de Servicios para Continuidad de negocio incluyen: soporte reactivo, soporte preventivo y mantenimiento, respaldo preventivo y contingencias explicados anteriormente.</p>
					</b-col>
					<b-col cols='12' md=''>
						<p class="support__subtitle support__subtitle--black mb-5">Estos planes se ejecutan en modalidad de bolsa de horas para consumo mensual, no acumulables, renovables anualmente aparte de la etapa inicial denominada puesta en marcha.</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md='4'>
						<div class="support__card mt-5">
							<h3>
								Puesta en Marcha
								<span class="support__tooltip  ml-3" id="tooltip-1">
									<b-icon icon="info"></b-icon>
								</span>
								<b-tooltip target="tooltip-1" triggers="hover" placement="topright">
									<b>Nota:</b> El costo de este plan es único y va incluido al <br> momento de la instalación.
								</b-tooltip>
							</h3>
							<p>Esta etapa aplica para empresas o clientes cuyos aplicativos no hayan sidos desarrollados por Systemico, aquí se realizan las siguientes actividades:</p>
							<ul>
								<li>Transferencia: arquitectura, documentación de uso, documentación técnica, listado de requerimientos y funcionalidades, mockups y capacitaciones al personal de Systemico sobre la herramienta.</li>
								<li>Montaje de Infraestructura</li>
								<li>Identificar la arquitectura de la aplicación</li>
								<li>Establecer los procesos de integración contínua</li>
								<li>Habilitar los entornos: alpha, beta y stable</li>
							</ul>
						</div>
					</b-col>
					<b-col cols='12' md='4'>
						<div class="support__card mt-5">
							<h3>
								Plan Small Business
								<div class="recommended ml-3">Recomendado<b-icon icon="check"></b-icon></div>
							</h3>
							<p>Ideal para pequeñas empresas que están probando modelos de negocio que ya tienen clientes y desean asegurar el nivel de servicio al cliente, cubre una frecuencia de respaldo de quincenal (dos veces al mes) con retención de un mes.</p>
						</div>
						<div class="support__slogan">
							<p>Nuestros Servicios son soluciones innovadoras para potenciar tu negocio.</p>
						</div>
					</b-col>
					<b-col cols='12' md='4'>
						<div class="support__card mt-5">
							<h3>Plan Startups</h3>
							<p>Si la empresa está iniciando y quiere hacer una validación de mercado, este es el recomendable, para asegurar de manera apropiada el servicio sin tener un costo alto, cubre una frecuencia de respaldo de una vez al mes con retención de un mes.</p>
						</div>
						<div class="support__card mt-5">
							<h3>Plan Impact</h3>
							<p>Ideal para empresas que están empezando a escalar la demanda del servicio, y que quieren crecer de manera rápida, cubre una frecuencia de respaldo semanal (cuatro veces al mes) con retención de un mes.</p>
						</div>
						<div class="support__card mt-5">
							<h3>Plan Corporativo</h3>
							<p>Ideal para empresas que desean tener personal dedicado siempre monitoreando la plataforma base del negocio, cubre una frecuencia de respaldo diaria (treinta veces al mes) con retención de un mes.</p>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 3 -->
		<section class="support support--gray" id="Prices">
			<b-container>
				<b-row>
					<b-col>
						<div class="support__content">
							<b-table class="support__table" :items="planes" :fields="titulos">
								<template #cell(plan)="data">
									<div class="table__plan">
										{{data.value.text}}
										<span v-if="data.value.tooltip" class="support__tooltip  ml-3" :id="'tooltip' + data.index">
											<b-icon icon="info"></b-icon>
										</span>
										<b-tooltip :target="'tooltip' + data.index" triggers="hover" placement="topright">
											<b>Nota:</b> El costo de este plan es único y va incluido al <br> momento de la instalación.
										</b-tooltip>
										<div v-if="data.value.recommended" class="recommended ml-3">Recomendado<b-icon icon="check"></b-icon></div>
									</div>
								</template>
							</b-table>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 4 -->
		<section class="support" id="Characteristic">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
						<div class="support__title w-100 justify-content-center">
							<h2>Servicios para Continuidad de Negocios</h2>
						</div>
						<p class="support__subtitle support__subtitle--black mb-5">Nuestros Servicios de Continuidad de negocio incluyen: soporte reactivo, soporte preventivo y mantenimiento, respaldo y contingencias aparte de la etapa inicial denominada puesta en marcha.</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md='4'>
						<div class="support__card mt-5">
							<div class="icon">
								<img :src="$cdn + 'icons/icon__service-1.svg'" alt="icon card">
							</div>
							<h3 class="text-center">Soporte Reactivo</h3>
							<p>Es aquel que está orientado a la solución de incidencias que surgen de maneraservicesperada en el día a día.</p>
						</div>
					</b-col>
					<b-col cols='12' md='4'>
						<div class="support__card mt-5">
							<div class="icon">
								<img :src="$cdn + 'icons/icon__service-2.svg'" alt="icon card">
							</div>
							<h3 class="text-center">Soporte Preventivo y Mantenimiento</h3>
							<p>Es el proceso por el cual se mejora y optimiza el software que se a instalado como su nombre lo indica previniendo posibles incidentes que se puedan presentar a medida que se usa el aplicativo.</p>
						</div>
					</b-col>
					<b-col cols='12' md='4'>
						<div class="support__card mt-5">
							<div class="icon">
								<img :src="$cdn + 'icons/icon__service-3.svg'" alt="icon card">
							</div>
							<h3 class="text-center">Respaldo y Contingencias</h3>
							<p>Se refiere a los backups de la información y en cada plan de continuidad de negocio se maneja de forma particular.</p>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<div class="support__img">
							<img :src="$cdn + 'images/Img-continuidad-negocio.png'" alt="image">
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 5  -->
		<section class="case" id="Methodology">
			<b-container fluid='xl'>
				<b-row>
					<b-col>
						<h2 class="case__title">Metodología</h2>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols="12">
						<div class="case__card">
							<header class="case__card__header">
								<h3>
									1
								</h3>
							</header>
							<div class="case__card__line">
								<img :src="$cdn + 'icons/icon-check.svg'" alt="icon card">
								<div class="line"></div>
							</div>
							<main class="case__card__content">
								<p>Al inicio de la ejecución se solicita al cliente compartir con Systemico el listado de personas autorizadas para realizar solicitudes junto con el líder asignado a quién se tendrá constantemente informado de los servicios que se presten.</p>
							</main>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="case__card">
							<header class="case__card__header">
								<h3>2</h3>
							</header>
							<div class="case__card__line">
								<img :src="$cdn + 'icons/icon-check.svg'" alt="icon card">
								<div class="line"></div>
							</div>
							<main class="case__card__content">
								<p>Las solicitudes de soporte se recibirán a través de: https://systemico.net/support/ con el asunto [Empresa]- [Solicitud de Soporte] o del whatsapp 3104853661 y se dará respuesta de acuerdo a los ans descritos a continuación y a través del correo para el registro formal de la atención.</p>
							</main>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="case__card">
							<header class="case__card__header">
								<h3>3</h3>
							</header>
							<div class="case__card__line">
								<img :src="$cdn + 'icons/icon-check.svg'" alt="icon card">
								<div class="line"></div>
							</div>
							<main class="case__card__content">
								<p>Una vez recibida la solicitud se procede a:</p>
								<ul>
									<li>Realizar la debida clasificación en alguno de los tres tipos de soporte descritos anteriormente, así como el nivel de criticidad en alto, medio o bajo.</li>
									<li>Planeación de la solución</li>
									<li>Asignación de los recursos</li>
									<li>Elaboración del cronograma de ejecución correspondiente si es requerido, si no lo es, se informará puntualmente el tiempo estimado para la ejecución de las acciones establecidas.</li>
								</ul>
							</main>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="case__card">
							<header class="case__card__header">
								<h3>4</h3>
							</header>
							<div class="case__card__line">
								<img :src="$cdn + 'icons/icon-check.svg'" alt="icon card">
								<div class="line"></div>
							</div>
							<main class="case__card__content">
								<p>Con el fin de llevar el control del número de horas de soporte realizadas por mes, se compartirá con el líder encargado autorizado por Cliente Express el acceso al formato correspondiente (ver anexo: FormatoControlHorasSoporte - (Nombre del Cliente.pdf), donde se detallara lo siguiente:</p>
								<ul>
									<li>a. Fecha de recepción de la solicitud.</li>
									<li>b. Tipo de solicitud.</li>
									<li>c. Descripción detallada de la solicitud.</li>
									<li>d. Tiempo de atención: el tiempo que se estima para atender la solicitud.</li>
									<li>e. Área responsable: para este caso siempre será el área de Soporte.</li>
									<li>f. Responsable de atención: persona asignada para atender la solicitud.</li>
								</ul>
								<p>También cuenta con un semáforo para dar alerta y controlar el consumo mensual de las horas de soporte contratadas.</p>
							</main>
						</div>
					</b-col>
					<b-col cols="12">
						<div class="case__card">
							<header class="case__card__header">
								<h3>5</h3>
							</header>
							<div class="case__card__line">
								<img :src="$cdn + 'icons/icon-check.svg'" alt="icon card">
								<div class="line"></div>
							</div>
							<main class="case__card__content">
								<p>El servicio normal se presta en el siguiente Horario de atención Equipo de Soporte:</p>
								<ul>
									<li>Lunes a Viernes de 8:00 a 12:00 y 14:00 a 18:00</li>
									<li>Horas Sábados de 8:00 a 12:00 Horas</li>
								</ul>
							</main>
						</div>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 6 -->
		<section class="support support--blue" id="Service">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
						<div class="support__title support__title--white w-100 justify-content-center">
							<h2 class="text-center">Acuerdo de Niveles de Servicio para Soporte Reactivo en cualquiera de los planes (ANS)</h2>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='12' md=''>
						<p class="support__subtitle support__subtitle--white my-4">Un acuerdo de nivel de servicio (ANS), es un documento entre Systemico y el Cliente con objeto de fijar el tiempo de atención para garantizar la calidad, ambas partes definirán los términos del nivel de calidad del servicio en el contrato según el proyecto , en aspectos tales como el tiempo de respuesta, la disponibilidad horaria, la documentación disponible, el personal asignado al servicio, etc.</p>
					</b-col>
					<b-col cols='12' md=''>
						<p class="support__subtitle support__subtitle--white my-4">El soporte reactivo que se maneja en cada uno de los planes de servicios para continuidad de negocio incluye generalmente soporte nivel 2, 3 y 4 por el contrario el nivel 1 que se refiere a las incidencias de uso del aplicativo por parte del cliente final se recomienda que esté a cargo directamente de personal propio del Cliente propietario del aplicativo.</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<div class="support__content">
							<b-table class="support__table" :items="categorias" :fields="titulos2"></b-table>
						</div>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<buttonPrimry class="mx-auto" :configButton='buttonSection' @funcionBtn="funcioRedirect(buttonSection.click)"/>
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 7 -->
		<section class="support support--blue">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10' lg='8'>
						<div class="support__develop">
							<h2>Desarrollamos el crecimiento de tu empresa!</h2>
							<p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
							<div class="opcions">
								<template v-for='(item, index) in buttons'>
									<buttonPrimry :configButton='item' :key='index' @funcionBtn="funcioRedirect(item.link)"/>
								</template>
							</div>
						</div>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
		<footerC />
	</main>
</template>

<script>
import headerC from '../../../../../../systemico.ui/components/header.vue'
import titleSection from '@/components/ui/titleSection'
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import footerC from '../../../../../../systemico.ui/components/footer.vue'

export default {
    name: "support",
    components: {
		headerC,
		titleSection,
		buttonPrimry,
		footerC
	},
	metaInfo: {
		title: 'Systemico: Súper soporte de software por demanda.'
	},
	data: function () {
		return {
			content: {
				title: 'Servicios',
				subtitle: '/ Súper Soporte'
			},
			titulos: [
				{
					key: 'plan',
					label: 'Plan'
				},
				{
					key: 'tiempo',
					label: 'Hora / Mes'
				},
				{
					key: 'valor',
					label: 'Valor Base'
				},
				{
					key: 'valorAdicional',
					label: 'Valor Hora Adicional'
				}
			],
			planes: [
				{ plan: { text: 'Puesta en Marcha*', tooltip: true }, tiempo: '10', valor: '400 USD', valorAdicional: '40 USD' },
				{ plan: { text: 'Plan Entrepreneur'}, tiempo: '10', valor: '250 USD', valorAdicional: '40 USD' },
				{ plan: { text: 'Plan Startups'}, tiempo: '20', valor: '500 USD', valorAdicional: '35 USD' },
				{ plan: { text: 'Plan Small Business', recommended: true}, tiempo: '30', valor: '750 USD', valorAdicional: '33 USD' },
				{ plan: { text: 'Plan Impact'}, tiempo: '50', valor: '1500 USD', valorAdicional: '30 USD' },
				{ plan: { text: 'Plan Corporativo'}, tiempo: '80', valor: '2000 USD', valorAdicional: '28 USD' },
				{ plan: { text: 'Plan Dedicado'}, tiempo: '100', valor: '2500 USD', valorAdicional: '25 USD' },
				// { plan: '', tiempo: '', valor: '', valorAdicional: '' }
			],
			titulos2: [
				{
					key: 'categoria',
					label: 'Categoría'
				},
				{
					key: 'descripcion',
					label: 'Descripción'
				},
				{
					key: 'tiempo',
					label: 'Tiempo de Recepción de la Incidencia'
				},
				{
					key: 'tiempo_accion',
					label: 'Tiempo para establecer o ejecutar acciones'
				},
				{
					key: 'ejemplo',
					label: 'Ejemplos'
				},
				{
					key: 'responsable',
					label: 'Responsable atención'
				},
			],
			categorias: [
				{ categoria: 'Soporte de cuarto nivel', descripcion: 'Incidencias de arquitectura con el proveedor de la misma', tiempo: '2 horas hábiles - en horario de oficina', tiempo_accion: 'Se envía un correo con el tiempo y/o cronograma para ejecutar acciones y el responsable de requerimiento Los tiempos cíclicos establecidos según la clasificación de criticidad son: Alta: 8 horas hábiles Media: 24 horas hábiles Baja: 40 horas hábiles', ejemplo: 'Servidores en amazon caídos y el cliente no puede acceder a la aplicación', responsable: 'Systemico junto con el Tercero identificado' },
				{ categoria: 'Soporte de tercer nivel', descripcion: 'Falla grande crítica que interrumpe completamente la aplicación o servicio', tiempo: '', tiempo_accion: '', ejemplo: 'La plataforma no carga o no permite el ingreso de los usuarios. Algo que no está funcionando y requiere modificar el código para solucionarlo', responsable: 'Systemico' },
				{ categoria: 'Soporte de segundo nivel', descripcion: 'Falla considerable que afecta parcialmente la aplicación o servicio', tiempo: '', tiempo_accion: '', ejemplo: 'Botones que no ejecutan ninguna acción, errores 404 en algunas páginas.', responsable: 'Systemico' },
				{ categoria: 'Soporte de segundo nivel', descripcion: 'Solicitudes de cambio o actualizaciones menores de la aplicación o servicio', tiempo: '', tiempo_accion: '', ejemplo: 'Actualizaciones de vistas, cambio de imágenes como logos o banners. Registro de información incorrecta y se requiere un ajuste en la información', responsable: 'Systemico' },
				{ categoria: '*Soporte de primer nivel', descripcion: 'Solicitudes de información y administración de la aplicación o servicio', tiempo: 'No aplica', tiempo_accion: 'No aplica', ejemplo: 'Manuales de usuario, fichas técnicas de la aplicación, alta, baja o edición de usuarios.', responsable: 'Cliente' },
				{ categoria: '*Soporte de primer nivel', descripcion: 'No aplicaSoporte en cuanto al uso de la aplicación', tiempo: 'No aplica', tiempo_accion: 'No aplica', ejemplo: 'N/A Dudas en cuanto al uso normal de la aplicación', responsable: 'Cliente' }
				// { categoria: '', descripcion: '', tiempo: '', tiempo_accion: '', ejemplo: '', responsable: '' }
			],
			buttonSection: { text: 'Descargar documento', class: 'button--secundary', click: 'sinContenido' },
			buttons: [
				{ text: 'Cotizar proyecto', class: 'button--secundary', link: '/support' },
				{ text: 'Solicitar servicio', class: 'button--secundary', link: '/support/registro' },
				{ text: 'Contáctanos', class: 'button--secundary', link: '/support' }
			]
		}
	},
	methods: {
		funcioRedirect: function (link) {
			window.location=link
		}
	}
};
</script>
