<template>
    <section class="team">
        <b-container fluid='xl'>
            <b-row>
                <b-col cols='12' md='6'>
                    <h2 class="team__title">
                        {{ title[$props.lang] }}
                        <div class="line"></div>
                    </h2>
                    <h3 class="team__subtitle">
                        {{ subtitle[$props.lang] }}
                    </h3>
                    <p class="team__description">
                        {{ description[$props.lang] }}
                    </p>
                    <a class="team__link" href="/company_team">
                        {{ link[$props.lang] }}
                    </a>
                    <!--<div class="clients__opcions">
                        <buttonPrimry :configButton="configButton" @funcionBtn="funcioRedirect('/login')"/>
                        <buttonPrimry :configButton="configButton2" @funcionBtn="funcioRedirect('/login')"/>
                    </div>-->
                </b-col>
            </b-row>
        </b-container>
       <modalWePower />
    </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import modalWePower from '@/components/modals/modalWePower.vue'

export default {
  name: 'team',
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  components: {
    buttonPrimry,
    modalWePower
  },

  data: function () {
    return {
      title: {es: 'Equipo de trabajo', en: 'Teamwork'},

      subtitle: {es: 'Juntos lo hacemos posible.', en:'Together we make it possible.'},

      description: {
        es: 'Somos profesionales trabajando en completa sinergia desde diferentes disciplinas tales como: Gerentes de proyecto, desarrolladores, diseñadores UI/UX, testers, comerciales, entre otras.',
        en: 'We are professionals working in complete synergy from different disciplines such as: project managers, developers, UI/UX designers, testers, salespeople, among others.'
      },

      link: {es: 'Conócenos >', en: 'Know us >'},

      configButton: { text: 'Crear cuenta gratis', class: 'button--primary' },

      configButton2: { text: 'Ingresar', class: 'button--secundary' }
    }
  },
  methods: {
    funcioRedirect: function (link) {
      window.location=link
    }
  }
}
</script>
