<template>
    <section class="technologies">
        <b-container fluid='xl'>
            <b-row>
                <b-col>
                    <h2 class="technologies__title">
                        {{ title }}
                        <div class="line"></div>
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols='12' md=''></b-col>
                <b-col cols='12' md='8'>
                    <div class="text-center">
                        <template v-for='(item, index) in items'>
                            <span class='technologies__item' :key='index'>
                                {{ item.name }}
                            </span>
                        </template>
                    </div>
                </b-col>
                <b-col cols='12' md=''></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'technologies',

  data: function () {
    return {
      title: 'Tecnologías',

      items: [
        { name: 'Python' },
        { name: 'Linux' },
        { name: 'MariaDB' },
        { name: 'Amazon AWS' },
        { name: 'SQLite' },
        { name: 'Javascript' },
        { name: 'Vue' },
        { name: 'Saas' },
        { name: 'Frameworks' },
        { name: 'Devops' },
        { name: 'React Native' },
        { name: 'CLI' },
        { name: 'UI/UX' },
        { name: 'AI Bots' },
        { name: 'API' },
        { name: 'HOOKS' }
      ]
    }
  }
}
</script>
