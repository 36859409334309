<template>
  <section class="products" id="products">
    <b-container fluid class="content">
      <b-row>
        <b-col>
          <h2 class="products__title">
            {{ title[$props.lang] }}
            <span class="line"></span>
          </h2>
          <p class="products__description">
            {{ description[$props.lang] }}
          </p>
          <div v-if="$props.alone === '' || $props.alone === undefined " class="products__options" name="more_products" id="more_products"  >
            <button
              :class="state == 1 ? 'active' : ''"
              v-on:click="changeOption(1)"
            >
              {{subtitle_1[$props.lang]}}
            </button>
            <button
              :class="state == 2 ? 'active' : ''"
              v-on:click="changeOption(2)"
              id="quick_services"
            >
              {{subtitle_2[$props.lang]}}
            </button>
            <button
              :class="state == 3 ? 'active' : ''"
              v-on:click="changeOption(3)"
            >
              {{subtitle_3[$props.lang]}}
            </button>
          </div>
        </b-col>
      </b-row>
      <b-row v-show="state === 1 && ($props.alone=='products' || $props.alone===undefined)">
        <template v-for="(item, index) in products">
          <b-col cols="12" md="6" lg="4" :key="index" v-show="item[$props.lang].show">
            <div class="products__card">
              <header class="m-3">
                <div class="type">{{subtitle_1[$props.lang]}}</div>
              </header>
              <h3 class="align-items-center">
                <img
                  :src="$cdn + 'icons/products/' + item[$props.lang].icon"
                  alt="icon card"
                />
                {{ item[$props.lang].title }}
              </h3>
              <p>{{ item[$props.lang].description }}</p>
              <div class="price">
                <p>
                  {{from[$props.lang]}} <span>{{ item[$props.lang].price[$props.currency] }}
                  </span><span class="currency">{{$props.currency}}</span>
                  / {{ item[$props.lang].period }}
                </p>
                <ButtonComponent  variant="secondary" :text="more[$props.lang]" :href="item[$props.lang].link" />
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row v-show="state == 2 && ($props.alone=='quick' || $props.alone===undefined)">
        <template v-for="(item, index) in services">
          <b-col cols="12" md="6" lg="4" :key="index" v-show="item[$props.lang].show && (item[$props.lang].location == $props.country || item[$props.lang].location === 'global'  )" >
            <div class="products__card">
              <header>
                <div class="location text-capitalize">
                  {{ item[$props.lang].location }} <b-icon icon="globe"></b-icon>
                </div>
                <div class="type">{{subtitle_2[$props.lang]}}</div>
              </header>
              <h3 class="align-items-start">
                <img
                  :src="$cdn + 'icons/services/' + item[$props.lang].icon"
                  alt="icon card"
                />
                {{ item[$props.lang].title }}
              </h3>
              <p>{{ item[$props.lang].description }}</p>
              <div class="price">
                <p>
                  <span>{{ item[$props.lang].price[$props.currency] }}</span> / pago único
                </p>
                <buttonPrimry
                  v-if="item[$props.lang].link"
                  :configButton="configButton2"
                  @funcionBtn="funcioRedirect(item[$props.lang].link)"
                />
                <buttonPrimry v-else :configButton="configButton3" />
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
      <b-row v-show="state == 3 && ( $props.alone=='services' || $props.alone===undefined)">
        <template v-for="(item, index) in software">
          <b-col cols="12" md="6" lg="4" :key="index">
            <div class="products__card">
              <header class="m-3">
                <div class="type">{{ subtitle_3[$props.lang] }}</div>
              </header>
              <h3>
                <img :src="$cdn + 'icons/' + item[$props.lang].icon" alt="icon card" />
                {{ item[$props.lang].title }}
              </h3>
              <p>
                {{ item[$props.lang].description }}
                <span class="badge badge-secondary">{{ item[$props.lang].limit }}</span>
              </p>
              <div class="price">
                <p>
                  {{ from[$props.lang] }} <span>{{ item[$props.lang].price[$props.currency] }} {{currency}}</span> / {{ item[$props.lang].period }}
                </p>
                <buttonPrimry
                  v-if="$props.lang == 'es'"
                  :configButton="configButton3_es"
                  @funcionBtn="funcioRedirect(item[$props.lang].link)"
                />
                <buttonPrimry
                  v-if="$props.lang == 'en'"
                  :configButton="configButton3_en"
                  @funcionBtn="funcioRedirect(item[$props.lang].link)"
                />
              </div>
            </div>
          </b-col>
        </template>
      </b-row>
    </b-container>
  </section>
</template>

<script>
import buttonPrimry from '../basic/buttonBasic.vue'
import ButtonComponent from '../../../../systemico.ui/components/buttons/ButtonComponent.vue';
export default {
  name: 'listpProducts',

  components: {
    buttonPrimry,
    ButtonComponent
  },
  props: {
    lang: String,
    currency: String,
    country: String,
    alone: String
  },
  data: function () {
    return {
      state: 1,
      title: { es: 'Productos y Servicios', en: 'Products and Services' },
      description: {
        es: 'Soluciones reales que te ayudaran a iniciar o potenciar tu negocio.',
        en: 'Real software for you and your business.'
      },
      subtitle_1: { es: 'Productos', en: 'Products' },
      subtitle_2: { es: 'Servicios Rápidos', en: 'Quick Services' },
      subtitle_3: { es: 'Desarrollo de Software', en: 'Custom Development' },
      more: { es: 'Saber m&aacute;s', en: 'Learn more' },
      from: { es:'Desde', en: 'From'},
      products: [
        {
          es: {
            icon: 'icon-suppmail.svg',
            title: 'Suppmail',
            description: 'Herramienta más completa para automatizar, gestionar y centralizar solicitudes de clientes (Quejas, soprotes, reclamos, recomendaciones, etc). Atiende a tus usuarios de forma rápida y sencilla.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/products/suppmail/',
            period: 'año',
            show: true
          },
          en: {
            icon: 'icon-suppmail.svg',
            title: 'Suppmail',
            description: 'Smart feedback and complaints system for your small business. Organize, improve and accelerate your helpdesk and servicedesk.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/products/suppmail/',
            period: 'year',
            show: true
          }
        },
        {
          es: {
            icon: 'icon-wabot.svg',
            title: 'Wabot',
            description: 'Brinda atención oportuna, eficiente y óptima para clientes y usuarios. Automatizamos la atención al cliente generando la innovación que tu empresa necesita.',
            price: { cop: '$500.000', usd: '$100', cad: '$100' },
            link: '/products/wabot/',
            period: 'año',
            show: true
          },
          en: {
            icon: 'icon-wabot.svg',
            title: 'Wabot',
            description: 'Brinda atención oportuna, eficiente y óptima para clientes y usuarios. Automatizamos la atención al cliente generando la innovación que tu empresa necesita.',
            price: { cop: '$500.000', usd: '$100', cad: '$100' },
            link: '/products/wabot/',
            period: 'year',
            show: false
          }
        },
        {
          es: {
            icon: 'icon-grumpyturtle.svg',
            title: 'Grumpyturtle',
            description: 'Planea proyectos, organiza a tu equipo y cumple tus metas. La mejor opción para startups, utilizamos scrum para integrar equipos y aumentar su productividad.',
            price: { cop: '$500.000', usd: '$100', cad: '$100' },
            link: '/products/grumpyturtle/',
            period: 'año',
            show: true
          },
          en: {
            icon: 'icon-grumpyturtle.svg',
            title: 'Grumpyturtle',
            description: 'Planea proyectos, organiza a tu equipo y cumple tus metas. La mejor opción para startups, utilizamos scrum para integrar equipos y aumentar su productividad.',
            price: { cop: '$500.000', usd: '$100', cad: '$100' },
            link: '/products/grumpyturtle/',
            period: 'year',
            show: false
          }
        },
        {
          es: {
            icon: 'icon-proposals.svg',
            title: 'Proposals',
            description: 'Acelera la gestión de cotizaciones en tu empresa de servicios, ideal para consultores, asesores, diseñadores, abogados y cualquier otro contratista de servicios.',
            price: { cop: '$250.000', usd: '$50', cad: '$70' },
            link: '/products/proposals/',
            period: 'año',
            show: true
          },
          en: {
            icon: 'icon-proposals.svg',
            title: 'Proposals',
            description: ' Impulse your sales with a quotation system, plans, customers and opened alerts and more. Cool and clean quotations to sell your services.',
            price: { cop: '$250.000', usd: '$50', cad: '$70' },
            link: '/products/proposals/',
            period: 'year',
            show: true
          }
        },
        {
          es: {
            icon: 'icon-provedur.svg',
            title: 'Provedur',
            description:
              'Dinamiza tu gestión de compras y contratación de proveedores con una solución innovadora y totalmente en la nube.\n',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/products/provedur',
            period: 'año',
            show: true
          },
          en: {
            icon: 'icon-provedur.svg',
            title: 'Provedur',
            description:
              'Dinamiza tu gestión de compras y contratación de proveedores con una solución innovadora y totalmente en la nube.\n',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/products/provedur',
            period: 'year',
            show: false
          }
        },
        {
          es: {
            icon: 'icon-transpur.svg',
            title: 'Transpur',
            description: 'Simplificando la gestión en empresas de transporte. Sistema integral para la gestión en empresas de transporte de personas y carga.\n',
            price: { cop: '$3.000.000', usd: '$800', cad: '$900' },
            link: '/products/transpur',
            period: 'año',
            show: true
          },
          en: {
            icon: 'icon-transpur.svg',
            title: 'Transpur',
            description: 'Simplificando la gestión en empresas de transporte. Sistema integral para la gestión en empresas de transporte de personas y carga.\n',
            price: { cop: '$3.000.000', usd: '$800', cad: '$900' },
            link: '/products/transpur',
            period: 'year',
            show: false
          }
        },
        {
          es: {
            icon: "icon-gogoals.svg",
            title: "Gogoals",
            description: "Email marketing para crecer. Transforma tu negocio con email marketing automatizad para mostrar tus productos y servicios.",
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: "https://systemico.net/products/gogoals",
            period: 'año',
            show: true
          },
          en: {
            icon: "icon-gogoals.svg",
            title: "Gogoals",
            description: "Email marketing to growth. Transform your business with automate email marketing and show your products and services.",
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: "https://systemico.net/products/gogoals",
            period: 'year',
            show: true
          }

        }
        /** {
          icon: "icon-focusmeet.svg",
          title: "Focusmeet",
          description:
            "Te acompañamos en los procesos de planeación, desarrollo de la reunión y seguimiento de compromisos, buscando aumentar la productividad y el cumplimiento de tus metas.Te acompañamos en los procesos de planeación, desarrollo de la reunión y seguimiento de compromisos, buscando aumentar la productividad y el cumplimiento de tus metas.",
          price: "$10 USD",
          link: "/products/focusmeet/",
        },**/
        /** {
          icon: "icon-chatter.svg",
          title: "Chatter",
          description:
            "Asegura la comunicación de tu equipo en tiempo real e integra las atenciones directas a clientes que visitan tus canales digitales.",
          price: "$10 USD",
          link: "",
        },**/
        /** {
          icon: "icon-ducksite.svg",
          title: "Ducksites",
          description:
            "Genera sitios web de manera sencilla para promover el crecimiento de tu marca y producto.",
          price: "$10 USD",
          link: "",
        },**/

        /** {
          icon: "icon-penguin.svg",
          title: "Penguin Strategy",
          description:
            "Te ayudamos a gestionar todo el proceso comercial de las pymes o startups enfocándonos en el resultado para mejorar su impacto y rentabilidad.",
          price: "$10 USD",
          link: "",
        },
        {
          icon: "icon-pugobox.svg",
          title: "Pugobox",
          description:
            "Ayuda a las empresas a asegurar su información y a establecer buenas practicas. Gestionamos, clasificamos y respaldamos todos tus archivos.",
          price: "$10 USD",
          link: "",
        },**/
      ],
      services: [
        {
          es: {
            icon: 'icon-service-2.svg',
            title: 'Login con Google - Flask',
            description: 'Simplifica el proceso de autenticación de tus clientes con un botón de Google para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=7',
            location: 'global',
            show: true
          },
          en: {
            icon: 'icon-service-2.svg',
            title: 'Login con Google - Flask',
            description: 'Login authentication module to use on flask applications to simplify the login process for each customer.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=7',
            location: 'global',
            show: true
          }
        },
        {
          es: {
            location: 'global',
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Outlook',
            description:
              'Simplifica el proceso de autenticación de tus clientes con un botón de Outlook para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=8',
            show: true
          },
          en: {
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Outlook',
            description:
              'Simplifica el proceso de autenticación de tus clientes con un botón de Outlook para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=8',
            location: 'global',
            show: true
          }
        },
        {
          es: {
            location: 'global',
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Linkedin',
            description: 'Simplifica el proceso de autenticación de tus clientes con un botón de Linkedin para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=9',
            show: true
          },
          en: {
            location: 'global',
            icon: 'icon-service-2.svg',
            title: 'Habilitación del Login con Linkedin',
            description: 'Simplifica el proceso de autenticación de tus clientes con un botón de Linkedin para que los usuarios accedan con su cuenta de Gmail sin tener que memorizar una nueva clave.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=9',
            location: 'global',
            show: true
          }
        },
        {
          es: {
            location: 'global',
            icon: 'icon-service-3.svg',
            title: 'Habilitar un módulo de notificaciones (SMS, EMAIL y PUSH)',
            description: 'Gestión automatizada de notificaciones SMS, EMAIL y PUSH para su aplicación.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=10',
            show: true
          },
          en: {
            location: 'global',
            icon: 'icon-service-3.svg',
            title: 'Habilitar un módulo de notificaciones (SMS, EMAIL y PUSH)',
            description: 'Gestión automatizada de notificaciones SMS, EMAIL y PUSH para su aplicación.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=10',
            show: true
          }
        },
        {
          es: {
            location: 'global',
            icon: 'icon-service-6.svg',
            title: 'Habilitación del monitor de las aplicaciones',
            description: 'Monitoreo de LOGS y ACCESOS para identificar inconvenientes o fallas potenciales.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=11',
            show: true
          },
          en: {
            location: 'global',
            icon: 'icon-service-6.svg',
            title: 'Habilitación del monitor de las aplicaciones',
            description: 'Monitoreo de LOGS y ACCESOS para identificar inconvenientes o fallas potenciales.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=11',
            show: true
          }
        },
        {
          es: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Emisión de documentos soporte',
            description: 'Servicio para automatizar la remisión de los documentos soporte a la DIAN a partir del proveedor tecnológico Carvajal ó Factura 1.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=12',
            show: true
          },
          en: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Emisión de documentos soporte',
            description: 'Servicio para automatizar la remisión de los documentos soporte a la DIAN a partir del proveedor tecnológico Carvajal ó Factura 1.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=12',
            show: true
          }
        },
        {
          es: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Inbox de Facturación Electrónica',
            description: 'Servicio para automatizar las facturas emitidas por proveedores y registro al software contable o ERP. 1. Carvajal a Seven. 2. Factura 1 a Seven.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=13',
            show: true
          },
          en: {
            location: 'colombia',
            icon: 'icon-service-5.svg',
            title: 'Inbox de Facturación Electrónica',
            description: 'Servicio para automatizar las facturas emitidas por proveedores y registro al software contable o ERP. 1. Carvajal a Seven. 2. Factura 1 a Seven.',
            price: { cop: '$1.000.000', usd: '$250', cad: '$300' },
            link: '/services/?id=13',
            show: true
          }
        }
      ],
      configButton1_es: { text: 'Probar', class: 'button--secundary' },
      configButton2_en: { text: 'Try it', class: 'button--secundary' },
      configButton2: { text: { es: 'Comprar', en: 'Buy' }, class: 'button--secundary' },
      configButton3_es: { text: 'Saber más', class: 'button--secundary' },
      configButton3_en: { text: 'More', class: 'button--secundary' },
      software: [
        {
          es:{
            icon: 'icon-requirements.svg',
            title: 'Desarrollo API REST',
            description:
              'Desarrollamos API REST con Python, PHP y Java para simplificar la integraciones de servicios e incluir opciones de datos abiertos.',
            price: { cop: '$1.500.000', usd: '$400', cad: '$500' },
            link: '/services/microservices',
            limit: '10h',
            period: 'mes',
            show: true
          },
          en: {
            icon: 'icon-requirements.svg',
            title: 'API REST Development',
            description:
              'Develop an API REST Microservice with Python, PHP or Java. Use OpenAPI to integrate diferent applications, customers and services managing the business rules from an unique place.',
            price: { cop: '$1.500.000', usd: '$400', cad: '$500' },
            link: '/services/microservices',
            limit: '10h',
            period: 'month',
            show: true
          }
        }
        // { title: '', description: '', price: '' }
      ],
      configButton4: { text: 'Próximamente', class: 'button--gray' }
    }
  },
  methods: {
    changeOption: function (option) {
      this.state = option
    },
    funcioRedirect: function (link) {
      window.location = link
    }
  }
}
</script>
<style lang="scss" scoped>
  .content{
    max-width:1280px;
  }
</style>
