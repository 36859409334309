<template>
  <button class="btn-principal" v-bind:style="{ background: btnUi.background, color: btnUi.color }" v-on:click="funcionBtn()">
    <!-- <img :src="$cdnSupp + btnUi.icon" alt="icon" v-if="btnUi.icon.length > 0"> -->
    {{ btnUi.text }}
    <!-- <div class="point" v-bind:style="{ background: btnUi.background }"></div> -->
    <!-- <b-icon icon="caret-right-fill" class="arrow" v-bind:style="{ color: btnUi.background }"></b-icon> -->
  </button>
</template>

<script>
export default {
  name: 'buttonMain',
  props: {
    btnUi: Object
  },
  methods: {
    funcionBtn: function () {
      this.$emit('funcionBtn')
    }
  }
}
</script>

<style lang="scss">
.btn-principal {
  width: max-content;
  padding: 12px 20px;
  border: 1px solid transparent;
  border-radius: 5px;
  background: transparent;
  font-family: 'Mulish', sans-serif;
  font-size: 1.6rem;
  font-weight: bold;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 5;
  img {
    margin-right: 1rem;
  }

  .point {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: transparent;
    position: absolute;
    top: 0;
    right: 0;
    transition: all 0.5s;
  }

  .arrow {
    font-size: 2.5rem;
    position: absolute;
    top: 1.1rem;
    right: 0;
    transition: all 0.5s;
  }

  &:hover {
    .point {
      top: -12px;
      right: -12px;
    }

    .arrow {
      right: -2.2rem;
    }
    z-index: 5;
  }
}
</style>
