<template>
  <button :class="'button--'+config.class" @click="$emit('clicked')">
    <b-icon-calendar-check-fill></b-icon-calendar-check-fill>
    {{ config.text }}
    <div class="point"></div>
  </button>
</template>
<script>
export default {
  name: 'buttonCalendar',

  props: {
    config: Object,
    icon: String
  }
}
</script>
