<template>
  <section class="full-modal" v-if="state" v-on:click="openLink()">
    <button class="btn-close"  v-on:click="cerrarModal()">
        <img :src="$cdn + 'icons/icon-x.svg'" alt="icon close">
    </button>
    <b-container>
        <b-row>
            <b-col cols="12" md="">
                <div class="power">
                    <img :src="$cdn + 'logos/Logo-WePower-accelerator-program.png'" alt="logo wepower accelerator">
                </div>
                <div class="img">
                    <img :src="$cdn + 'team/Img-equipo-edwin-ariza--full.jpg'" alt="picture person">
                </div>
                <div class="description">
                    <h2>Edwin Ariza</h2>
                    <h3>Systemico CEO & CIO & Co-Founder</h3>
                </div>
            </b-col >
            <b-col cols="12" md="">
                <h1 class="title">Llevando a Systemico al siguiente nivel.</h1>
                <p class="text"><strong>Systemico</strong> fue seleccionada por la aceleradora <strong>WEPOWER</strong> de Estados Unidos, como una de las 10 empresas que serán parte de su programa de desarrollo empresarial “<strong>ELEVATE / ELEVAR Accelerator</strong>” cohorte 2022. Esto nos dará una oportunidad única para crecer y globalizar nuestros productos y servicios.</p>
            </b-col>
        </b-row>
    </b-container>
  </section>
</template>

<script>

export default {
  name: 'modalWePower',
  components: {
  },

  data: function () {
    return {
    }
  },

  // mounted() {
  //   let stateLocalStorage = localStorage.getItem("modalWePower")

  //   if (stateLocalStorage == null || stateLocalStorage == true) {
  //     this.$store.commit("setModalWePower", { state: true });
  //   } else {
  //     this.$store.commit("setModalWePower", { state: false });
  //   }
  // },

  computed: {
    state(){
      let data = this.$store.getters.getModalWePower;
      return data.state;
    }
  },

  methods: {
    cerrarModal: function () {
      this.$store.commit("setModalWePower", { state: false });
      localStorage.setItem("modalWePower", false);
      event.stopPropagation()
    },

    openLink: function () {
      window.open('https://wepowerstl.org/elevate/', '_blank');
    }
  }
}
</script>
