<template>
  <div class="landing" @scroll="headerReact()">
    <HeaderSystemicoComponent
        :menuActive="true"
        lang="ES"
        country="COLOMBIA"
        class="header_nav__active p-0"
        loginURL="https://suppmail.systemico.net/login"
        signupURL="https://suppmail.systemico.net/signup"
        logoProduct="https://cdn.systemico.net/systemico/products/suppmail_logo_black.svg"
        loginVariant="secondary"
        :isSignup="true"
        :isProduct="true"
    />
    <BannerMultiProductComponent
        :items="banner_items"
        country="colombia"
        lang="es"
        class="mt-5"
        schema="SoftwareApplication" />
    <ProductNavComponent lang="ES" background="#fefefe" />
    <BannerBenefitsComponent
      title="Transforma tu negocio."
      :items="benefits"
      background="#f8f8f8"
      textColor="#222222"
      titleItemColor="#222222"
    />
    <BannerTipComponent :message="what.content"
                        :title="what.title"
                        :icon="what.icon"
                        background="#fefefe"
                        text-color="#222222"
                        id="what"
    />
    <ProductDescriptionComponent
        :characteristics="characteristics['ES']"
        background="#f8f8f8"
        backgroundAdditional="#f8f8f8"
        title="Conoce el potencial de <b>Suppmail</b>"
        subtitleColor="#444444"
        contentColor="#555555"
        lang="ES"
    />
    <TagsComponent title="Características adicionales" :tags="tags" background="#222222" titleColor="#f8f8f8" />
    <!--<banner />-->
    <BannerTipComponent :message="tip_1.content"
                        :title="tip_1.title"
                        :icon="tip_1.icon"
                        background="#f8f8f8"
                        text-color="#222222"
    />
    <!--<HowComponent />-->
    <PresentationComponent title="Systemico / Productos / <b>Suppmail</b>" :topics="topics" />
    <BannerTipComponent :message="tip_2.content"
                        :title="tip_2.title"
                        :icon="tip_2.icon"
                        background="#f8f8f8"
                        text-color="#222222"
    />
    <TestimonialsComponent lang="ES" :items='opinions["ES"]' ></TestimonialsComponent>
    <BannerStatsComponent :items="stats" title="Gracias por tu confianza." />
    <PricingPlansComponent
          productId="3"
          paymentsHost="https://systemico.net/pay/"
          currency="COP"
          lang="ES"
          action="redirect"
          urlButton="https://suppmail.systemico.net/signup"
          trialLimit="7"
          title="Un solo pago un año de servicio y mejoras."
    >
    </PricingPlansComponent>
    <PradekSection />
    <ContactComponent lang="ES"/>
    <FooterSystemicoComponent :lang="ES" country="colombia" />
    <modal-popup v-bind:title="'Suppmail'" v-bind:urlboton="'https://suppmail.systemico.net'" v-bind:urlvideo="'https://www.youtube.com/embed/Kfx813mKW4U?si=lQdq1iS3whc5EN_r'"/>
  </div>
</template>

<script>
// EXCLUSIVE COMPONENTS
//import banner from '../components/sections/banner.vue'
import productivity from '../components/sections/productivity.vue'
import attention from '../components/sections/attention.vue'
import modalPopup from '../components/modals/modalPopup.vue'
import BannerStatsComponent from '../packages/systemico.ui/components/banners/BannerStatsComponent.vue'
// UI PACKAGES
import HeaderSystemicoComponent from '../packages/systemico.ui/components/headers/HeaderSystemicoComponent.vue'
import footerCustom from '../packages/systemico.ui/components/footers/FooterSystemicoComponent.vue'
import BannerMultiProductComponent from '../packages/systemico.ui/components/banners/BannerMultiProductComponent.vue'
import BannerBenefitsComponent from '../packages/systemico.ui/components/banners/BannerBenefitsComponent.vue'
import PlansComponent from '../packages/systemico.ui/components/pricing/PlansComponent.vue'
import ProductNavComponent from '../packages/systemico.ui/components/navs/ProductNavComponent.vue'
//import HowComponent from '../components/sections/HowComponent.vue'
import PresentationComponent from '../packages/systemico.ui/components/slides/PresentationComponent.vue'
import PradekSection from '../packages/systemico.ui/components/sections/PradekSection.vue'
import ContactComponent from "../packages/systemico.ui/components/contacts/ContactComponent.vue";
import FooterSystemicoComponent from "../packages/systemico.ui/components/footers/FooterSystemicoComponent.vue";
import BannerTipComponent from "../packages/systemico.ui/components/banners/BannerTipComponent.vue";
import PricingPlansComponent from "../packages/systemico.ui/components/pricing/PricingPlansComponent.vue";
import TestimonialsComponent from "../packages/systemico.ui/components/contents/TestimonialsComponent.vue";
import ProductDescriptionComponent from "../packages/systemico.ui/components/contents/ProductDescriptionComponent.vue";
import TagsComponent from "../packages/systemico.ui/components/tags/TagsComponent.vue";

export default {
  name: 'Home',
  components: {
    TagsComponent,
    ProductDescriptionComponent,
    TestimonialsComponent,
    PricingPlansComponent,
    BannerTipComponent,
    FooterSystemicoComponent,
    ContactComponent,
    PradekSection,
    //HowComponent,
    ProductNavComponent,
    PlansComponent,
    BannerStatsComponent,
    BannerBenefitsComponent,
    BannerMultiProductComponent,
    HeaderSystemicoComponent,
    PresentationComponent,
    //banner,
    productivity,
    footerCustom,
    modalPopup
  },
  data: function (){
    return {
      what: {
        title: 'Simplifica tus procesos de negocio con <b>Suppmail</b>',
        content: 'Organiza cualquier proceso de atención con un workflow para asegurar el cumplimiento de las metas de servicio, mejorando los tiempos de respuesta y la satisfacción de tus clientes.',
        icon: '@/assets/images/suppmail-what.svg'
      },
      characteristics: {
        ES: [
          {
            id: 1,
            title: 'Registro de Solicitudes',
            description: 'Recibe, clasifica y asigna solicitudes de todo tipo (Peticiones, quejas, reclamos, sugerencias, cotizaciones, contactos, etc) para asegurar su cumplimiento.',
            image: 'https://cdn.systemico.net/suppmail/images/screenshots/suppmail-1.jpeg'
          },
          {
            id: 2,
            title: 'Establece grupos de atención.',
            description: 'Organiza tu equipo para asignar organización por niveles, reáas o departamentos y brindar así una atención apropiada por el personal ídoneo.',
            image: 'https://cdn.systemico.net/suppmail/images/screenshots/suppmail-4.jpeg'
          },
          {
            id: 3,
            title: 'Establece flujos de atención.',
            description: 'Define procesos de atención que aseguren el diligenciamiento de información requerida, documentos asociados (Acta, Contratos, Certificados) para cumplir paso a paso y de la manera que tanto el negocio como el cliente requieren..',
            image: 'https://cdn.systemico.net/suppmail/images/screenshots/suppmail-3.jpeg'
          },
          {
            id: 4,
            title: 'Integra WhatsApp ó Facebook Messenger',
            description: 'Recibe solicitudes desde los canales más utilizados por tus cliente evitando instalaciones innecesarias o accesos a direcciones complicadas.',
            image: 'https://cdn.systemico.net/suppmail/images/screenshots/suppmail-7.jpg'
          },
          {
            id: 5,
            title: 'Evalua el comportamiento de tu atención.',
            description: 'Identifica cuellos de botella, tiempos de respuesta, niveles de satisfacción y otros indicadores que te permitan mejorar la atención y la calidad de tu servicio.',
            image: 'https://cdn.systemico.net/suppmail/images/screenshots/suppmail-6.jpeg'
          }
        ],
        EN: []
      },
      tags:[
        { id: 1, name: 'Workflow' },
        { id: 2, name: 'PQRs' },
        { id: 3, name: 'Help & Desk' },
        { id: 4, name: 'Feedback' },
        { id: 5, name: 'Quejas' },
        { id: 6, name: 'Reclamos' },
        { id: 7, name: 'Sugerencias' },
        { id: 8, name: 'Portal de Clientes' },
        { id: 9, name: 'Widget para Sitio Web' },
        { id: 10, name: 'API' },
        { id: 11, name: 'Radicados Únicos' },
        { id: 12, name: 'Atencinó 24/7' },
        { id: 13, name: 'Evangeliza a tus clientes' },
        { id: 14, name: 'Aprende de tu negocio' }
      ],
      tip_1: {
        title: 'Recibe solicitudes, cotizaciones y sugerencias por Whatsapp, Email y Telefono.',
        content: 'Integra tus canales de atención en un flujo de proceso claro con tiempos de atención y niveles de satisfacción.',
        icon: '@/assets/images/suppmail-what.svg'
      },
      tip_2: {
        title: 'Automatiza notificaciones y recordatorios para asegurar el cumplimiento de tu equipo.',
        content: 'Establece reglas de nivel de servicio y genera notificaciones automáticas para indicar atrasos o demoras en el proceso.',
        icon: '@/assets/images/suppmail-what.svg'
      },
      banner_items: [
        {
          id: 1,
          title: '<b class="text-dark">Suppmail</b> <span style="color:#aaaaaa;">=</span> Atención oportuna<span style="color:#aaaaaa;">,</span> clientes felices',
          subtitle: '<b>Software para cumplir</b> con <i>solicitudes, quejas, sugerencias y cotizaciones</i> desde <b>múltiples canales</b>, asegurando experiencias de servicio.',
          image: 'https://cdn.systemico.net/systemico/web/headers/png/header_12.png',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Prueba gratuita', variant: 'warning', link: 'https://suppmail.systemico.net/signup', icon: 'gift-fill' },
            { id: 1, text: 'Ingresar', variant: 'secondary', link: 'https://suppmail.systemico.net/login', icon: 'lock-fill' }
          ],
          note: '<b>Systemico</b>, un simple paso que transformará tu negocio.',
          show: true
        }
      ],
      stats: [
        { id: 1, title: 'Usuarios', subtitle: 'Usuarios directos que utilizan el servicio.', value: '+1000', icon: 'https://cdn.systemico.net/systemico/icons/icon--users.svg' },
        { id: 2, title: 'Solicitudes', subtitle: 'Solicitudes recibidas por nuestros usuarios.', value: '+15000', icon: 'https://cdn.systemico.net/systemico/icons/icon--cotizacion.svg' },
        { id: 3, title: 'Interacciones', subtitle: 'Interacciones para asegurar la meta del servicio.', value: '+40000', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' },
        { id: 4, title: 'Paises', subtitle: 'Origen de nuestros clientes y usuarios.', value: '5', icon: 'https://cdn.systemico.net/systemico/icons/icon--world.svg' }
      ],
      topics: [
        { id: 1, topic: 'Introducción', content: '<b>Suppmail</b> es una plataforma <i>utilizada para simplificar la gestión solicitudes en mesas de ayuda y de servicio</i>. <br><br>Se enfoca en centralizar e identificar todas las <b>solicitudes originadas por Atención Directa, Telefónica, Email y Whatsapp</b> para asegurar su cumplimiento, utilizando un proceso estandarizado y otros aspectos innovadores. ', image: '' },
        { id: 2, topic: 'Etapas del proceso de gestión',
          content: ['1. Recepción. \n', '2. Clasificación. \n', '3. Asignación. \n', '4. Atencion - Interacción. \n', '5. Solución. \n', '6. Notificación.\n', '7. Evaluación.\n', '8. Cierre. \n.'], image: '' },
        { id: 3, topic: 'Aspectos Innovadores', content: ['1. Monitoreos para cumplimiento ANS.', '2. Alertas por Email.', '3. 100% Web.', '4. Widget integrable para la Web.', '5. API Rest para integración.'], image: '' },
        { id: 4, topic: 'Soporte Innovadores', content: 'Soporte directo desde el portal de <a href="https://systemico.net/">systemico.net</a> o los canales autorizados.', image: '' },
        { id: 5, topic: 'Gracias', content: '<b>Suppmail</b> es la oportunidad de digitalizar las solicitudes de clientes, proveedores, trabajadores y otros interesados en tu negocio.', image: '' },
      ],
      benefits: [
        { id: 1, title: 'Evita Sanciones.', description: 'Protege tu negocio de incumplimientos legales o sanciones normativas por demoras en los tiempos de respuesta.', icon: 'https://cdn.systemico.net/systemico/icons/target.svg' },
        { id: 2, title: 'Estandariza tu atención.', description: 'Incrementa la productividad de tu equipo estableciendo un proceso estandarizado de atención que mejora los tiempos de respuesta al cliente.\n.', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' },
        { id: 3, title: 'Digitaliza tus procesos.', description: 'Estandariza y moderniza tus actividades para potenciar tus servicios con una mejor atención a tus clientes.\n', icon: 'https://cdn.systemico.net/systemico/icons/icon--soporte.svg' },
        { id: 4, title: 'Empodera los clientes.', description: 'Establece procesos claros para que tus clientes autogestionen sus solicitudes y apoyen la definición de información clave para su atención.\n', icon: 'https://cdn.systemico.net/systemico/icons/icon--cool.svg' },
        { id: 5, title: 'Asegura la trazabilidad.', description: 'Centraliza tu gestión y evita la pérdida de información asociada a una petición o requerimiento de un cliente por correos en spam o mal gestionados.', icon: 'https://cdn.systemico.net/systemico/icons/icon--world.svg' },
        { id: 6, title: 'Optimiza la comunicación.', description: 'Organiza la comunicación con tus clientes de cada uno de los temas atendidos quedando en constancia para cada una de las partes.', icon: 'https://cdn.systemico.net/systemico/icons/icon-email-orange.svg' },
        { id: 7, title: 'Aumenta la satisfacción.', description: 'Mejora la percepción de tus clientes con una atención oportuna y eficiente que asegure la satisfacción de tus clientes.', icon: 'https://cdn.systemico.net/systemico/icons/icon-rocket--orange.svg' },
        { id: 8, title: 'Aprende de tus clientes.', description: 'Identifica patrones de comportamiento, solicitudes recurrentes y otros indicadores que te permitan mejorar la calidad de tu servicio.', icon: 'https://cdn.systemico.net/systemico/icons/icon-idea.svg' }
      ],
      plans: [
        {
          id: 1,
          title: 'Emprendedor',
          description: 'Crea una mesa de ayuda que te permita aprender de tus clientes, aliados e inversionistas.',
          features: [
            { id: 1, value: 'Hasta 3 usuarios.' },
            { id: 2, value: 'Soporte permanente y actualizaciones.' },
            { id: 3, value: 'Widget para sitio web.' },
            { id: 4, value: 'Todas la funcionalidades.' }
          ],
          pricing: '$100.000',
          frecuency: 'Año',
          currency: 'COP',
          labelButton: 'Empezar',
          link: 'https://suppmail.systemico.net/signup?plan=1'
        },
        {
          id: 2,
          title: 'Pymes',
          description: 'Optimiza y simplifica tus procesos para disminuir la carga operativa y asegurar tu cumplimiento.',
          features: [
            { id: 1, value: 'Hasta 10 usuarios.' },
            { id: 2, value: 'Soporte permanente y actualizaciones.' },
            { id: 3, value: 'Widget para sitio web.' },
            { id: 4, value: 'Todas la funcionalidades.' }
          ],
          pricing: '$500.000',
          frecuency: 'Año',
          currency: 'COP',
          labelButton: 'Empezar',
          link: 'https://suppmail.systemico.net/signup?plan=2'
        },
        {
          id: 2,
          title: 'Especializado',
          description: 'Adaptación e integración del producto con el negocio, migración de datos y los software actuales.',
          features: [
            { id: 1, value: 'Usuarios ilimitados.' },
            { id: 2, value: 'Acompañamiento personalizado.' },
            { id: 3, value: 'Ajustes e integraciones personalizadas.' },
            { id: 4, value: 'Todas la funcionalidades.' },
            { id: 5, value: 'Soporte prioritario.' },
            { id: 6, value: 'Integraciones Beta.' }
          ],
          pricing: 'A convenir',
          frecuency: 'Año',
          currency: 'COP',
          labelButton: 'Empezar',
          link: 'https://suppmail.systemico.net/signup?plan=3'
        }
      ],
      opinions: {
        ES: [
          {
            id: 1,
            message: "Como firma de asesoramiento contable, manejar las comunicaciones con nuestros clientes es crucial para mantener la confianza y la precisión en nuestro trabajo. <b>Suppmail ha sido un verdadero cambio en nuestra operación</b> diaria.",
            author: "Marcela Gualdron, ",
            role: "Revisora Fiscal COMULDESA",
            company: "Audifin S.A.S",
            place: "Santander, Colombia",
            image: "http://cdn.systemico.net/suppmail/photo/users/photo_1.jpg"
          }
        ],
        EN: [
          {
            id: 1,
            message: "As an accounting advisory firm, managing communications with our clients is crucial to maintaining trust and accuracy in our work. <b>Suppmail has been a real change in our daily operation</b>.",
            author: "Marcela Gualdron, ",
            role: "COOMULDESA Fiscal Audit Office",
            company: "Audifin S.A.S",
            place: "Santander, Colombia",
            image: "http://cdn.systemico.net/suppmail/photo/users/photo_1.jpg"
          }
        ]
      }
    }
  },
  methods: {
    headerReact: function () {
      const header = document.getElementsByClassName('headerUi')
      if (event.target.scrollTop > 0) {
        header[0].classList.add('headerUi--active')
      } else {
        header[0].classList.remove('headerUi--active')
      }
      const stateLocalStorage = localStorage.getItem('modalWePower')
      // console.log(event.target.scrollTop)

      if (event.target.scrollTop > 2620 && window.screen.width > 768 && stateLocalStorage == null) {
        this.$store.commit('setModalWePower', { state: true })
      }

      if (event.target.scrollTop > 5200 && window.screen.width < 768 && stateLocalStorage == null) {
        this.$store.commit('setModalWePower', { state: true })
      }
    },

    changeLanguage: function (idioma) {
      this.$refs.header.changeLanguage(idioma)
    }
  }
}
</script>

<style scoped>

</style>
