import { render, staticRenderFns } from "./ProductDescriptionComponent.vue?vue&type=template&id=32affe50&scoped=true"
import script from "./ProductDescriptionComponent.vue?vue&type=script&lang=js"
export * from "./ProductDescriptionComponent.vue?vue&type=script&lang=js"
import style0 from "./ProductDescriptionComponent.vue?vue&type=style&index=0&id=32affe50&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../systemico.web/src/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32affe50",
  null
  
)

export default component.exports