<template>
    <main class="section">
      <HeaderSystemico :menuActive="true" country="COLOMBIA" :lang="$props.lang" class="header_nav__active p-0" />
        <!-- seccion 1 -->
		<section class="content mt-5 pt-5">
            <titleSection :content='content'/>
			<b-container fluid='lg' class="mt-5">
				<b-row>
					<b-col cols='12' md=''>
                        <h2 class="content__title content__title--celeste mb-5">Te estamos esperando</h2>
						<p class="content__subtitle">Nuestros colaboradores son quienes impulsan el desarrollo sostenible y son la base de los sueños, por ello se buscan profesionales que contribuyan con el cumplimiento de las metas.</p>
					</b-col>
					<b-col cols='12' md=''>
						<img class="d-block mx-auto" :src="$cdn + 'images/image__work.svg'">
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 2 -->
        <section class="content__section content--gray py-8">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
                        <h2 class="content__title text-center margin-bottom-5">Trabaja con Nosotros</h2>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='8' class="mx-auto">
                        <p class="content__paragraph margin-bottom-6">Systemico trabaja día a día para lograr su objetivo: Desarrollar soluciones tecnológicas enfocadas en el impacto y cumplimiento de la estrategia empresarial de nuestros clientes, por ese motivo busca personas que sean apasionadas, comprometidas, que trabajan en equipo y tengan gran sentido de pertenencia.</p>
					</b-col>
				</b-row>
                <b-row>
                    <b-col cols='12' md=''>
                        <div class="content__element shadow-none">
							<img class="d-block mx-auto" style="width: 10rem" :src="$cdn + 'icons/icon-systemico--color.svg'" alt="logo systemico">
                            <h3 class="text-center margin-bottom-4 margin-top-3">¿Porqué trabajar en Systemico?</h3>
                            <p>Systemico busca brindar una estabilidad donde las personas puedan desarrollarse y construir una trayectoria laboral con la oportunidad de crecer como persona y profesional. Ofrece un ambiente donde puedes trabajar en lo que te apasiona y al mismo tiempo aprender, crecer, compartir, divertirse y cumplir metas.</p>
                            <p>Te invitamos a hacer parte del equipo. Envíanos tus datos
								<span class="content__tooltip" id="tooltip-1">
									<b-icon icon="info"></b-icon>
								</span>
								<b-tooltip target="tooltip-1" triggers="hover" placement="topright">
									<b>Nota:</b> Nota: El formulario de recolección de datos se utiliza <br> en el proceso de contratación de Systemico, el cual <br> permite obtener información de las competencias de <br> un candidato, sus habilidades, educación, experiencia <br> laboral y otras referencias. Al enviar el formulario se <br> aceptan los términos y condiciones, además nuestra <br> política de privacidad establecida en <a href="http://" target="_blank" rel="noopener noreferrer"> www.systemico.co</a>
								</b-tooltip>
								<br>
								e iniciemos juntos un nuevo camino.
							</p>
                            <buttonPrimry class="mx-auto margin-bottom-4" :configButton='buttonSection' @funcionBtn="funcioRedirect(buttonSection.click)"/>
                        </div>
                    </b-col>
                    <b-col cols='12' md=''>
                        <div class="content__element shadow-none">
							<img class="d-block mx-auto margin-top-4" :src="$cdn + 'logos/logo-eteamer.svg'" alt="logo eteamer">
                            <h3 class="text-center margin-bottom-4 margin-top-4">¡Trabaja por Actividades!</h3>
                            <p>Systemico cuenta con Eteamer, plataforma de trabajo por horas que permite la vinculación sencilla, ágil y organizada de técnicos, tecnólogos y profesionales de diferentes áreas donde pueden prestar un servicio a diferentes empresas desde cualquier lugar de Colombia desarrollando actividades específicas alineadas a su perfil.</p>
                            <p> Para ser parte de los colaboradores de Systemico, ingresa a <a href="https://www.eteamer.co/" target="_blank" rel="noopener noreferrer">www.eteamer.co</a> selecciona tu campo de conocimiento y asigna las actividades acorde a tu perfil.</p>
                            <buttonPrimry class="mx-auto margin-bottom-4" :configButton='buttonSection2' @funcionBtn="funcioRedirect(buttonSection2.click)"/>
                        </div>
                    </b-col>
                </b-row>
			</b-container>
		</section>
		<!-- seccion 3 -->
		<section class="content content--blue">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10' lg='8'>
						<div class="content__develop">
							<h2>Desarrollamos el crecimiento de tu empresa!</h2>
							<p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
							<div class="opcions">
								<template v-for='(item, index) in buttons'>
									<buttonPrimry :configButton='item' :key='index' @funcionBtn="funcionBtn(item.click)"/>
								</template>
							</div>
						</div>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
		<footerC />
	</main>
</template>

<script>
import titleSection from '@/components/ui/titleSection'
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import footerC from '../../../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import HeaderSystemico from "../../../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
    name: "work",
    components: {
      HeaderSystemico,
		titleSection,
		buttonPrimry,
		footerC
	},
  props: {
    lang: String
  },
	metaInfo: {
		title: 'Systemico: Únetenos, trabaja con nosotros.'
	},
	data: function () {
		return {
			content: {
				title: 'Compañía',
				subtitle: '/ Trabaja con Nosotros'
			},
            buttonSection: { text: 'Aplicar', class: 'button--secundary', click: 'mailto: cao@systemico.co' },
            buttonSection2: { text: 'Aplicar', class: 'button--secundary', click: 'https://www.eteamer.co/' },
			buttons: [
				{ text: 'Cotizar proyecto', class: 'button--secundary', click: 'sinContenido' },
				{ text: 'Solicitar servicio', class: 'button--secundary', click: 'sinContenido' },
				{ text: 'Contáctanos', class: 'button--secundary', click: 'sinContenido' }
			]
		}
	},
	methods: {
        funcioRedirect: function (link) {
			window.location=link
		}
	}
};
</script>
