<template>
  <main class="section">
    <headerC :menuActive="true" class="headerUi--active p-0" />
    <articlec/>
    <footerC />
  </main>
</template>

<script>
import headerC from "../../../../systemico.ui/components/header.vue";
import articlec from '@/components/landing/article.vue'
import footerC from "../../../../systemico.ui/components/footer.vue";

export default {
  name: "article",
  components: {
    headerC,
    articlec,
    footerC,
  },
  metaInfo: {
    title: 'Systemico: Acerca de nosotras.',
  },
  data: function () {
    return {
    };
  },
  methods: {},
};
</script>
