<template>
  <div class="home">
    <HeaderSystemicoComponent :menuActive="true" :lang="$props.lang" class="header_nav__active p-0" />
    <!--<b-img class="logo" src="https://cdn.systemico.net/systemico/logos/logo-systemico--white-2.svg" ></b-img>-->
    <div class="intro">
      <h1 class="sublogo">
         {{ title[$props.lang] }}
      </h1>
      <ContactComponent :lang="$props.lang"/>
    </div>
    <DeckComponent id="what"
                   title="Coolest technologies for your company."
                   description="We provide solutions to improve your daily business."
                   variant="blue"
                   :items="services" />
    <FooterSystemicoComponent/>
  </div>
</template>

<script>
import HeaderSystemicoComponent from '../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue'
import FooterSystemicoComponent from '../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import products from '@/components/landing/products.vue'
import DeckComponent from '@/../../systemico.ui/components/cards/DeckComponent.vue'
import ContactComponent from '@/../../systemico.ui/components/contacts/ContactComponent.vue'

export default {
  name: 'Contact',
  props: {
    lang: String
  },
  data: function () {
    return {
      title: {
        ES: 'Utiliza nuestro canales de atención.',
        EN: 'Available channels for your happiness.',
      }
    }
  },
  components: {
    DeckComponent,
    products,
    FooterSystemicoComponent,
    HeaderSystemicoComponent,
    ContactComponent
  },
  methods: {
    // Nothing
  },
  beforeMount() {
    if (this.lang === undefined || this.lang === '{{LANG}}'){
      this.lang = 'en'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../systemico.ui/variables.scss";
  .home{
    position:absolute;
    min-height: 100%;
    width: 100%;
    font-family:$font-quicksand;
  }
  .intro{
    width: 100%;
    min-height: 100%;
    background:$color-blue--dark;
    background-image: url("https://cdn.systemico.net/systemico/images/image-banner-3.jpg");
    background-position: top;
    background-size: cover;
    background-repeat:no-repeat;
    text-align:center;
    padding-bottom:2em;
  }
  .logo{
    margin-top:10em;
    width: 20%;
    min-width:300px;
  }
  .sublogo{
    font-size: 3rem;
    text-shadow:1px 1px #222222;
    color:$color-white;
    padding-top:3.5rem;
    max-width:80%;
    margin:auto;
  }
  .title{
    margin-bottom:1em;
    font-size:1.5em;
    color:#222222;
    text-shadow:1px 1px #fefefe;
    padding:1em;
    margin-top:1em;
    font-weight: bold;
  }
  .selector{
    text-align: center;
    margin:auto;
    padding-left:2em;
    padding-right:2em;
  }
  .continent{
    font-size:2em;
    color:#f8f8f8;
  }
  .country{
    font-size:1.2rem;
    background: $color-white;
    padding:0.5rem;
    margin-top:0.5rem;
    list-style:none;
    border-radius:1.5em;
    a{
      color:#444444;
      text-decoration:none;
    }
    a:hover{
      color:#222222;
      font-weight:bold;
      text-decoration:none;
    }
  }
  country:hover{
    background: #fefefe;
    color:#444444;
  }
</style>
