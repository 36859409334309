import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueMeta from 'vue-meta'
import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2'
import VueCookies from 'vue-cookies'


// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  //confirmButtonColor: 'transparent linear-gradient(270deg, #4ab9ff, #339aff 42%, #1c78e6) 0 0 no-repeat padding-box',
  customClass: {
    confirmButton: 'button--secundary'
  },
  buttonsStyling: false,
  cancelButtonColor: '#B4B0B0',
  confirmButtonText: 'Aceptar',
  width: '50%'
};
Vue.use(VueSweetalert2, options);

// Import BootstrapVue
// import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'

// Import Bootstrap an BootstrapVue CSS files (order is important)
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Make BootstrapVue available throughout your project
// Vue.use(BootstrapVue)
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)
// COOKIES MANAGEMENT
Vue.use(VueCookies)
// SEO Meta
Vue.use(VueMeta)

Vue.config.productionTip = false
// URL CDN IMAGENES
Vue.prototype.$lang = document.documentElement.lang
Vue.prototype.$cdnSupp = 'https://cdn.systemico.net/suppmail'
Vue.prototype.$cdn = 'https://cdn.systemico.net/systemico/'

// DETECT THE LANG
if (process.env.NODE_ENV === 'production') {
  axios.defaults.baseURL = '/api/services/'
  Vue.prototype.$app = '/api/app/'
} else {
  axios.defaults.baseURL = 'https://systemico-alpha.herokuapp.com/api/services/'
  //axios.defaults.baseURL = 'http://localhost:5000/api/services/'
  Vue.prototype.$app = 'https://systemico-alpha.herokuapp.com/api/app/'
}

new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')

