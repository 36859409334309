<template>
    <section class="attention">
        <b-container fluid=lg>
          <b-row>
            <b-col cols='12' md=''>
              <div class="attention__card">
                <h3>Quiero iniciar con Suppmail para asegurar la atención de mis usuarios.</h3>
                <img :src="$cdnSupp + '/imagenes/img-woman.svg'" alt="icon-attention">
                <div class="text-center">
                  <ButtonComponent text="Inicie una prueba gratuita" href="https://suppmail.systemico.net/login/" variant="primary" />
                  <ButtonMeetingComponent class="ml-2" text="Agendar Demo" variant="info" />
                </div>
              </div>
            </b-col>
            <b-col cols='12' md=''>
              <h2 class="display-4 text-white mb-2">Atención eficaz para fidelizar tus clientes.</h2>
              <q class="description description--white"><strong>Suppmail</strong> va más allá de ser una herramienta de gestión, es un canal de comunicación, que contribuye en gran medida, a fortalecer las relaciones con los clientes mejorando continuamente la percepción del servicio, garantizando que cada vez que se comunique con la empresa obtendrá una respuesta adecuada y a tiempo.</q>
            </b-col>
          </b-row>
        </b-container>
    </section>
</template>

<script>

import ButtonMeetingComponent from '../../packages/systemico.ui/components/buttons/ButtonMeetingComponent.vue'
import ButtonComponent from '../../packages/systemico.ui/components/buttons/ButtonComponent.vue'

export default {
  name: 'attention',
  components: { ButtonComponent, ButtonMeetingComponent },
  methods: {
    reDirect: function (direct) {
      window.location.href = direct
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../packages/systemico.ui/basic.scss";
.attention {
    font-size:12px;
    padding: 8rem 0;
    background-color: $color-blue;
    &__card {
        height: auto;
        padding: 5rem 1rem;
        border-radius: 1rem;
        background-color: $color-white;

        img {
            margin: 0 auto 2.5rem;
            display: block;
        }

        h3 {
            margin-bottom: 3rem;
            font-family: $font-quicksand;
            font-size: 1.5rem;
            font-weight: bold;
            color: $color-blue--dark;
            line-height: 1;
            text-align: center;
        }
    }

    .title {
        margin-top: 2rem;
        margin-bottom: 4rem;
        text-align: center;
    }

    .description {
        margin-bottom: 3rem;
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: $breakpoint-tablet) {
        &__card {
            padding: 5rem;
        }

        .title {
            text-align: left;
        }
    }
}
</style>