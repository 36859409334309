<template>
    <main class="section">
    	 <HeaderSystemico :menuActive="true" country="COLOMBIA" :lang="$props.lang" class="header_nav__active p-0" />
        <!-- seccion 1 -->
		<section class="content mt-5 pt-5">
            <titleSection :content='content'/>
			<b-container fluid='lg' class="mt-5">
				<b-row>
					<b-col cols='12' md=''>
                        <h2 class="content__title content__title--celeste mb-5">Un proceso claro y eficaz</h2>
						<p class="content__subtitle">La adecuada Metodología proporciona las herramientas necesarias para el éxito de un proyecto, principalmente en lo que se refiere a tiempos, costos y riesgos.</p>
					</b-col>
					<b-col cols='12' md=''>
						<img class="d-block mx-auto" :src="$cdn + 'images/image-methodology.svg'">
					</b-col>
				</b-row>
			</b-container>
		</section>
		<!-- seccion 2 -->
        <section class="content__section content--gray py-8">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12'>
                        <h2 class="content__title text-center margin-bottom-5">Aseguramiento de servicios</h2>
					</b-col>
				</b-row>
				<b-row>
					<b-col cols='8' class="mx-auto">
                        <p class="content__paragraph margin-bottom-6">Systemico trabaja con metodologías documentadas en el sistema de gestión de calidad para los procesos de consultoría, producción y testing, con base en metodologías PMI para la gestión de proyecto y de SCRUM como marco de trabajo para el desarrollo de software, ambas enfocadas en el apoyo al servicio de fábrica de software.</p>
					</b-col>
				</b-row>
				<b-row>
					<b-col>
						<img class="img-fluid margin-bottom-8" :src="$cdn + 'images/Img-metodologia-fabrica-software.png'" alt="">
					</b-col>
				</b-row>
                <b-row>
                    <b-col cols='12' md=''>
                        <div class="content__element shadow-none">
                            <h3 class="text-center margin-bottom-4 margin-top-4">Cumpliendo objetivos</h3>
                            <p>Systemico utiliza metodologías ágiles que permiten cumplir con los tiempos de entrega establecidos asegurando la gestión de cada proceso del desarrollo, implementando herramientas para establecer responsabilidades directas a cada miembro del equipo, fomentando el cumplimiento de tiempos, nivel de calidad y metas.</p>
                        </div>
                    </b-col>
                    <b-col cols='12' md=''>
                        <div class="content__card">
                            <h3 class="text-center margin-bottom-4 margin-top-4">En pro de sostener el proceso de manera viable para todos, se realizan las siguientes actividades:</h3>
                            <ul>
                                <li>
                                    <p>Reuniones de estandarización cada 15 días para identificar librerías, técnicas y acciones que aseguren el cumplimiento a clientes.</p>
                                </li>
                                <li>
                                    <p>Se coordina una reunión mensual con todos los program managers y líderes de área para identificar acciones de mejora que optimice los servicios de la empresa.</p>
                                </li>
                                <li>
                                    <p>Se realizan reuniones una vez a la semana para coordinar los equipos vinculados a diferentes proyectos.</p>
                                </li>
                                <li>
                                    <p>Se realiza una reunión a la semana para determinar las lecciones aprendidas, proceso a cargo del Coordinador Metodológico.</p>
                                </li>
                                <li>
                                    <p>Se utilizan herramientas propias y de terceros que permitan generar soluciones de última generación adaptadas a las tendencias y necesidades del mercado.</p>
                                </li>
                            </ul>
                        </div>
                    </b-col>
                </b-row>
			</b-container>
		</section>
		<!-- seccion 3 -->
		<section class="content content--blue">
			<b-container fluid=lg>
				<b-row>
					<b-col cols='12' md=''></b-col>
					<b-col cols='12' md='10' lg='8'>
						<div class="content__develop">
							<h2>Desarrollamos el crecimiento de tu empresa!</h2>
							<p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
							<div class="opcions">
								<template v-for='(item, index) in buttons'>
									<buttonPrimry :configButton='item' :key='index' @funcionBtn="funcionBtn(item.click)"/>
								</template>
							</div>
						</div>
					</b-col>
					<b-col cols='12' md=''></b-col>
				</b-row>
			</b-container>
		</section>
		<footerC />
	</main>
</template>

<script>
import titleSection from '@/components/ui/titleSection'
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import footerC from '../../../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import HeaderSystemico from "../../../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
    name: "methodology",
    components: {
    HeaderSystemico,
		titleSection,
		buttonPrimry,
		footerC
	},
	metaInfo: {
		title: 'Metodologia - Systemico'
	},
  props: {
    lang: String
  },
	data: function () {
		return {
			content: {
				title: 'Compañía',
				subtitle: '/ Metodología de Trabajo'
			},
            buttonSection: { text: 'Aplicar', class: 'button--secundary', click: 'sinContenido' },
            buttonSection2: { text: 'Aplicar', class: 'button--secundary', click: 'sinContenido' },
			buttons: [
				{ text: 'Cotizar proyecto', class: 'button--secundary', click: 'sinContenido' },
				{ text: 'Solicitar servicio', class: 'button--secundary', click: 'sinContenido' },
				{ text: 'Contáctanos', class: 'button--secundary', click: 'sinContenido' }
			]
		}
	},
	methods: {
        funcioRedirect: function (link) {
			window.location=link
		}
	}
};
</script>
