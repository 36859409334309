/* <template>
  <section class="news">
    <b-container fluid="xl">
      <b-row>
        <b-col>
          <h2 class="news__title">
            {{ title }}
            <div class="line"></div>
          </h2>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <div class="news__slider">
            <div class="news__inner-slider">
              <div class="news__card">
                <div class="img">
                  <img :src="$cdn + 'images/Img-news-1.jpg'" alt="image">
                </div>
                <div class="content">
                  <h3>Meet + Support The Spring 2022 Cohort of Elevate/Elevar Biz Accelerator!</h3>
                  <p>WEPOWER is beyond thrilled to welcome our third ever cohort of Elevate/Elevar, our accelerator for Black and Latinx-owned companies. This year, we are scaling our impact by transitioning to run not one but two programs per year. Today, we want to introduce you to the Spring 2022 Elevate/Elevar cohort.</p>
                  <a href="https://wepowerstl.org/meet-support-the-spring-2022-cohort-of-elevate-elevar/" target="_blank" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>

              <div class="news__card">
                <div class="img">
                  <img :src="$cdn + 'images/Img-news-2.jpg'" alt="image">
                  </div>
                <div class="content">
                  <h3>Elevate/Elevar Accelerator, a St. Louis incubator for Black and Latino entrepreneurs, unveils…</h3>
                  <p>A St. Louis-based startup accelerator that helps fund and sustain businesses led by Black and Latino founders has unveiled its newest cohort of companies.</p>
                  <a href="https://www.bizjournals.com/stlouis/inno/stories/news/2022/05/05/elevate-elevar-accelerator-unveils-new-cohort.html" target="_blank" rel="noopener noreferrer">Leer más</a>
                </div>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </section>
</template>

<script>
export default {
  name: "news",

  data: function () {
    return {
      title: "Noticias",
    };
  },
  mounted() {
    let sliderContainer = document.querySelector(".news__slider");
    let innerSlider = document.querySelector(".news__inner-slider");

    let pressed = false;
    let startX;
    let x;

    sliderContainer.addEventListener("mousedown", (e) => {
      pressed = true;
      startX = e.offsetX - innerSlider.offsetLeft;
      sliderContainer.style.cursor = "grabbing";
    });

    sliderContainer.addEventListener("mouseenter", () => {
      sliderContainer.style.cursor = "grab";
    });

    sliderContainer.addEventListener("mouseup", () => {
      sliderContainer.style.cursor = "grab";
      pressed = false;
    });

    sliderContainer.addEventListener("mousemove", (e) => {
      if (!pressed) return;
      e.preventDefault();

      x = e.offsetX;

      innerSlider.style.left = `${x - startX}px`;
      checkBoundary();
    });

    const checkBoundary = () => {
      let outer = sliderContainer.getBoundingClientRect();
      let inner = innerSlider.getBoundingClientRect();

      if (parseInt(innerSlider.style.left) > 0) {
        innerSlider.style.left = "0px";
      }

      if (inner.right < outer.right) {
        innerSlider.style.left = `-${inner.width - outer.width}px`;
      }
    };
  },
  methods: {
    funcioRedirect: function (link) {
      window.location=link
    }
  }
};
</script>
