<template>
  <section class="popup" v-if="state">
    <div class="content">
      <div class="container-fluid">
        <button class="btn-close"  v-on:click="cerrarModal()">
          <b-icon icon="x"></b-icon>
        </button>
        <h2 v-html="title" style="font-weight: bold;"></h2>
        <p v-html="message"></p>
        <div class="options">
          <div class="container-fluid w-100"><ButtonComponent :text="labelButton" variant="secondary" :href="url_target"/></div>
          <div class="container-fluid small mt-2 text-muted">Automatic popup with <a href="https://gogoals.systemico.net">Gogoals</a> simple and automatic marketing.</div>
        </div>
      </div>
    </div>

  </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import axios from "axios";
import ButtonComponent from '../../../../systemico.ui/components/buttons/ButtonComponent.vue';

export default {
  name: "modalPopup",
  components: {
    buttonPrimry,
    ButtonComponent
  },

  data: function () {
    return {
      title: '¿Listo para iniciar tu Startup?',
      message: 'Te ayudamos a desarrollar o sostener tu producto de software.',
      labelButton: '',
      sespop:'',
      idioma:'',
      idiomaM:'',
      url_target: '/service_developing#Price'
    };
  },

  mounted() {
    setTimeout(() => {
      let date = new Date();
      this.sespop = localStorage.getItem('idnoty')
      this.idioma = localStorage.getItem('userLanguage').toUpperCase()
      if(this.sespop != null){
        this.cargarmsg()
      } else{
        this.cargarmsg()
      }
      /*if (localStorage.getItem('timePopup')) {
          let timePopup = new Date(localStorage.getItem('timePopup'));

          if (date.getTime() > timePopup.getTime()) {
            date.setHours(date.getHours() + 24);
            localStorage.setItem('timePopup', date);
            this.showPopup();
          } else {
            this.cerrarModal();
          }
        } else {
          date.setHours(date.getHours() + 1);
          localStorage.setItem('timePopup', date);
          this.showPopup();
        }*/
      /*if(sespop!=null && sespop==true){
        this.cerrarModal()
      }else{

      }*/


    }, 10000);
  },

  computed: {
    state(){
      let data = this.$store.getters.getPopup;
      return data.state;
    }
  },

  methods: {
    cerrarModal: function () {
      localStorage.setItem('cerradoPopup', true)
      this.$store.commit("setPopup", { state: false });
    },
    cargarmsg: function () {
      axios.post('/../marketing/marketing_get_notificacion_pub',{tipo: 5}).then(response => {
        let data = response.data
        this.title = data.DATA.titulo
        this.message = data.DATA.contenido
        this.idiomaM = data.DATA.idioma
        this.url_target = data.DATA.url_target
        this.labelButton = data.DATA.label_button

        if(this.sespop != "popup_"+this.idioma+"_"+data.DATA.id && this.idioma == this.idiomaM ){
          this.showPopup()
          localStorage.setItem('idnoty',"popup_"+this.idioma+"_"+data.DATA.id)
        }
      })
    },

    showPopup: function () {
      this.$store.commit("setPopup", { state: true });
    },

    funcioRedirect: function (link) {
      window.location=link
    },
    //comentario final
  }
};
</script>
