<template>
  <div class="page"  @scroll="headerReact()">
    <headerC :menuActive="true"  class="headerUi--active p-0"/>
    <section>
      <div class="container-fluid p-5" style="background-color:#33CAFF;margin-top:70px;">
        <b-row>
        <b-col cols='12' md='12'>
        <b-card-group  class="justify-center">
          <b-card no-body class="overflow-hidden" style="max-width: 540px;">
            <b-row no-gutters>
              <b-col md="6">
                <b-card-img src="https://cdn.systemico.net/systemico/images/productivity/banner-2.jpg" alt="Image" class="rounded-1 m-2 border-1"></b-card-img>
              </b-col>
              <b-col md="6">
                <b-card-body >
                  <b-card-title style="font-size:3.2em;" class="text-dark">Agenda una reunión.</b-card-title>
                  <b-card-text class='display-5 lead' style="font-size:1.5em;">
                    <b style="font-weight:bold;">Hacemos reuniones cortas y enfocadas</b>, sabemos que estas ocupado, igual nosotros. Así que queremos aportar, <b style="font-weight:bold;">porqué ser productivos es nuestro negocio</b>.
                  </b-card-text>
                  <b-button href="#" variant="success" class="lead btn-lg" style='font-size:16px' onclick="Calendly.initPopupWidget({url: 'https://calendly.com/systemico/30min'});return false;">
                    <b-icon-calendar2-date-fill></b-icon-calendar2-date-fill>
                    <b>&nbsp;Agendar Reunión</b>
                  </b-button>
                </b-card-body>
              </b-col>
            </b-row>
          </b-card>
         </b-card-group>
         </b-col>
         </b-row>
        </div>
    </section>
    <footerC/>
  </div>
</template>

<script>
import headerC from '../../../../systemico.ui/components/header.vue'
import footerC from '../../../../systemico.ui/components/footer.vue'

export default {
  name: 'Meet',
  components: {
    headerC,
    footerC
  },
  metaInfo: {
    title: 'Systemico: Meetings, Reuniones, Réunions.'
  },

  data: function () {
    return {
      title: 'Systemico',
      configButton: { text: 'Ir a Inicio', class: 'button--secundary' }
    }
  },
  methods: {
    home: function () {
      window.location = '/'
    }
  }
}
</script>
