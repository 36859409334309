import Vue from 'vue'
import VueRouter from 'vue-router'

// vistas principales
import Home from '../views/Home.vue'
import Pitch from '../views/Pitch.vue'
import Home_ES_CO from '../views/Home_ES_CO.vue'
import Home_EN_US from '../views/Home_EN_US.vue'
import Home_EN_CA from '../views/Home_EN_CA.vue'
import ContactPage from '../views/Contact.vue'
import article from '../views/website/article.vue'
import tryit from '../views/website/tryit.vue'
import Construction from '../views/website/enConstruccion.vue'

// vista términos y condiciones de pradek
import terms from '../views/privacy/terms.vue'
import politics from '../views/privacy/politics.vue'
import advice from '../views/privacy/advice.vue'

// vista de los terminos
import ProductsPage from '../views/ProductsPage.vue'

// vista de los servisios
import requirements from '../views/website/sections/services/requirements.vue'
import support from '../views/website/sections/services/support.vue'
import developing from '../views/website/sections/services/developing.vue'
import testing from '../views/website/sections/services/testing.vue'
import design from '../views/website/sections/services/design.vue'

// vista de los compañia
import team from '../views/website/sections/company/team.vue'
import aboutUs from '../views/website/sections/company/aboutUs.vue'
import history from '../views/website/sections/company/history.vue'
import work from '../views/website/sections/company/work.vue'
import methodology from '../views/website/sections/company/methodology.vue'
import clients from '../views/website/sections/company/clients.vue'
import investors from '../views/website/sections/company/investors.vue'

// VISTAS DE SIMPLIFICACIÓN DE
import sms from '../views/website/sections/landings/sms.vue'
import NotFound from '../views/website/NotFound.vue'
import Meet from '../views/website/Meet.vue'
import Suppmail from '../../../../suppmail.web/src/views/Home.vue'

Vue.use(VueRouter)

const routes = [
  // ROOT
  { path: '/', name: 'Home', component: Home },
  { path: '/welcome', name: 'Home', component: Home },
  { path: '/pitch', name: 'Home', component: Pitch },
  { path: '/products', name: 'products', component: ProductsPage },
  { path: '/contact', name: 'products', component: ContactPage },
  //{ path: '/products/suppmail', name: 'products.suppmail', component: Suppmail },
  // COLOMBIA
  { path: '/es-co', name: 'es_co', component: Home_ES_CO},
  { path: '/es-co/products', name: 'es_co.products', component: ProductsPage },
  { path: '/es-co/products/suppmail', name: 'es_co.products.suppmail', component: Suppmail },
  // UNITED STATES
  { path: '/en-us', name: 'Home_EN_US', component: Home_EN_US },
  // CANADA
  { path: '/en-ca', name: 'Home_EN_CA', component: Home_EN_CA },
  // GENERAL SECTIONS
  { path: '/construction', name: 'construction', component: Construction },
  { path: '/terms', name: 'terms', component: terms },
  { path: '/politics', name: 'politics', component: politics },
  { path: '/advice', name: 'advice', component: advice },
  { path: '/service_requirements', name: 'requirements', component: requirements },
  { path: '/service_support', name: 'support', component: support },
  { path: '/service_developing', name: 'developing', component: developing },
  { path: '/service_testing', name: 'testing', component: testing },
  { path: '/service_design', name: 'design', component: design },
  { path: '/company_team', name: 'team', component: team },
  { path: '/company_about', name: 'company_about', component: aboutUs },
  { path: '/company_history', name: 'history', component: history },
  { path: '/company_work', name: 'work', component: work },
  { path: '/company_methodology', name: 'methodology', component: methodology },
  { path: '/company_clients', name: 'clients', component: clients },
  { path: '/company_investors', name: 'investors', component: investors },
  { path: '/sms', name: 'sms', component: sms },
  { path: '/meet', name: 'meet', component: Meet },
  { path: '/article', name: 'article', component: article },
  { path: '/tryit', name: 'tryit', component: tryit },
  { path: '/not_found', name: 'NotFound', component: NotFound},
  // NOT FOUND
  { path: '*',
    redirect: () => {
      window.location.href = "/not_found";
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
