<template>
  <div class="home pt-5">
    <HeaderSystemicoComponent :menuActive="true" lang="es" country="colombia" class="header_nav__active p-0" />
    <BannerMultiProductComponent :items="banner_items" country="colombia" lang="es" />
    <BannerBenefitsComponent
          title="<b>Soñamos en grande</b>, para que nuestro país sea mejor."
          :items="benefits"
          background="#fefefe"
          textColor="#444444"
    />
    <products lang="es" currency="cop" country="colombia"/>
    <software/>
    <rocket/>
    <clients/>
    <ceo/>
    <methodology/>
    <team/>
    <allies/>
    <news />
    <testimonials lang="es"/>
    <contact/>
    <footerC/>
    <!-- MODALS -->
    <!--<modalTranslate @changeLanguage="changeLanguage"/>-->
    <modalPopup />
  </div>
</template>

<script>
// import headerC from '@/../../systemico.ui/components/header.vue'
// import banner from '@/components/landing/banner.vue'
import products from '@/components/landing/products.vue'
import software from '@/components/landing/software.vue'
import services from '@/components/landing/services.vue'
import methodology from '@/components/landing/methodology.vue'
import rocket from '@/components/landing/rocket.vue'
import team from '@/components/landing/team.vue'
// import technologies from '@/components/landing/technologies.vue'
import BannerMultiProductComponent from '@/../../systemico.ui/components/banners/BannerMultiProductComponent.vue'
import BannerBenefitsComponent from '@/../../systemico.ui/components/banners/BannerBenefitsComponent.vue'
import ceo from '@/components/landing/ceo.vue'
import clients from '@/components/landing/clients.vue'
import news from '@/components/landing/news.vue'
import testimonials from '@/components/landing/testimonials.vue'
import allies from '@/components/landing/allies.vue'
import contact from '@/components/landing/contact.vue'
// import pradek from '@/components/landing/pradek.vue'
import footerC from '@/../../systemico.ui/components/footer.vue'
//import modalTranslate from '@/components/modals/modalTranslate.vue'
import modalPopup from '@/components/modals/modalPopup.vue'
import social from '@/../../systemico.ui/components/social.vue'
import HeaderSystemicoComponent from "../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue";

export default {
  name: 'Home_ES_CO',
  data: function () {
    return {
      headerFixed: false,
      banner_items: [
        {
          id: 1,
          title: 'Transforma tus procesos de negocio',
          subtitle: '<b>tecnologías para Colombianos</b> que quieren enfrentar los retos actuales del país.',
          image: 'https://cdn.systemico.net/systemico/web/headers/header_9.svg',
          backgroundImage: '',
          buttons: [
            { id: 1, text: 'Software para Pymes', variant: 'primary', link: '/es-co/products', icon:'patch-check-fill' },
            { id: 2, text: 'Desarrollo', variant: 'secondary', link: '/service_developing', icon:'code-square' }
          ],
          show: true
        }
      ],
      benefits: [
        { id: 1, title: 'Soporte Permanente', description: 'Equipo experto que garantiza el cumplimiento de las metas del servicio.', icon: 'https://cdn.systemico.net/systemico/icons/icon--soporte.svg' },
        { id: 2, title: 'Experiencia', description: '17 años en el mercado con errores y aprendizajes que fortalecieron nuestro enfoque, resiliencia y visión.', icon: 'https://cdn.systemico.net/systemico/icons/icon-code.svg' },
        { id: 3, title: 'Empatía', description: 'Orgullosamente Colombianos que vive y enfrentan los retos de nuestro entorno y economía.', icon: 'https://cdn.systemico.net/systemico/icons/icon--cool.svg' },
        { id: 4, title: 'Confianza', description: 'Clientes y aliados que garantizan nuestra experiencia y motivación.', icon: 'https://cdn.systemico.net/systemico/icons/icon-check--orange.svg' }
      ]
    }
  },
  components: {
    HeaderSystemicoComponent,
    BannerBenefitsComponent,
    BannerMultiProductComponent,
    products,
    software,
    methodology,
    rocket,
    team,
    ceo,
    clients,
    news,
    testimonials,
    allies,
    contact,
    footerC,
    //modalTranslate,
    modalPopup,
    social
  },
  beforeMount () {
    /* if(this.$route.params.lang){
      if(this.$route.params.lang=='es' || this.$route.params.lang=='en'){
        localStorage.setItem("userLanguage", this.$route.params.lang);
        this.changeLanguage(this.$route.params.lang)
      }else{
        localStorage.setItem("userLanguage", 'en');
        this.changeLanguage('en')
      }
    }else{
      localStorage.setItem("userLanguage", 'en');
      this.changeLanguage('en')
    } */
  },
  methods: {
    headerReact: function () {
      /** const header = document.getElementsByClassName('headerUi')
      if (event.target.scrollTop > 0 && this.headerFixed === false) {
        header[0].classList.remove('headerUi--show')
        header[0].classList.add('headerUi--active')
        this.headerFixed = true
      } else if (this.headerFixed === true && event.target.scrollTop === 0) {
        header[0].classList.remove('headerUi--active')
        header[0].classList.add('headerUi--show')
        this.headerFixed = false
      }**/

      // TODO: let stateLocalStorage = localStorage.getItem("modalWePower")

      // console.log(event.target.scrollTop)

      // if (event.target.scrollTop > 2620 && window.screen.width > 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }

      // if (event.target.scrollTop > 5200 && window.screen.width < 768 && stateLocalStorage == null) {
      //   this.$store.commit("setModalWePower", { state: true });
      // }
    },
    typing: function () {
      this.$refs.banner.traduccion()
    },
    changeLanguage: function (idioma) {
      this.$refs.header.changeLanguage(idioma)
    }
  }
}
</script>
<style>
@import "../../../systemico.ui/basic.scss";
</style>
