<template>
  <section class="banner_stats container-fluid" :style="'background:'+$props.background+';color:'+$props.textColor">
    <h2 v-html="$props.title"></h2>
    <b-row>
      <b-col md="3" v-for="item in $props.items" :key="item.id" class="p-0">
        <b-card
          style="max-width: 30rem;border-radius: 1.0rem;min-height:10rem;"
          class="m-2 border-0"
        >
          <b-card-text class="text-center" >
            <div>
              <b-img :src="item.icon" height="60em" alt="" class="mb-3" ></b-img>
            </div>
            <div class="value" v-html="item.value"></div>
            <div class="title" v-html="item.title"></div>
            <div class="subtitle" v-html="item.subtitle"></div>
          </b-card-text>
      </b-card>
      </b-col>
    </b-row>
  </section>
</template>

<script>
export default {
  name: 'BannerStatsComponent',
  props: {
    title: String,
    items: Array,
    background: String,
    textColor: String
  }
}
</script>
<style lang="scss" scoped>
  .banner_stats{
    padding:2rem;
    border-bottom: 2px solid #f0f0f0;
    h2{
      font-size: 2.5rem;
      height: 2.7rem;
      text-align: center;
      color:#0b1c98;
      font-weight:bolder;
      margin-bottom: 2rem;
    }
    .title{
      font-size: 1.3rem;
      height: 1.3rem;
      text-align: center;
      color:#009dff;
      font-weight: bold;
    }
    .subtitle{
      font-size: 1.2rem;
      line-height: 1.5rem;
      margin-top: 1rem;
      text-align: center;
      font-weight: lighter;
      color:#777777;
      padding-left:20%;
      padding-right:20%;
    }
    .value{
      font-size: 2rem;
      font-weight:bold;
      color:#222222;
    }
  }
  @media (max-width: 768px) {
    .banner_stats {
      padding: 1rem;
      h2{
        font-size: 2rem;
        line-height: 2.2rem;
      }
      .title{
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
      .subtitle{
        font-size: 1rem;
        line-height: 1.2rem;
      }
      .value{
        font-size: 1.5rem;
      }
    }
  }
</style>
