import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

const apiAxios = axios.create({
  headers: {
    'Content-Type': 'application/json'
  }
})

Vue.use(Vuex, apiAxios)

const token_pri = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : ''
const email = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).usuario_correo : ''

export default new Vuex.Store({
  state: {
    modalTranslate: {
      state: false
    },
    modalWePower: {
      state: false
    },
    popup: {
      state: false
    }
  },
  mutations: {
    setModalTranslate(state, payload) {
      state.modalTranslate.state = payload.state;
    },
    setModalWePower(state, payload) {
      state.modalWePower.state = payload.state;
    },
    setPopup(state, payload) {
      state.popup.state = payload.state;
    }
  },
  actions: {
  },
  modules: {
  },
  getters: {
    getModalTranslate: state => {
      return state.modalTranslate;
    },
    getModalWePower: state => {
      return state.modalWePower;
    },
    getPopup: state => {
      return state.popup;
    }
  }
})
