<template>
    <main class="section">
      <HeaderSystemico :menuActive="true" country="COLOMBIA" :lang="$props.lang" class="header_nav__active p-0" />
      <!-- seccion 1 -->
      <section class="about__section about__banner pt-5 mt-5">
              <titleSection :content='content'/>
              <b-container fluid=lg class="margin-top-3 margin-bottom-6">
                  <b-row>
                      <b-col cols='12' md=''>
                          <h2 class="about__title margin-bottom-4">Somos tu aliado estratégico en tecnología.</h2>
                          <img class="img-fluid" :src="$cdn + 'images/image-development.svg'" alt="image development">
                      </b-col>
                      <b-col cols='12' md=''>
                          <img class="mx-auto d-flex img-fluid mt-5 mt-md-0" :src="$cdn + 'images/image-systemico-cascade.svg'" alt="image development">
                      </b-col>
                  </b-row>
              </b-container>
      </section>
      <!-- seccion 2 -->
      <section class="about__section py-0">
        <b-container fluid=lg>
          <b-row>
            <b-col cols='12' md='' class="p-0">
                          <div class="img--center">
                <img class="img-fluid" :src="$cdn + 'icons/icon-systemico.svg'" alt="ssytemico icon">
              </div>
            </b-col>
            <b-col cols='12' md=''>
                          <div class="padding-4">
                          </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- seccion 3 -->
      <section class="about__section py-0">
        <b-container fluid=lg>
          <b-row>
            <b-col cols='12' md='' class="p-0">
                          <img class="img--full" :src="$cdn + 'images/image-banner.jpg'" alt="image development">
            </b-col>
            <b-col cols='12' md=''>
                          <div class="padding-4">
                              <p class="about__paragraph margin-bottom-2">
                                  <strong>Nuestra Misión</strong>
                              </p>
                              <p class="about__paragraph margin-bottom-6">En SYSTEMICO definimos, desarrollamos y sostenemos <strong>startups</strong> de base tecnológica que presenten un alto nivel de innovación con una clara estrategia y que aporten un beneficio para aliados, clientes e inversionistas.</p>
                              <p class="about__paragraph margin-bottom-2">
                                  <strong>Nuestra Visión</strong>
                              </p>
                              <p class="about__paragraph">Somos una empresa certificada que asegura el desarrollo de software de alta calidad con la satisfacción como motor principal. Que realiza operaciones en 5 países (Colombia, Panamá, Canadá, Estados Unidos y México). 2021-01-01 hasta 2030-12-31</p>
                          </div>
            </b-col>
          </b-row>
        </b-container>
      </section>
      <!-- seccion 4 -->
      <section class="about__section about__slogan">
        <p>“Si tienes una idea y quieres cambiar el mundo, siempre serás bienvenido; <br> si solo quieres intentarlo, no estamos interesados.”</p>
      </section>
      <!-- seccion 5 -->
      <section class="about__section py-8">
        <b-container fluid=lg>
          <b-row>
            <b-col cols='12'>
                          <h2 class="about__title text-center margin-bottom-5">Política Integral</h2>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols='12' md='4'>
                          <p class="about__paragraph margin-bottom-2">En SYSTEMICO estamos comprometidos con la mejora continua de la organización de manera integral, involucrando los aspectos de calidad, seguridad y salud en el trabajo dentro de todos los procesos, con el fin de prestar servicios de base tecnológica que cumplan los requisitos establecidos y sean competitivos según los estándares mundiales.</p>
            </b-col>
            <b-col cols='12' md='4'>
                          <p class="about__paragraph margin-bottom-2">Será parte fundamental de nuestra organización la innovación en nuestros procesos, productos y servicios, para lo cual la empresa se compromete a suministrar los recursos necesarios para la operación, incluyendo los requeridos para la implementación y funcionamiento de los diferentes sistemas de gestión, buscando satisfacer los requisitos de los clientes, aliados e inversionistas y cumplir la normatividad aplicable.</p>
            </b-col>
            <b-col cols='12' md='4'>
                          <p class="about__paragraph margin-bottom-2">Es además un compromiso de la organización realizar actividades de prevención y promoción de la seguridad y salud a los colaboradores, contratistas y demás partes interesadas, a través de la identificación de los peligros, evaluación y valoración de los diferentes riesgos, establecimiento de los respectivos controles y la adquisición de hábitos de trabajo seguro.</p>
            </b-col>
          </b-row>
        </b-container>
		  </section>
      <!-- seccion 6 -->
      <section class="about__section about__section--blue">
        <b-container fluid=lg>
          <b-row>
            <b-col cols='12' md=''></b-col>
            <b-col cols='12' md='10' lg='8'>
              <div class="about__develop">
                <h2>Desarrollamos el crecimiento de tu empresa!</h2>
                <p>Systemico es un aliado estratégico enfocado en hacer las cosas simples y efectivas, que a cada paso se agregue valor y que la inversión se ejecute lo más eficientemente posible.</p>
                <div class="opcions">
                  <template v-for='(item, index) in buttons'>
                    <ButtonComponent :text='item.text' :key='index.key' :variant="item.variant" @click="item.click"/>
                  </template>
                </div>
              </div>
            </b-col>
            <b-col cols='12' md=''></b-col>
          </b-row>
        </b-container>
      </section>
		<footerC />
	</main>
</template>

<script>
import HeaderSystemico from '../../../../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue'
import ButtonComponent from '../../../../../../systemico.ui/components/buttons/ButtonComponent.vue'
import titleSection from '@/components/ui/titleSection'
import buttonPrimry from '@/components/basic/buttonBasic.vue'
import footerC from '../../../../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'

export default {
  name: "aboutUs",
  components: {
    HeaderSystemico,
    titleSection,
    buttonPrimry,
    footerC,
    ButtonComponent
  },
	metaInfo: {
		title: 'Systemico: Acerca de nosotros.'
	},
  props: {
    lang: String
  },
	data: function () {
		return {
			content: {
				title: 'Compañía',
				subtitle: '/ Sobre Nosotros'
			},
      buttons: [
				{ key: 1, text: 'Cotizar proyecto', variant: 'secondary', click: '' },
				{ key: 2, text: 'Solicitar servicio', variant: 'secondary', click: '' },
				{ key: 3, text: 'Contáctanos', variant: 'secondary', click: '' }
			]
		}
	},
	methods: {
	}
};
</script>
