<template>
  <section class="social">
    <nav class="social_nav">
      <template v-for='(network, index) in networks'>
        <!-- <a class="footer__button" href="javascript:void(0);" rel="noopener noreferrer" :key='index' v-on:click="sinContenido"> -->
        <a class="social__button" :href="network.link" rel="noopener noreferrer" :key='index'>
            <img :src="$cdn + 'icons/' + network.icon1" alt="network. networks">
            <img :src="$cdn + 'icons/' + network.icon2" alt="icon networks">
        </a>
    </template>
    </nav>
  </section>
</template>

<script>

export default {
  name: 'social',
  data: function () {
    return {
      networks: [
        {
          icon1: 'icon-facebook-dark.svg',
          icon2: 'icon-facebook-dark--hover.svg',
          link: 'https://www.facebook.com/Systemico/'
        },
        {
          icon1: 'icon-linkend-dark.svg',
          icon2: 'icon-linkend-dark--hover.svg',
          link: 'https://www.linkedin.com/company/systemico/mycompany/'
        },
        {
          icon1: 'icon-youtube-dark.svg',
          icon2: 'icon-youtube-dark--hover.svg',
          link: 'https://www.youtube.com/channel/UCdG-G3O4cLs99C5lP2ye8QQ'
        },
        {
          icon1: 'icon-twitter-dark.svg',
          icon2: 'icon-twitter-dark--hover.svg',
          link: 'https://twitter.com/systemico?lang=es'
        },
        // {
        //   icon1: 'icon-git-dark.svg',
        //   icon2: 'icon-git-dark--hover.svg',
        //   link: ''
        // },
        {
          icon1: 'icon-medium-dark.svg',
          icon2: 'icon-medium-dark--hover.svg',
          link: 'https://medium.com/@systemico'
        },
      ],
    }
  },
  methods: {
    redirect: function (link) {
      window.location = link;
    },
  }
}
</script>

<style lang="scss">
.social {
  position: fixed;
  top: 16rem;
  left: 2rem;

  &__nav {
    & > :last-child {
      margin: 0;
    }
  }

  &__button {
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
        margin: 0 0 0.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > :first-child {
            display: block;
        }

        & > :last-child {
            display: none;
        }

        &:hover {
            & > :first-child {
                display: none;
            }

            & > :last-child {
                display: block;
            }
        }
    }

    // responsive para dispositivos de tablets en adelante
    @media (max-width: 768px) {
      display: none;
    }
}
</style>