<template>
    <section class="footer">
        <b-container fluid='xl'>
            <b-row>
                <template v-for='(col, index) in cols'>
                    <b-col cols='12' md='6' lg='3' :key='index'>
                        <h2 class="footer__subtitle">{{ col.subtitle }}</h2>
                        <template v-for='(link, index) in col.links'>
                          <a class="footer__link" :href="($props.urlbase?$props.urlbase+link.link:link.link)"  rel="noopener noreferrer" :key='index' v-if="link.link">
                              {{ link.name }}
                          </a>
                          <a class="footer__link" href="javascript:void(0);" rel="noopener noreferrer" :key='index' v-else-if="link.event" :onclick="link.event">
                            {{ link.name }}
                          </a>
                          <a class="footer__link" href="/enConstruccion" rel="noopener noreferrer" :key='index' v-else>
                              {{ link.name }}
                          </a>
                        </template>
                    </b-col>
                </template>
            </b-row>
            <b-row class="mt-md-5">
                <b-col cols='12' lg='6'>
                    <div class="footer__items">
                      <img class="footer__logo" :src="$cdn + 'logos/logo-systemico--black.svg'" alt="logo systemico">
                      <div class="footer__networks">
                          <template v-for='(network, index) in networks'>
                              <!-- <a class="footer__button" href="javascript:void(0);" rel="noopener noreferrer" :key='index' v-on:click="sinContenido"> -->
                              <a class="footer__button" :href="network.link" rel="noopener noreferrer" :key='index'>
                                  <img :src="$cdn + 'icons/' + network.icon1" alt="network. networks">
                                  <img :src="$cdn + 'icons/' + network.icon2" alt="icon networks">
                              </a>
                          </template>
                      </div>
                    </div>
                </b-col>
                <b-col cols='12' lg='6'>
                  <div class="footer__clutch">
                    <img v-if="idioma == 'es-ES'" :src="$cdn + 'logos/logo_cln.png'" alt="logo cln">
                    <a href="https://www.crunchbase.com/organization/systemico" target="_blank" rel="noopener noreferrer">
                      <img :src="$cdn + 'logos/logo_crunchbase.svg'" alt="logo runchbase" class="img2">
                    </a>
                    <div class="clutch-widget" data-nofollow="true" data-url="https://widget.clutch.co" data-widget-type="14" data-height="50" data-clutchcompany-id="1822619"></div>
                  </div>
                </b-col>
            </b-row>
            <b-row class="footer__line">
              <b-col cols='12' md='6'>
                <p class="footer__firm">© {{ fecha }} <strong>Systemico®</strong></p>
              </b-col>
              <b-col cols='12' md='6'>
                <div class="footer__legal">
                  <a href="javascript:void(0);" rel="noopener noreferrer" v-on:click="reDirect('terms')">Legal</a>
                  <!-- <a href="javascript:void(0);" rel="noopener noreferrer" v-on:click="sinContenido">Privacidad</a> -->
                </div>
              </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'footerC',
  props:{
    urlbase: String
  },

  components: {
  },

  data: function () {
    return {
      fecha: '',
      cols: [
        {
          subtitle: 'Servicios',
          links: [
            { name: 'Análisis de requerimientos', link: '/service_requirements' },
            { name: 'Diseño y Modelado de Software', link: '/service_design' },
            { name: 'Desarrollo de Software', link: '/service_developing' },
            { name: 'Pruebas de Software', link: '/service_testing' },
            { name: 'Súper Soporte', link: '/service_support' }
          ]
        },
        {
          subtitle: 'Acerca de',
          links: [
            { name: 'Compañía', link: '/company_about' },
            { name: 'Políticas de la empresa', link: '/politics' },
            { name: 'Metodología de trabajo', link: '/company_methodology' },
            { name: 'Clientes', link: '/company_clients' },
            { name: 'Aliados' }
          ]
        },
        {
          subtitle: 'Atención',
          links: [
            { name: 'Soporte', link: 'https://systemico.net/support/' },
            //{ name: 'Contáctanos', link: 'https://systemico.net/support/registro' },
            { name: 'Contáctanos', event: 'const collection = document.getElementsByClassName("pradek_widget__button");(collection[0]).click();' },
            // { name: 'Cotizaciones' },
            { name: 'Blog', link: 'https://medium.com/@systemico' }
          ]
        },
        {
          subtitle: 'Extensión',
          links: [
            { name: 'Inversores', link: '/company_investors' },
            { name: 'Trabaje con nosotros', link: '/company_work' },
            { name: 'Equipo', link: '/company_team' }
          ]
        }
      ],

      networks: [
        {
          icon1: 'icon-facebook--hover.svg',
          icon2: 'icon-facebook.svg',
          link: 'https://www.facebook.com/Systemico/'
        },
        {
          icon1: 'icon-linked--hover.svg',
          icon2: 'icon-linked.svg',
          link: 'https://www.linkedin.com/company/systemico/mycompany/'
        },
        {
          icon1: 'icon-youtube--hover.svg',
          icon2: 'icon-youtube.svg',
          link: 'https://www.youtube.com/channel/UCdG-G3O4cLs99C5lP2ye8QQ'
        },
        {
          icon1: 'icon-twitter--hover.svg',
          icon2: 'icon-twitter.svg',
          link: 'https://twitter.com/systemico?lang=es'
        },
        // {
        //   icon1: 'icon-git--hover.svg',
        //   icon2: 'icon-git.svg',
        //   link: ''
        // },
        {
          icon1: 'icon-blog--hover.svg',
          icon2: 'icon-blog.svg',
          link: 'https://medium.com/@systemico'
        },
      ],

      idioma: ''
    }
  },


  mounted() {
    this.fecha = new Date().getFullYear();
    this.idioma = window.navigator.idioma;
  },

  methods: {
    sinContenido: function () {
      const pathname = location.pathname

      if (pathname === '/enConstruccion') {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth'
        })
      } else {
        this.$router.push({
          name: 'enConstruccion'
        })
      }
    },

    reDirect: function (view) {
      this.$router.push({
        name: view
      })
    }
  }
}
</script>

<style lang="scss">
.footer {
    padding: 8rem 0;
    background-color: #E9ECF4;
    z-index: 1000;

    &__subtitle {
        margin: 0 0 3rem;
        font-family: 'Quicksand', sans-serif;
        font-size: 1.5rem;
        font-weight: 600;
        color: #171E44;
        line-height: 1.8rem;
    }

    &__link {
        margin: 0 0 2rem;
        font-family: 'Mulish', sans-serif;
        font-size: 1.2rem;
        font-weight: normal;
        color: #171E44;
        line-height: 1.5rem;
        display: block;

        &:hover {
            color: #171E44;
        }
    }

    &__logo {
        margin: 5rem auto;
        display: block;
    }

    &__networks {
        margin: 5rem 0 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__button {
        width: 3rem;
        min-width: 3rem;
        height: 3rem;
        margin: 0 0.5rem;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        & > :first-child {
            display: block;
        }

        & > :last-child {
            display: none;
        }

        &:hover {
            & > :first-child {
                display: none;
            }

            & > :last-child {
                display: block;
            }
        }
    }

    &__firm {
        font-family: 'Mulish', sans-serif;
        font-size: 1.2rem;
        font-weight: normal;
        color: #171E44;
        line-height: 1.5rem;
    }

    &__line {
        border-top: 0.1rem solid #7D8AA7;
        margin: 3rem 0 0;
        padding: 3rem 0 0;
    }

    &__legal {
        padding: 3rem 0 0;
        display: flex;

        a {
            margin: 0 4rem 2rem 0;
            font-family: 'Mulish', sans-serif;
            font-size: 1.2rem;
            font-weight: normal;
            color: #171E44;
            line-height: 1.5rem;
            display: block;

            &:hover {
                color: #171E44;
            }
        }
    }

    &__clutch {
        height: 100%;

        & > :nth-child(1) {
            width: 15rem;
            min-width: 15rem;
            margin: 2rem auto 0;
            display: block;
        }

        & > :nth-child(2) {
            width: 16rem;
            min-width: 16rem;
            margin: 0 auto 3rem;
            display: block;
        }

        & > :nth-child(3),
        .clutch-widget {
            width: 22.2rem;
            min-width: 22.2rem;
            margin: 0 auto;
            display: block;
        }
    }

    // responsive para dispositivos de tablets en adelante
    @media (min-width: 768px) {
        padding: 9rem 0 12.5rem;

        &__subtitle {
            font-size: 1.8rem;
            line-height: 2.3rem;
        }

        &__link {
            font-size: 1.6rem;
            line-height: 2rem;
        }

        &__logo {
            width: 16rem;
            margin: 0 3rem 0 0;
        }

        &__networks {
            margin: 0;
        }

        &__firm {
            font-size: 1.6rem;
            line-height: 2rem;
        }

        &__legal {
            padding: 0;
            justify-content: flex-end;

            a {
                font-size: 1.6rem;
                line-height: 2rem;
            }
        }

        &__items {
            height: 100%;
            display: flex;
            align-items: center;
        }

        &__clutch {
            display: flex;
            align-items: center;
            justify-content: flex-end;

            & > :nth-child(1n) {
                margin: 0 1rem;
            }
        }
    }
}
</style>
