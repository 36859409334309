<template>
  <div class="pitch">
    <HeaderSystemicoComponent :menuActive="true" :lang="lang" class="header_nav__active p-0" country="usa"/>
    <PresentationComponent title="Systemico / <b>Pitch</b>" :topics="topics" />
    <FooterSystemicoComponent/>
  </div>
</template>

<script>
import HeaderSystemicoComponent from '../../../systemico.ui/components/headers/HeaderSystemicoComponent.vue'
import FooterSystemicoComponent from '../../../systemico.ui/components/footers/FooterSystemicoComponent.vue'
import PresentationComponent from '../../../systemico.ui/components/slides/PresentationComponent.vue'

export default {
  name: 'Pitch',
  components: {
    FooterSystemicoComponent,
    HeaderSystemicoComponent,
    PresentationComponent
  },
  data: function () {
    return {
      title: {
        es: 'Pitch para clientes.',
        en: 'Pitch for clients.'
      },
      lang: 'en',
      topics: [
        {
          id: 1,
          topic: 'Systemico Technologies.',
          content:'<b>Simple and coolest software and development</b> to transform the small business processes in the world.<br><br><b>We are ready the impulse your objectives</b>.',
          image: ''
        },
        {
          id: 1,
          topic: 'Type of services.',
          content:['1. Software as a Service - <b>SAAS</b>.', '2. Custom Development.'],
          image: ''
        },
        {
          id: 2,
          topic: 'Software as a Service - SAAS.',
          content:
            [ '1. RPA y ChatBOT - WhatsApp, SMS, Facebook Messenger y Telegram. (<a href="https://systemico.net/products/wabot">Wabot</a>)',
              '2. Requests, Feedback and Complaints. (<a href="https://systemico.net/products/suppmail">Suppmail</a>)',
              '3. Smart Quotation System. (<a href="https://systemico.net/products/proposals">Proposals</a>)',
              '4. Meetings and commitments (<a href="https://systemico.net/products/focusmeet">Focusmeet</a>).',
              '5. Project Management Platform (<a href="https://systemico.net/products/grumpyturtle">Grumpyturtle</a>).',
              '6. Email marketing platform (<a href="https://systemico.net/products/gogoals">Gogoals</a>).',
              '7. Contractor management system (<a href="https://systemico.net/products/provedur">Provedur</a>).',
              '8. Transportation System. (<a href="https://systemico.net/products/transpur">Transpur</a>).'],
          image: ''
        },
        { id: 3, topic: 'Custom Development.', content: ['1. API development.', '2. Bot Development.', '3. Dashboards Development.', '4. Cron Services.'], image: '' },
        { id: 4, topic: 'Permanent Support', content: 'Direct support from <a href="https://systemico.net/">systemico.net</a> and other channels like WhatsApp.', image: '' },
        { id: 5, topic: 'Thanks', content: '<b>Systemico</b> provide simple solutions to improve your business. Thanks.', image: '' }
      ],
      country_choose: {
        es: 'Selecciona tu país',
        en: 'Select your country'
      },
      lines: [
        { id: 1, name: 'Products', description: 'Products ready to use.', link: '/products' },
        { id: 2, name: 'Software Development', description: 'Custom development for your needs.', link: '/development' },
        { id: 3, name: 'Integrations & Plugins', description: 'Code to integrate and increase your products functionalities.', link: '/development' }
      ],
      services: [
        { id: 1, title: 'Software as a service.', description: 'Simple software to improve your processes and team. Generate cool experiences to your clients, teams and providers.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-25.png', link: '/products', button: 'I want it', buttonIcon: 'arrow-right' },
        { id: 2, title: 'Custom Development.', description: 'Development on demand for your startup, small business or company. API, Dashboards, web applications and more.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-1.png', link: '/development', button: 'I want it', buttonIcon: 'arrow-right' },
        { id: 3, title: 'Data Analytics.', description: 'Identify patterns and sources to obtain data and transform it into information to learn, improve your business and more impact.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-21.png', link: '/development/data_analytics', button: 'I want it', buttonIcon: 'arrow-right' },
        { id: 4, title: 'Code Ready.', description: 'Set options of Open Data, Plugins, Libraries, Components, Extensions, Cron and more to help your team with clean code to integrate directly.', image: 'https://cdn.systemico.net/systemico/web/cards/headers/header-3.png', link: '/code', button: 'I want it', buttonIcon: 'arrow-right' }
      ]
    }
  },
  methods: {
    // Nothing
  },
  beforeMount () {
    if (this.lang === undefined || this.lang === '{{LANG}}') {
      this.lang = 'en'
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../../../systemico.ui/variables.scss";
  .pitch{
    position:absolute;
    min-height: 100%;
    width: 100%;
    font-family:$font-quicksand;
  }
  .intro{
    width: 100%;
    min-height: 100%;
    background:$color-blue--dark;
    background-image: url("https://cdn.systemico.net/systemico/images/image-banner-3.jpg");
    background-position: top;
    background-size: cover;
    background-repeat:no-repeat;
    text-align:center;
    padding-bottom:2em;
  }

</style>
