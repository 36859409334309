<template>
    <section class="productivity">
        <b-container>
          <b-row>
            <b-col cols='12' md=''>
              <div class="productivity__img">
                <img :src="$cdnSupp + '/imagenes/Img-productividad.png'" alt="img-productivity">
              </div>
            </b-col>
            <b-col cols='12' md=''>
              <h2 class="title title--aquamarine">El poder de Suppmail como herramienta</h2>
              <p class="description description--white" style="line-height: 28px">
                <strong>Suppmail</strong> es una plataforma para fidelizar clientes a partir de un servicio optimizado de atención.</br></br>
                Es común en las empresas que en el día reciban <strong>solicitudes de cotizaciones, soporte, quejas, peticiones o recomendaciones</strong>
                de los clientes, proveedores, empleados, entre otros y se vuelve
                indispensable contar con herramientas que faciliten la gestión de esas solicitudes, asegurando el control
                de tiempos de atención, incrementando el nivel de servicio y obteniendo estadísticas que permitan <strong>la mejora continua</strong>.</br></br>
                Ayuda tu negocio a evolucionar con tecnología. <strong>Systemico</strong> te acompañará a cada paso.
              </p>
            </b-col>
          </b-row>
        </b-container>
    </section>
</template>

<script>
export default {
  name: 'productivity'
}
</script>
<style>
  .description{
    font-weight:normal;
    font-size: 1.8em;
    line-height: 2em;
  }
</style>