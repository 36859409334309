<template>
	<div class="titleSection">
		<b-container fluid=lg>
			<b-row>
				<b-col>
					<h2>
						{{content.title}}
						<div class="line"></div>
						<span>{{content.subtitle}}</span>
					</h2>
				</b-col>
			</b-row>
		</b-container>
	</div>
</template>

<script>
export default {
  name: "titleSection",
  props: {
    content: Object
  }
};
</script>
