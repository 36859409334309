<template>
  <main class="section">
    <headerC :menuActive="true" class="headerUi--active p-0"/>
    <section class="requirements requirements--blue mt-5 pt-5">
      <b-container fluid="lg">
        <b-row>
          <b-col>
            <titleSection :content="content" />
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md=""></b-col>
          <b-col cols="12" md="5">
            <div class="requirements__title requirements__title--white">
              <img
                :src="$cdn + 'icons/icon-requirements.svg'"
                alt="icon card"
              />
              <h2>Análisis de Requerimientos</h2>
            </div>
            <p class="requirements__subtitle">
              Análisis detallado de necesidades y soluciones
            </p>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="requirements__description">
              La transformación digital es inminente y directamente proporcional
              al nivel de competitividad; cada empresa tiene particularidades
              que con la experiencia de un aliado tecnológico es posible
              potencializarlas al más alto nivel.
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <!-- index -->
    <div class="content__index">
      <a href="#Description">Descripción</a>
      <!-- <a href="#Prices">Precios</a> -->
      <a href="#Case">Caso de Éxito</a>
    </div>
    <div class="requirements requirements--grey pb-0" id="Description">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Análisis de negocio y necesidades</h3>
              <p>
                Cada negocio que desea destacarse en el mercado debe proponer
                aspectos diferenciadores que se conviertan en requerimientos
                funcionales y no funcionales que permitirán generar soluciones
                tecnológicas disruptivas y de alto impacto.
              </p>
              <a href="http://" target="_blank" rel="noopener noreferrer"
                >Solicitar un experto ></a
              >
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Conocer las expectativas</h3>
              <p>
                La cohesión del cliente con el equipo multidisciplinario de
                Systemico permite identificar los sueños y las necesidades que
                motivaron la inversión para fijar un norte claro alineado al
                concepto base.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Público objetivo</h3>
              <p>
                El producto o servicio tecnológico que desarrolla cada miembro
                de Systemico está alineado a la necesidad del usuario al que va
                dirigido para así maximizar su viabilidad.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Priorizar con sentido</h3>
              <p>
                Un líder de proyecto estructura el plan para mantenerse enfocado
                en salir al mercado lo más pronto posible con una versión mínima
                viable. Se sabe por experiencia que el nivel de éxito se mide
                por los resultados y son los usuarios los que lo determinan.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__slogan">
              <p>
                Nuestros Servicios son soluciones innovadoras para potenciar tu
                negocio.
              </p>
            </div>
          </b-col>
          <b-col cols="12" md="6" lg="4">
            <div class="requirements__card">
              <h3>Ajustado al presupuesto</h3>
              <p>
                Desear es fácil, lograr es complicado para eso se determinan
                límites claros que faciliten el balance entre el riesgo y el
                cumplimiento de metas.
              </p>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <section class="requirements__plans requirements__plans--blue">
      <b-container>
        <b-row>
          <b-col>
            <h2
              class="
                requirements__plans__title requirements__plans__title--white
                text-center
                py-5
              "
            >
              Planes de Consultoría para Análisis de Requerimientos
            </h2>
          </b-col>
        </b-row>
        <div class="requirements__plans__card">
          <b-row>
            <b-col cols="12" md="6">
              <img
                class="requirements__plans__icon"
                :src="$cdn + 'icons/icon-requirements.svg'"
                alt="icon card"
              />
              <h3 class="requirements__plans__subtitle text-center">
                Consultoría de Análisis Técnico y Preparación de Proyecto de
                Software
              </h3>
              <buttonPrimry
                class="requirements__plans__button"
                :configButton="plan1"
                @funcionBtn="funcionBtn(plan1.click)"
              />
            </b-col>

            <b-col cols="12" md="6">
              <div class="requirements__plans__slogan">
                <p>
                  Eres emprendedor o profesional y tienes claridad de tu
                  objetivo y no sabes cómo hacerlo técnicamente, pero tienes una
                  necesidades identificadas y experiencia en proyectos de
                  tecnología u otros.
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple class="requirements__plans__table" responsive>
                <b-thead>
                  <b-tr>
                    <b-th>Atención</b-th>
                    <b-th>Horas / Dedicación</b-th>
                    <b-th>Valor</b-th>
                    <b-th>Incluye</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Nivel 1</b-td>
                    <b-td>5 Horas (2 ó 3 horas por semana)</b-td>
                    <b-td>750 USD</b-td>
                    <b-td rowspan="4">
                      <div class="table-card">
                        <p>
                          Incluye lo siguiente en todos los niveles de atención:
                        </p>
                        <h3>Personal Experto:</h3>
                        <ul>
                          <li>Experto en negocios</li>
                          <li>Experto en tecnología</li>
                        </ul>
                        <h3>Actividades:</h3>
                        <ul>
                          <li>Análisis del negocio</li>
                          <li>
                            Generación de requerimientos ó historias de usuario
                          </li>
                          <li>Diagrama de flujo de los procesos principales</li>
                          <li>Perfilamiento del usuario o cliente potencial</li>
                          <li>Arquitectura de software recomendada</li>
                          <li>Generación de Wireframes</li>
                          <li>Simulador para pruebas</li>
                          <li>Informe final con plan de proyecto</li>
                        </ul>
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Nivel 2</b-td>
                    <b-td>10 Horas (2 ó 3 horas por semana)</b-td>
                    <b-td>1500 USD</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Nivel 3</b-td>
                    <b-td>20 Horas (2 ó 3 horas por semana)</b-td>
                    <b-td>3000 USD</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Nivel 4</b-td>
                    <b-td>50 Horas (2 ó 3 horas por semana)</b-td>
                    <b-td>9000 USD</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>

        <div class="requirements__plans__card">
          <b-row>
            <b-col cols="12" md="6">
              <img
                class="requirements__plans__icon"
                :src="$cdn + 'icons/icon-requirements.svg'"
                alt="icon card"
              />
              <h3 class="requirements__plans__subtitle text-center">
                Consultoría de Optimización de Negocio y Análisis Técnico para
                Desarrollo de Proyecto de Software
              </h3>
              <buttonPrimry
                class="requirements__plans__button"
                :configButton="plan1"
                @funcionBtn="funcionBtn(plan1.click)"
              />
            </b-col>

            <b-col cols="12" md="6">
              <div class="requirements__plans__slogan">
                <p>
                  Si eres startup y quieres dar un home run con un proceso ágil
                  y sencillo, o si eres un negocio tradicional pero no sabes
                  como superar algunas brechas para mejorar tu gestión a partir
                  de tecnología.
                </p>
              </div>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-table-simple class="requirements__plans__table" responsive>
                <b-thead>
                  <b-tr>
                    <b-th>Atención</b-th>
                    <b-th>Horas / Dedicación</b-th>
                    <b-th>Valor</b-th>
                    <b-th>Incluye</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr>
                    <b-td>Nivel 1</b-td>
                    <b-td>20 Horas (2 ó 3 horas por semana)</b-td>
                    <b-td>3000 USD</b-td>
                    <b-td rowspan="4">
                      <div class="table-card">
                        <p>
                          Incluye lo siguiente en todos los niveles de atención:
                        </p>
                        <h3>Personal Experto:</h3>
                        <ul>
                          <li>Experto en negocios</li>
                          <li>Experto en tecnología</li>
                          <li>Experto en estrategia</li>
                          <li>Experto en calidad</li>
                        </ul>
                        <h3>Actividades:</h3>
                        <ul>
                          <li>Análisis del negocio</li>
                          <li>Análisis de viabilidad técnica</li>
                          <li>
                            Análisis de calidad a partir de reglas de ISO 9001
                          </li>
                          <li>
                            Generación de requerimientos ó historias de usuario
                          </li>
                          <li>Diagrama de flujo de los procesos principales</li>
                          <li>Perfilamiento del usuario o cliente potencial</li>
                          <li>Arquitectura de software recomendada</li>
                          <li>Generación de Wireframes</li>
                          <li>Simulador para pruebas</li>
                          <li>Informe final con plan de proyecto</li>
                        </ul>
                      </div>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Nivel 2</b-td>
                    <b-td>30 Horas (2 ó 3 horas por semana)</b-td>
                    <b-td>4500 USD</b-td>
                  </b-tr>
                  <b-tr>
                    <b-td>Nivel 3</b-td>
                    <b-td>50 Horas (2 ó 3 horas por semana)</b-td>
                    <b-td>9000 USD</b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </b-col>
          </b-row>
        </div>
      </b-container>
    </section>
    <section class="case" id="Case">
      <b-container fluid="xl">
        <b-row>
          <b-col>
            <h2 class="case__title">Software de Facturación y Recuado</h2>
            <h3 class="case__subtitle">Caso de Éxito</h3>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12">
            <div class="case__card">
              <header class="case__card__header">
                <h3>
                  Problema
                  <img :src="$cdn + 'icons/icon-tests.svg'" alt="icon card" />
                </h3>
              </header>
              <div class="case__card__line">
                <img :src="$cdn + 'icons/icon-check.svg'" alt="icon card" />
                <div class="line"></div>
              </div>
              <main class="case__card__content">
                <!-- <h3>Lorem ipsum dolor et samet</h3> -->
                <!-- <h4>Cliente</h4> -->
                <p>
                  Demoras en los tiempos de facturación y recaudo en una empresa
                  de transporte y afectaciones de productividad en diferentes
                  etapas del proceso de negocio.
                </p>
              </main>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="case__card">
              <header class="case__card__header">
                <h3>
                  Desafío
                  <img :src="$cdn + 'icons/icon-idea.svg'" alt="icon card" />
                </h3>
              </header>
              <div class="case__card__line">
                <img :src="$cdn + 'icons/icon-check.svg'" alt="icon card" />
                <div class="line"></div>
              </div>
              <main class="case__card__content">
                <!-- <h3>Lorem ipsum dolor et samet</h3> -->
                <p>
                  Optimizar los procesos de negocio para disminuir los tiempos
                  de recaudo de 120 días a 45 y generar evaluaciones de
                  analitica para identificar los clientes a los mejores
                  clientes.
                </p>
              </main>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="case__card">
              <header class="case__card__header">
                <h3>
                  Solución
                  <img :src="$cdn + 'icons/icon-idea.svg'" alt="icon card" />
                </h3>
              </header>
              <div class="case__card__line">
                <img :src="$cdn + 'icons/icon-check.svg'" alt="icon card" />
                <div class="line"></div>
              </div>
              <main class="case__card__content">
                <!-- <h3>Lorem ipsum dolor et samet</h3> -->
                <p>
                  Optimizar los procesos de cotización, despacho y entrega e
                  integrar en el mismo proceso toda la información asociada para
                  asegurar las exigencias de los clientes que acelere la
                  facturación y entregar información en tiempo real de
                  situaciones del negocio al gerente para facilitar la toma de
                  decisiones que aceleren la gestión interna del negocio e
                  impacten positivamente sus metas financieras. Para esto se
                  desarrolló un software integrado que controla cada uno de los
                  procesos de negocio con todos los involucrados e interesados
                  en las diferentes fuentes de información para incluir
                  analitica, automatización y opciones de auto servicios que le
                  dan agilidad al proceso e incremento de la calidad del
                  servicio a accionistas, clientes y proveedores.
                </p>
              </main>
            </div>
          </b-col>
          <b-col cols="12">
            <div class="case__card">
              <header class="case__card__header">
                <h3>
                  Resultados
                  <img :src="$cdn + 'icons/icon-tests.svg'" alt="icon card" />
                </h3>
              </header>
              <div class="case__card__line">
                <img :src="$cdn + 'icons/icon-check.svg'" alt="icon card" />
              </div>
              <main class="case__card__content">
                <!-- <h3>Lorem ipsum dolor et samet</h3> -->
                <p>
                  Incremento de la fidelización de cliente, disminución en más
                  del 40% de tareas operativas, mejoramiento del nivel de
                  satisfacción del personal interno que disminuyó los tiempos
                  asociados a tareas operativas tediosas lo que impactó la
                  productividad y la cohesión del equipo de trabajo al dar mayor
                  autonomía al equipo de trabajo, aspectos que no son
                  convencionales en empresas del sector transporte. Se
                  disminuyeron los tiempos de recaudo de 120 días a 43 días sin
                  incrementar el personal aliviando a la empresa de cargar
                  financieras por créditos asociados al cumplimiento de su
                  operación. Ahora la empresa cuenta con un software que integra
                  su operación y que es soportado parcialmente por la empresa
                  con apoyo técnico y de negocio del personal especializado de
                  Systemico.
                </p>
              </main>
            </div>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <section class="requirements requirements--blue">
      <b-container fluid="lg">
        <b-row>
          <b-col cols="12" md=""></b-col>
          <b-col cols="12" md="10" lg="8">
            <div class="requirements__develop">
              <h2>Desarrollamos el crecimiento de tu empresa!</h2>
              <p>
                Systemico es un aliado estratégico enfocado en hacer las cosas
                simples y efectivas, que a cada paso se agregue valor y que la
                inversión se ejecute lo más eficientemente posible.
              </p>
              <div class="opcions">
                <template v-for="(item, index) in buttons">
                  <buttonPrimry
                    :configButton="item"
                    :key="index"
                    @funcionBtn="funcionBtn(item.click)"
                  />
                </template>
              </div>
            </div>
          </b-col>
          <b-col cols="12" md=""></b-col>
        </b-row>
      </b-container>
    </section>
    <footerC />
  </main>
</template>

<script>
import headerC from '../../../../../../systemico.ui/components/header.vue'
import titleSection from "@/components/ui/titleSection";
import buttonPrimry from "@/components/basic/buttonBasic.vue";
import footerC from '../../../../../../systemico.ui/components/footer.vue'

export default {
  name: "requirements",
  components: {
    headerC,
    titleSection,
    buttonPrimry,
    footerC,
  },
  metaInfo: {
    title: "Systemico: Servicio de Análisis de Requerimientos.",
  },
  data: function () {
    return {
      content: {
        title: "Servicios",
        subtitle: "/ Análisis de Requerimientos",
      },
      buttons: [
        { text: "Cotizar proyecto", class: "button--secundary", click: "sinContenido" },
        {
          text: "Solicitar servicio",
          class: "button--secundary",
          click: "sinContenido",
        },
        { text: "Contáctanos", class: "button--secundary", click: "sinContenido" },
      ],
    };
  },
  methods: {
    funcionBtn: function (item) {
      if (item == "sinContenido") {
        this.sinContenido();
      }
    },

    sinContenido: function () {
      console.log("holi");
      this.$router.push({
        name: "enConstruccion",
      });
    },
  },
};
</script>
