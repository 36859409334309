<template>
    <section class="services" id="services">
        <div class="services__opacity"></div>
        <b-container fluid='xl'>
            <b-row>
                <b-col>
                    <h2 class="services__title">
                        {{ title }}
                        <div class="line"></div>
                    </h2>
                </b-col>
            </b-row>
            <b-row>
                <template v-for='(item, index) in items'>
                    <b-col cols='12' md='4' xl='' :key='index'>
                        <div class="services__card" v-on:click="funcioRedirect(item.link)">
                            <div class="services__card__icon">
                                <img :src="$cdn + 'icons/' + item.icons" alt="icon card">
                            </div>
                            <h3 class="services__card__subtitle">
                                {{ item.title }}
                            </h3>
                            <p class="services__card__description">
                                {{ item.description }}
                            </p>
                        </div>
                    </b-col>
                </template>
            </b-row>
            <b-row>
                <b-col>
                    <p class="services__description">
                        {{ description }}
                    </p>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <buttonPrimry class="mx-auto" :configButton="configButton" @funcionBtn="funcioRedirect('/support/registro')"/>
                </b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>
import buttonPrimry from '@/components/basic/buttonBasic.vue'

export default {
  name: 'banner',

  components: {
    buttonPrimry
  },

  data: function () {
    return {
      title: 'Servicios',

      items: [
        { icons: 'icon-requirements.svg', title: 'Análisis de requerimientos', description: 'Análisis detallado de necesidades y soluciones.', link: '/service_requirements' },
        { icons: 'icon-design.svg', title: 'Diseño y Modelado de Software', description: 'Arquitectura de software y prototipado.', link: '/service_design' },
        { icons: 'icon-code.svg', title: 'Desarrollo de Software', description: 'Metodologías ágiles, estándares e integración.', link: '/service_developing' },
        { icons: 'icon-software.svg', title: 'Pruebas de Software', description: 'Validaciones y cumplimiento funcional del software.', link: '/service_testing' },
        { icons: 'icon-person--systemico.svg', title: 'Súper Soporte!', description: 'Planes integrales para garantizar la disponibilidad permanente de tu aplicación.', link: '/service_support' }
      ],

      description: 'Soluciones innovadoras para potenciar tu negocio',

      configButton: { text: 'Cotizar', class: 'button--secundary' }
    }
  },
  methods: {
    funcioRedirect: function (link) {
      window.location=link
    }
  }
}
</script>
