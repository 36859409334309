<template>
    <button :class="configButton.class" v-on:click="funcionBtn()">
        {{ configButton.text }}
        <div class="point"></div>
    </button>
</template>

<script>

export default {
  name: 'buttonBasic',
  props: {
    configButton: Object
  },
  methods: {
    funcionBtn: function () {
      this.$emit('funcionBtn')
    }
  }
}
</script>
