<template>
    <section class="ceo">
        <b-container fluid='xl'>
            <b-row>
                <b-col cols='12' md=''></b-col>
                <b-col cols='12' md='7'>
                    <img class="ceo__icon" :src="$cdn + 'icons/icon-systemico--color.svg'" alt="icon card">
                    <p class="ceo__text">
                        <i>{{ text[$props.lang] }}</i>
                    </p>
                    <h3 class="ceo__name">
                        {{ name }}
                    </h3>
                    <span class="ceo__ceo">
                        {{ ceo }}
                    </span>
                </b-col>
                <b-col cols='12' md=''></b-col>
            </b-row>
        </b-container>
    </section>
</template>

<script>

export default {
  name: 'ceo',
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      text: {
        es: '“Desarrollamos soluciones tecnológicas a medida, transformando modelos de negocios para facilitar el crecimiento empresarial.”',
        en: '“We develop cool technological solutions to transform your business, letting you growth in your market. We really respect your high important resources, your dreams and time.”'
      },

      name: 'Edwin Ariza',

      ceo: 'CEO & CIO & Co-Founder'
    }
  }
}
</script>
