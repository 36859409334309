<template>
  <div  class="presentation">
    <p class="my-4 text-white small" v-html="$props.title"></p>
    <b-row>
      <b-col class="d-none d-md-block" md="4" >
        <b-card class="menu font-weight-bold text-white border-0" header="Temas">
          <b-list-group class="bg-transparent">
            <b-list-group-item
                v-for="item in topics"
                :key="item.id"
                class="menu-item bg-transparent text-white"
                @click="select(item.id)"
                v-html="item.topic"
            >
            </b-list-group-item>
          </b-list-group>
          <div class="p-4 text-center border mt-4" style="border-radius:0.7em;">
            <h4 v-html="meetingMessage[$props.lang].title"></h4>
            <p class="small mb-4" v-html="meetingMessage[$props.lang].message"></p>
          <ButtonMeetingComponent :text="meetingButton[$props.lang]" variant="secondary" />
          </div>
        </b-card>
      </b-col>
      <b-col md="8">
        <b-card
          :img-src="image"
          img-alt= "Presentation Image"
          img-top= ""
          tag="slide"
          style="width:100%;"
          class="mb-2"
        >
          <b-card-header class="text-right bg-transparent border-0 small">
            <span class="badge badge-white">slide {{position + 1}}</span>
          </b-card-header>
          <b-card-text >
            <h2 v-html="content_title"></h2>
            <p v-if="content_description instanceof Object" class="content">
              <b-list-group>
                <b-list-group-item v-for="item_content in content_description" :key="item_content.id" v-html="item_content">
                </b-list-group-item>
              </b-list-group>
            </p>
            <p v-else class="content" v-html="content_description"></p>
          </b-card-text>
          <div class="float-right">
            <ButtonComponent class="mr-2" v-if="showBack" icon="arrow-left-circle" iconPosition="start" variant="secondary" :text="buttonBack[$props.lang]" @click="back" ></ButtonComponent>
            <ButtonComponent v-if="showNext" icon="arrow-right-circle" iconPosition="end" variant="secondary" :text="buttonNext[$props.lang]" @click="next" ></ButtonComponent>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <div class="text-right">
      <small class="text-light text-lowercase small font-weight-lighter p-2">Presentations Widget at Systemico</small>
    </div>
  </div>
</template>
<script>
import ButtonComponent from '../buttons/ButtonComponent.vue'
import ButtonMeetingComponent from '../buttons/ButtonMeetingComponent.vue'

export default {
  name: 'BannerTipComponent',
  components: { ButtonMeetingComponent, ButtonComponent },
  data () {
    return {
      content_title: '',
      content_description: '',
      image: '',
      position: 0,
      showBack: false,
      showNext: true,
      showFinal: false,
      meetingMessage: {
        es: {
          title: '¿Deseas una demostración?',
          message: 'Sin compromisos o complicaciones, solo para recibir acompañamiento directo y recibir un beneficio de uso gratuito.',
          labelButton: 'RSVP Meeting'
        },
        en: {
          title: '¿Do you need a demo?',
          message: 'Request a meeting without restrictions or payments to talk with and expert.',
          labelButton: 'RSVP Meeting'
        }
      },
      meetingButton: {
        es: 'Agendar Reunión',
        en: 'Schedule meeting'
      },
      buttonNext: {
        es: 'Siguiente',
        en: 'Next'
      },
      buttonBack: {
        es: 'Atrás',
        en: 'Back'
      }

    }
  },
  props: {
    id: String,
    icon: String,
    title: String,
    topics: Object,
    lang: String
  },
  methods: {
    start () {
      this.image = this.topics[this.position].image
      this.content_title = this.topics[this.position].topic
      this.content_description = this.topics[this.position].content
    },
    next () {
      if (this.position <= (this.topics.length - 1)) {
        this.position = this.position + 1
        this.updatePosition()
      }
    },
    back () {
      if (this.position >= 1) {
        this.position = this.position - 1
        this.updatePosition()
      }
    },
    select (position) {
      this.position = position - 1
      this.updatePosition()
    },
    updatePosition () {
      this.image = this.topics[this.position].image
      this.content_title = this.topics[this.position].topic
      this.content_description = this.topics[this.position].content
      if (this.position === 0) {
        this.showBack = false
        this.showNext = true
      } else if (this.position === (this.topics.length - 1)) {
        this.showNext = false
        this.showBack = true
      } else {
        this.showNext = true
        this.showBack = true
      }
    }
  },
  beforeMount () {
    if (this.lang === undefined) {
      this.lang = 'en'
    }
    this.start()
  }
}
</script>
<style lang="scss" scoped>
  @import "../../basic.scss";
  .presentation{
    font-size:1.2rem;
    padding:2rem;
    background-color: $color-blue--dark;
    .menu{
      background:none;
      .menu-item{
        cursor:pointer;
      }
      .card-header{
        background:none;
        border:none;
      }
    }
    h2{
      font-size: 2.5rem;
      color:$color-blue;
      padding-bottom:5px;
      border-bottom: 3px solid;
      border-color:$color-orange;
    }
    h3{
      font-size:2rem;
    }
    h4{
      font-size:1.8rem;
    }
    .content{
      font-size:1.5rem;
      line-height: 1.5rem;
      height: 25rem;
      padding:1rem;
      .list-group-item{
        border:none;
        color:$color-black;
        border:none;
      }
    }
    .list-group-item{
      border-top:none;
      border-left:none;
      border-right:none;
      border-bottom: 1px solid;
      border-color:$color-white;
      font-weight:lighter;
      color:$color-blue;
    }
    @media (max-width: 768px) {
      h2{
        font-size: 1.5rem;
      }
      h3{
        font-size:1.3rem;
      }
      h4{
        font-size:1.2rem;
      }
      .content{
        font-size:0.9rem;
        line-height: 1.2rem;
        height: 20rem;
      }
    }
  }
</style>
