<template>
  <div >
    <b-card class="formContact mt-3 p-4 mb-3" header="">
      <b-form @submit.stop.prevent>
        <!--FULL NAME -->
        <label for="full_name" class="label">{{fullName[$props.lang]}}</label>
        <b-form-input class="input" type="text" id="full_name" v-model="name"
                      aria-describedby="name-help-block" :placeholder="fullName_Note[$props.lang]"></b-form-input>
        <b-form-text id="name-help-block" class="help">
          {{fullName_Note[$props.lang]}}
        </b-form-text>
        <!--PHONE NUMBER -->
        <label for="phone" class="label">{{phoneNumber[$props.lang]}}</label>
        <b-form-input class="input" type="phone" id="phone" v-model="phone"
                      aria-describedby="name-help-block" :placeholder="phoneNumber_Note[$props.lang]"></b-form-input>
        <b-form-text id="name-help-block" class="help">
          {{phoneNumber_Note[$props.lang]}}
        </b-form-text>
        <!--PHONE EMAIL -->
        <label for="email" class="label">{{lemail[$props.lang]}}</label>
        <b-form-input class="input" type="email" id="email" v-model="email"
                      aria-describedby="name-help-block" :placeholder="lemail_Note[$props.lang]"></b-form-input>
        <b-form-text id="name-help-block" class="help">
          {{lemail_Note[$props.lang]}}
        </b-form-text>
        <!-- NEED -->
        <label for="txtmsg" class="label">{{detailYNeed[$props.lang]}}</label>
        <b-form-textarea
          id="txtmsg"
          v-model="message"
          class="input"
          :placeholder="detailYNeed_Note[$props.lang]"
          rows="3"
          max-rows="6"
        ></b-form-textarea>
        <b-form-text id="name-help-block" class="help">
          {{detailYNeed_Note[$props.lang]}}
        </b-form-text>
        <div class="text-center pt-2">
          <ButtonComponent class="m-0" variant="secondary" :text="send.text[$props.lang]"  @click="sendmsg()" />
        </div>
        <div class="text-center pt-2">
          <small class="text-muted" style="font-size:12px;">{{privacyPolicy[$props.lang]}}</small>
        </div>
       </b-form>
    </b-card>
  </div>
</template>

<script>
import ButtonComponent from "./buttons/ButtonComponent.vue";
import axios from 'axios'
export default {
  components: {
    ButtonComponent
  },
  props: {
    lang: String
  },
  beforeMount(){
    if(this.lang === undefined){
      this.lang='es'
    }
  },
  data: function () {
    return {
      fullName:{
        es: "Nombre Completo",
        en:"Full name"
      },
      fullName_Note: {
        es: "Escribe tu nombre completo para ponernos en contacto.",
        en: "Type your name to contact you."
      },
      phoneNumber:{
        es: "Número de teléfono",
        en:"Phone Number"
      },
      phoneNumber_Note: {
        es: "Escribe tu número de teléfono.",
        en: "Type your phone number."
      },
      lemail:{
        es: "Email",
        en:"Email"
      },
      lemail_Note: {
        es: "Escribe tu email de contacto.",
        en: "Type your contact email."
      },
      detailYNeed:{
        es: "Detalle de la Necesidad.",
        en: "Detail your Need."
      },
      detailYNeed_Note:{
        es: "Describe claramente tu necesidad, se concreto, si deseas puedes mencionar aspectos técnicos ó lo que consideres relevante.",
        en: "Clearly describe your need, be specific, if you wish you can mention technical aspects or anything you consider relevant."
      },
      privacyPolicy:{
        es: 'Al enviar este mensaje aceptas nuestros términos de uso y políticas de privacidad.',
        en: 'By sending this message you accept our terms of use and privacy policies.'
      },
      send: {
        text: {es: "Enviar Solicitud", en: "Send request"},
        class: "button--secundary",
        link: "",
      },
      name: '',
      phone: '',
      email: '',
      message: ''
    }
  },
  methods:{
    sendmsg: function(){
      let formData = new FormData();
      formData.append("nombre_cliente", this.name);
      formData.append("email_cliente", this.email);
      formData.append("telefono_cliente", this.phone);
      formData.append("descripcion", 'Mensaje enviado desde web site Systemico: '+this.message);
      formData.append('api_client', clienteid);
      formData.append("lang", this.lang);

      //this.$store.commit('setLoading', true);

      axios.post('https://suppmail.systemico.net/customer/cliente_ticket_crear',formData).then(response => {
        let res = response.data
        if (res.CODIGO == 1) {
          this.$swal('Solicitud remita exitosamente, muy pronto nuestro equipo te contactara')
          this.name = ''
          this.phone = ''
          this.email = ''
          this.message = ''
          //this.$store.commit('setNotification', { state: true, title: 'Éxito', text: 'Recomendación enviada correctamente' });
          //this.close();
        }
        else if(res.CODIGO != 6) {
          //this.$store.commit('setNotification', { state: true, title: 'Error', text: response.MENSAJE });
        }
      });

      //this.$store.commit('setLoading', false);

    }
  }
}
</script>

<style lang="scss" scoped>
  .formContact{
    background:#f8f8f8 !important;
    border-radius:1em;
  }
  .label{
    font-size:16px;
    font-weight:bold;
    color:#444444;
  }
  .input{
    font-size:16px;
    font-family:"Roboto", sans-serif;
    margin-bottom:1em;
  }
  .help{
    font-size:11px;
    display: none;
  }
</style>